import { useEffect, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'

import Page from 'commons/page'
import { ListWrapper } from 'components/layout'
import Block from 'components/block'
import { SectionTitle } from 'components/typography'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  Contract,
  ContractLink,
  Feature,
  FeatureLink,
  StatusType,
} from 'schema/contract'
import { UserRole } from 'schema/user'
import {
  fetchContract,
  fetchFeature,
  selectContractList,
  selectContractListLoading,
  selectFeatureList,
  selectFeatureListLoading,
} from 'stores/contract'
import { useQuery } from 'utils'
import NoData from '../../components/no-data'
import {
  ContractListItem,
  FeatureListItem,
} from '../../features/contracts/list/list-item'
import {
  CONTRACT_STATUS_TYPE,
  FEATURE_STATUS_TYPE,
} from '../../constants/contract'
import {
  ContractedStatusTub,
  FetaureStatusTub,
} from '../../features/contracts/status-tab'

const ContractList = () => {
  const { claims } = useAuthProvider()
  const contracts = useAppSelector(selectContractList)
  const features = useAppSelector(selectFeatureList)
  const contractLoading = useAppSelector(selectContractListLoading)
  const featureLoading = useAppSelector(selectFeatureListLoading)
  const dispatch = useAppDispatch()
  const queryString = useQuery()
  const type = queryString.get('type')
  const queryStatus = queryString.get('status')
  const [status, setStatus] = useState<StatusType>(
    queryStatus === 'inProgress' ? 'inProgress' : 'done'
  )

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (type !== 'feature') {
      dispatch(fetchContract({ role: claims?.role }))
    }

    if (claims?.role === UserRole.AGENT && type === 'feature') {
      dispatch(fetchFeature())
    }
  }, [dispatch, claims?.role, type, status])

  const contractList = useMemo<Array<ContractLink>>(() => {
    const typeList = CONTRACT_STATUS_TYPE[status || '']
    if (!typeList) {
      return []
    }
    return contracts
      ?.filter((contract: Contract) => typeList.indexOf(contract.status) > -1)
      .map((contract: Contract) => {
        const destination =
          claims?.role === UserRole.OWNER
            ? ROUTING_PATH.B0302
            : ROUTING_PATH.C0302
        const toDetail = generatePath(destination, { id: contract.id })
        const toChat = generatePath(destination, { id: contract.id })
        return {
          ...contract,
          toDetail,
          toChat,
        }
      })
  }, [claims?.role, contracts, status])

  const featureList = useMemo<Array<FeatureLink>>(() => {
    const typeList = FEATURE_STATUS_TYPE[status || '']
    if (!typeList) {
      return []
    }

    return features
      ?.filter((feature: Feature) => typeList.indexOf(feature.status) > -1)
      .filter((feature: Feature) => !feature.isHidden)
      .map((feature: Feature) => {
        const destination =
          claims?.role === UserRole.OWNER
            ? ROUTING_PATH.B0302
            : ROUTING_PATH.C0302
        const toDetail = generatePath(destination, { id: feature.id })
        return {
          ...feature,
          toDetail,
        }
      })
  }, [claims?.role, features, status])

  return (
    <Page
      title="物件一覧"
      loading={contractLoading || featureLoading}
      type="user"
    >
      <Block>
        {type === 'contract' && (
          <>
            <SectionTitle>売却中の物件一覧</SectionTitle>
            <ContractedStatusTub status={status} setStatus={setStatus} />
          </>
        )}
        {claims?.role === UserRole.AGENT && type === 'feature' && (
          <>
            <SectionTitle>先物掲載</SectionTitle>
            <FetaureStatusTub status={status} setStatus={setStatus} />
          </>
        )}
        {type !== 'feature' && (
          <ListWrapper>
            {contractList?.map((contract) => (
              <ContractListItem
                key={`contract-${contract.id}`}
                type={type}
                data={contract}
              />
            ))}
            {contractList?.length === 0 && <NoData />}
          </ListWrapper>
        )}
        {claims?.role === UserRole.AGENT && type === 'feature' && (
          <ListWrapper>
            {featureList?.map((feature) => {
              return (
                <FeatureListItem
                  key={`contract-${feature.id}`}
                  type="feature"
                  data={feature}
                  image={`/image/${feature.type}.png`}
                  imageAlt={feature.type}
                />
              )
            })}
            {featureList?.length === 0 && <NoData />}
          </ListWrapper>
        )}
      </Block>
    </Page>
  )
}

export default ContractList
