import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Page from 'commons/page'
import Block from 'components/block'
import { Horizontal } from 'components/layout'
import Panel from 'components/panel'
import { SectionTitle } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import SuumoDetailPVGraph from '../../features/contracts/graph/suumo-detail-pv-graph'
import SuumoFavoriteGraph from '../../features/contracts/graph/suumo-favorite-graph'
import SuumoHpClickGraph from '../../features/contracts/graph/suumo-hp-click-graph'
import SuumoListToDetailGraph from '../../features/contracts/graph/suumo-list-to-detail-graph'
import SuumoListPVGraph from '../../features/contracts/graph/suumo-list-pv-graph'
import SuumoReactionGraph from '../../features/contracts/graph/suumo-reaction-graph'
import {
  fetchContractById,
  fetchFeatureById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
  selectFeatureById,
  selectFeatureByIdFailed,
  selectFeatureByIdLoading,
} from 'stores/contract'
import { UserRole } from 'schema/user'
import { useQuery } from 'utils'
import HelpTips from '../../components/help-tip'
import LatestImportedDatetime from '../../features/contracts/latest-imported-datetime'
import NextActionArea from '../../features/contracts/next-action-area'

const GraphArea = styled(Horizontal)`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
  }
`

const SuumoDetail = () => {
  const { claims } = useAuthProvider()
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const feature = useAppSelector(selectFeatureById)
  const featureLoading = useAppSelector(selectFeatureByIdLoading)
  const featureFailed = useAppSelector(selectFeatureByIdFailed)
  const dispatch = useAppDispatch()
  const queryString = useQuery()
  const type = queryString.get('type')
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    if (type === 'feature') {
      if (claims?.role === UserRole.AGENT) {
        dispatch(fetchFeatureById({ id }))
      } else {
        // TODO 売り主がアクセスした場合の処理
      }
    } else {
      dispatch(fetchContractById({ role: claims?.role, id }))
    }
  }, [dispatch, claims?.role, type, id])

  const data = useMemo(() => {
    return type === 'feature' ? feature : contract
  }, [contract, feature, type])

  const HasApiError = useMemo(() => {
    return contractFailed || featureFailed
  }, [contractFailed, featureFailed])

  const isContract = useMemo(() => {
    return type !== 'feature'
  }, [type])

  const reFetch = () => {
    if (type === 'feature') {
      if (claims?.role === UserRole.AGENT) {
        dispatch(fetchFeatureById({ id: id! }))
      } else {
        // TODO 売り主がアクセスした場合の処理
      }
    } else {
      dispatch(fetchContractById({ role: claims!.role!, id: id! }))
    }
  }

  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title={`SUUMO詳細 | ${contract?.name}`}
          loading={contractLoading || featureLoading}
          type="user"
        >
          <NextActionArea
            isContract={isContract}
            id={id!}
            suumoUnderAverageListPvPercent={
              contract?.nextActionInfo?.suumoUnderAverageListPvPercent
            }
            suumoUnderAverageTransitionRate={
              contract?.nextActionInfo?.suumoUnderAverageTransitionRate
            }
            refresh={reFetch}
          />
          <LatestImportedDatetime
            props={{
              importedDate:
                contract?.suumoLatestImportedDate ??
                feature?.suumoLatestImportedDate,
            }}
          />
          <Block>
            <SectionTitle>SUUMO詳細</SectionTitle>
          </Block>
          <Block>
            <SectionTitle>
              一覧PV
              <HelpTips text="対象物件が検索結果に表示された回数" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <SuumoListPVGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>
              詳細PV
              <HelpTips text="対象物件の詳細ページが表示された回数" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <SuumoDetailPVGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>
              一覧から詳細への遷移率
              <HelpTips text="検索結果から対象物件の詳細ページに遷移した率" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <SuumoListToDetailGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>HPリンククリック数</SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <SuumoHpClickGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>お気に入り登録数</SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <SuumoFavoriteGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>
              反響数
              <HelpTips text="掲載サイト上からのお問い合わせ件数" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <SuumoReactionGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
        </Page>
      )}
    </>
  )
}

export default SuumoDetail
