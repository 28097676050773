import Page from 'commons/page'
import Block from 'components/block'
import { Text, StrongText } from 'components/typography'
import NoLogoHeader from 'components/no-logo-header'

const Help = () => {
  return (
    <Page
      title="メールが届かない場合"
      type="guest"
      customHeader={
        <NoLogoHeader
          title={'メールが届かない場合'}
          visibleGlobalMenu={false}
          visibleReturn={true}
        />
      }
    >
      <Block>
        <StrongText>迷惑メールフォルダ等に振り分けられている</StrongText>
        <Text>
          Terassからのメールが、迷惑メールファルダやゴミ箱フォルダなどに振り分けられていないかご確認ください。
        </Text>
        <Text>
          また、Terassからのメールが受信できるように、terass.comのドメイン受信設定をお願いいたします。
        </Text>
      </Block>
      <Block>
        <StrongText>誤ったメールアドレスを入力している</StrongText>
        <Text>
          担当エージェントにお伝えしたメールアドレスを入力してください。
        </Text>
        <Text>
          メールアドレスが分からない場合は、担当エージェントに登録したメールアドレスをご確認ください。
        </Text>
      </Block>
      <Block>
        <StrongText>送信されたメールが遅延している</StrongText>
        <Text>メールが遅延している可能性があります。</Text>
        <Text>
          届かない場合は、少し時間を空けてメールが届くかをご確認ください。
        </Text>
      </Block>
    </Page>
  )
}

export default Help
