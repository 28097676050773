import { ContractStatus, MediaCampaignStatus } from 'schema/contract'

export const CONTRACT_STATUS_TYPE: {
  [key: string]: ContractStatus[]
} = {
  inProgress: ['契約準備中', '契約待ち', '契約完了', 'REINS掲載済み'],
  done: ['クローズ', 'キャンセル'],
}

export const FEATURE_STATUS_TYPE: {
  [key: string]: MediaCampaignStatus[]
} = {
  inProgress: ['掲載中'],
  done: ['掲載前', '掲載停止', 'クローズ', 'キャンセル'],
}
