import styled from 'styled-components'

interface HorizontalProps {
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  align?: 'flex-start' | 'center' | 'flex-end' | 'baseline'
  gap?: number
}

const Horizontal = styled.div<HorizontalProps>`
  display: flex;
  justify-content: ${(p) => (p.justify ? p.justify : 'flex-start')};
  align-items: ${(p) => (p.align ? p.align : 'flex-start')};
  gap: ${(p) => (p.gap ? `${p.gap}px` : 0)};
`
export default Horizontal
