import styled from 'styled-components'
import { Horizontal as BaseHorizontal } from 'components/layout'
import { Text } from 'components/typography'
import { format } from 'date-fns'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 40px) / 3);
  padding: 10px 0;
  border-bottom: 1px solid ${(p) => p.theme.BORDER};
  box-sizing: border-box;

  vertical-align: top;
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 100%;
  }
`

const Contents = styled.div`
  max-width: calc(100% - 10px);
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  height: 100%;
`

const Item = styled(BaseHorizontal)`
  width: 100%;
  vertical-align: top;
`
const Horizontal = styled(BaseHorizontal)`
  width: 100%;
  vertical-align: top;
`

const PropertyDate = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: bold;
`

const PropertyBody = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font-size: 12px;
`

export interface OtherListInquiryItemProps {
  item: { date: Date; body: string; isVisible?: boolean }
  isVisibleInquiryLabel: boolean
}

export const OtherListInquiryItem = ({
  item,
  isVisibleInquiryLabel,
}: OtherListInquiryItemProps) => {
  return (
    <Container>
      <Contents>
        <Item justify="space-between">
          <Horizontal>
            <PropertyDate>
              問い合わせ日時：{format(item.date, 'yyyy年MM月dd日 HH時mm分')}
            </PropertyDate>
          </Horizontal>
        </Item>
        <Horizontal align="center" gap={8}>
          <Horizontal>
            <PropertyBody>
              {isVisibleInquiryLabel && (
                <>
                  ■問い合わせ内容
                  <br />
                </>
              )}
              {item.body}
            </PropertyBody>
          </Horizontal>
        </Horizontal>
      </Contents>
    </Container>
  )
}
