import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 750px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    max-width: 100%;
  }
`

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)

const MultitypeGraph = ({
  data,
  options,
  plugins,
}: {
  data: any
  options: any
  plugins: any
}) => (
  <Container>
    <Bar
      data={data}
      options={options}
      plugins={[
        {
          ...ChartDataLabels,
          ...plugins,
        },
      ]}
    />
  </Container>
)

export default MultitypeGraph
