import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'

const SuumoReactionGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const documentRequestData = useMemo(() => {
    if (!contract?.suumo?.documentRequest) {
      return []
    }

    return createBarGraphData(contract?.suumo?.documentRequest, breakpoint)
  }, [breakpoint, contract?.suumo?.documentRequest])

  const visitorReservationData = useMemo(() => {
    if (!contract?.suumo?.visitorReservation) {
      return []
    }

    return createBarGraphData(contract?.suumo?.visitorReservation, breakpoint)
  }, [breakpoint, contract?.suumo?.visitorReservation])

  const documentRequestSum = useMemo(() => {
    return total30Days(contract?.suumo?.documentRequest || [])
  }, [contract?.suumo?.documentRequest])

  const visitorReservationSum = useMemo(() => {
    return total30Days(contract?.suumo?.visitorReservation || [])
  }, [contract?.suumo?.visitorReservation])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: `資料請求数`,
        data: documentRequestData,
        backgroundColor: theme.GRAPH_COLOR_03,
        borderWidth: 0,
      },
      {
        label: `来場予約数`,
        data: visitorReservationData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <StackedBarGraph
        data={data}
        options={BAR_GRAPH_OPTIONS}
        plugins={GRAPH_PLUGINS('件数')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日の反響数',
            value: visitorReservationSum + documentRequestSum,
            unit: '件',
            detail: {
              list: [
                {
                  label: '資料請求数',
                  value: documentRequestSum,
                  unit: '件',
                },
                {
                  label: '来場予約数',
                  value: visitorReservationSum,
                  unit: '件',
                },
              ],
            },
          },
        ]}
      />
    </>
  )
}

export default SuumoReactionGraph
