import { useCallback } from 'react'

import { sendEmailForVerification } from 'commons/firebase-auth'
import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import ButtonWrapper from 'components/layout/button-wrapper'
import { PageTitle, Text } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'

const NotVerification = () => {
  const { currentUser } = useAuthProvider()

  const handleSendEmail = useCallback(() => {
    if (!currentUser) {
      return
    }

    sendEmailForVerification(currentUser)
  }, [currentUser])

  return (
    <Page title="メールアドレス認証の確認" type="guest">
      <PageTitle>メールアドレス認証の確認</PageTitle>
      <Block>
        <Text type="warn">メールアドレス認証が完了していません。</Text>
        <Text>
          確認用メールに記載されているURLから、メールアドレス認証を行ってください。
        </Text>
      </Block>
      <Block>
        <Text>送信したEメールアドレス</Text>
        <Text>変更後：{currentUser?.email}</Text>
      </Block>
      <ButtonWrapper>
        <Button onClick={() => handleSendEmail()}>メールの再送</Button>
      </ButtonWrapper>
      <Block>
        <Text size="sm">
          メールアドレス変更をキャンセルする場合は、変更前メールアドレスに届くメールをご確認ください。メールに記載されているURLからキャンセルすることが出来ます。
        </Text>
      </Block>
    </Page>
  )
}

export default NotVerification
