import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Button from 'components/button'
import ErrorArea from 'components/error-area'
import { HeaderComponent } from 'components/header'
import Block from 'components/block'
import { ErrorMessage } from 'schema/error'
import { store } from 'stores'
import theme from 'styles/theme'

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    min-height: calc(100vh - 60px);
  }
`

const Container = styled.div`
  width: 100%;
  padding-top: 80px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  background-color: ${theme.BACKGROUND_BASE};

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  max-width: min(100vw - 60px, ${(p) => p.theme.vp}px);
  margin: 0 auto;
  padding: 80px 0;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    padding: 16px 0;
  }
`

interface Props {
  children: ReactNode
  onError?: (e: ErrorEvent | PromiseRejectionEvent | Error) => void
}

interface State {
  hasError: boolean
  messages: Array<ErrorMessage>
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      messages: [],
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  override componentDidMount() {
    window.addEventListener('error', this.handleGlobalError, true)
    window.addEventListener('unhandledrejection', this.handleGlobalError, true)
  }

  override componentDidCatch(error: Error) {
    this.handleGlobalError(error)
  }

  override componentWillUnmount() {
    store.dispatch({
      type: 'error/clearSystemErrors',
    })

    window.removeEventListener('error', this.handleGlobalError, true)
    window.removeEventListener(
      'unhandledrejection',
      this.handleGlobalError,
      true
    )
  }

  handleGlobalError = (e: ErrorEvent | PromiseRejectionEvent | Error) => {
    if (this.props.onError) {
      this.props.onError(e)
    }

    const messages: Array<ErrorMessage> = []
    if (e instanceof ErrorEvent) {
      messages.push({ key: e.type || 'error', message: e.message })
    } else if (e instanceof Error) {
      messages.push({ key: 'error', message: e.message })
    } else if (e instanceof PromiseRejectionEvent) {
      messages.push({ key: e.type || 'error', message: e.reason.message })
    }

    this.setState({
      hasError: true,
      messages,
    })
  }

  override render() {
    if (this.state.hasError) {
      return (
        <Box>
          <HeaderComponent />
          <Container>
            <Contents>
              <Block>
                <ErrorArea
                  messages={
                    this.state.messages.length > 0
                      ? this.state.messages
                      : [
                          {
                            key: 'systemError',
                            message: '予期せぬエラーが発生しました',
                          },
                        ]
                  }
                />
              </Block>
              <Block>
                <Button
                  onClick={() => {
                    window.location.href = '/'
                  }}
                >
                  トップページへ
                </Button>
              </Block>
            </Contents>
          </Container>
        </Box>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
