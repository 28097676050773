import { getUnixTime } from 'date-fns'
import {
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { ChatMessage, ChatMessageReadStateSchema, ChatRoom } from 'schema/chat'
import { collections } from 'commons/firebase-store'

const fetchChatRoom = async (
  contractId: string,
  userId: string,
  updateLastMessage: boolean
): Promise<ChatRoom | undefined> => {
  const chatRoom = (await getDoc(doc(collections.chatRoom, contractId))).data()
  const chatRoomMessages = await fetchChatRoomMessages(contractId)
  if (chatRoom) {
    chatRoom.messages = chatRoomMessages
    if (updateLastMessage) {
      await updateReadStateOnFetchingRoom({
        contractId,
        userId,
        room: chatRoom,
      })
    }
  }
  return chatRoom
}

const fetchChatRoomMessages = async (contractId: string) => {
  const chatMessages: {
    [key in string]: ChatMessage
  } = {}
  const snapshots = await getDocs(
    query(collections.chatRoomMessage(contractId))
  )
  snapshots.docs.map((snapshot) => {
    chatMessages[snapshot.id] = snapshot.data()
    return snapshot.data()
  })
  return chatMessages
}

const registerChatRoom = async (
  contractId: string,
  users: {
    ownerId: string
    agentId: string
  }
): Promise<ChatRoom> => {
  const chatRoomRef = doc(collections.chatRoom, contractId)
  const chatRoomDoc = await getDoc(chatRoomRef)
  if (chatRoomDoc.exists()) {
    const chatRoom = chatRoomDoc.data()
    return chatRoom
  }
  const chatRoom: ChatRoom = {
    messages: {},
    users: users,
  }
  await setDoc(chatRoomRef, chatRoom)
  return chatRoom
}

const createChatMessage = async (
  contractId: string,
  messageBody: {
    message: string
    senderId: string
  }
): Promise<ChatMessage> => {
  const unixTime = getUnixTime(new Date())
  const messageId = `${unixTime}.${messageBody.senderId}`
  const chatRoomMessageRef = doc(
    collections.chatRoomMessage(contractId),
    messageId
  )
  const chatMessage: ChatMessage = {
    id: messageId,
    ...messageBody,
    createdAt: unixTime,
    messageKind: 'message',
  }
  await setDoc(chatRoomMessageRef, chatMessage)
  return chatMessage
}

const createChatFileMessage = async (
  contractId: string,
  messageBody: {
    senderId: string
    file: string
    originalFileName: string
  }
): Promise<ChatMessage> => {
  const unixTime = getUnixTime(new Date())
  const messageId = `${unixTime}.${messageBody.senderId}`
  const chatRoomMessageRef = doc(
    collections.chatRoomMessage(contractId),
    messageId
  )
  const chatMessage: ChatMessage = {
    id: messageId,
    ...messageBody,
    message: '',
    createdAt: unixTime,
    messageKind: 'file',
  }
  await setDoc(chatRoomMessageRef, chatMessage)
  return chatMessage
}

const updateChatReadStatus = async (params: {
  contractId: string
  userId: string
  lastMessage: ChatMessage
}): Promise<ChatMessageReadStateSchema> => {
  const chatRoomRef = doc(collections.chatRoom, params.contractId)
  const chatRoom = (await getDoc(chatRoomRef)).data()
  const messageReadState: ChatMessageReadStateSchema = {
    ...chatRoom?.messageReadState,
  }
  if (chatRoom) {
    messageReadState[params.userId] = params.lastMessage?.id
  }
  await updateDoc(chatRoomRef, { messageReadState })
  return messageReadState
}

const updateReadStateOnFetchingRoom = async (params: {
  contractId: string
  room: ChatRoom
  userId: string
}) => {
  const messages = params.room.messages
    ? Object.values(params.room.messages)
    : []
  if (!messages.length) return
  const lastMessage = messages[messages.length - 1]
  if (!lastMessage) return
  await updateChatReadStatus({
    contractId: params.contractId,
    userId: params.userId,
    lastMessage,
  })
}

const ChatApi = {
  fetchChatRoom,
  registerChatRoom,
  createChatMessage,
  createChatFileMessage,
  updateChatReadStatus,
}

export default ChatApi
