import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import chatApi from 'apis/chat'
import { AsyncTaskStatus } from 'schema/common'
import { RootState } from 'stores'
import { getTypePrefix } from 'utils'
import { ChatMessage, ChatMessageReadStateSchema, ChatRoom } from 'schema/chat'
// import { collections, subscribePromisDocument, subscribeQueryPromise } from 'commons/firebase-store'
// import { doc, limitToLast, orderBy, query } from 'firebase/firestore'

export const CHAT_STORE_NAME = 'chat'

export const fetchChatRoom = createAsyncThunk<
  ChatRoom,
  {
    id: string
    userId: string
    users: {
      ownerId: string
      agentId: string
    }
    updateLastMesage: boolean
  }
>(
  'fetchChatRoom',
  async ({ id, userId, users, updateLastMesage }, { rejectWithValue }) => {
    try {
      const chatRoom = await chatApi.fetchChatRoom(id, userId, updateLastMesage)
      if (chatRoom) {
        return chatRoom
      } else {
        return await chatApi.registerChatRoom(id, users)
      }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const registerChatRoom = createAsyncThunk<
  ChatRoom,
  {
    id: string
    users: {
      ownerId: string
      agentId: string
    }
  }
>('registerChatRoom', async ({ id, users }, { rejectWithValue }) => {
  try {
    return await chatApi.registerChatRoom(id, users)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const createChatMessage = createAsyncThunk<
  ChatMessage,
  {
    id: string
    messageBody: {
      message: string
      senderId: string
    }
  }
>('createChatMessage', async ({ id, messageBody }, { rejectWithValue }) => {
  try {
    return await chatApi.createChatMessage(id, messageBody)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const createChatFileMessage = createAsyncThunk<
  ChatMessage,
  {
    id: string
    messageBody: {
      file: string
      originalFileName: string
      senderId: string
    }
  }
>('createChatFileMessage', async ({ id, messageBody }, { rejectWithValue }) => {
  try {
    return await chatApi.createChatFileMessage(id, messageBody)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const updateChatMessages = createAsyncThunk<
  ChatMessage[],
  {
    id: string
    messages: ChatMessage[]
  }
>('updateChatMessages', async ({ messages }, { rejectWithValue }) => {
  try {
    return messages
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const updateChatRoom = createAsyncThunk<
  ChatRoom,
  {
    id: string
    room: ChatRoom
  }
>('updateChatRoom', async ({ room }, { rejectWithValue }) => {
  try {
    return room
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const updateChatReadStatus = createAsyncThunk<
  ChatMessageReadStateSchema,
  {
    id: string
    userId: string
    lastMessage: ChatMessage
  }
>(
  'updateChatReadStatus',
  async ({ id, userId, lastMessage }, { rejectWithValue }) => {
    try {
      return await chatApi.updateChatReadStatus({
        contractId: id,
        userId,
        lastMessage,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

interface ChatState {
  chatRooms: {
    [key in string]: ChatRoom
  }
  status: Record<string, AsyncTaskStatus>
}

export const initialState: ChatState = {
  chatRooms: {},
  status: {
    [fetchChatRoom.typePrefix]: 'idle',
    [registerChatRoom.typePrefix]: 'idle',
    [createChatMessage.typePrefix]: 'idle',
    [createChatFileMessage.typePrefix]: 'idle',
    [updateChatMessages.typePrefix]: 'idle',
    [updateChatRoom.typePrefix]: 'idle',
    [updateChatReadStatus.typePrefix]: 'idle',
  },
}

export const chatSlice = createSlice({
  name: CHAT_STORE_NAME,
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatRoom.fulfilled, (state, action) => {
        state.chatRooms[action.meta.arg.id] = {
          ...state.chatRooms[action.meta.arg.id],
          ...action.payload,
        }
      })
      .addCase(registerChatRoom.fulfilled, (state, action) => {
        state.chatRooms[action.meta.arg.id] = {
          ...state.chatRooms[action.meta.arg.id],
          ...action.payload,
        }
      })
      .addCase(createChatMessage.fulfilled, (state, action) => {
        const chatRoom = state.chatRooms[action.meta.arg.id]
        if (chatRoom && chatRoom.messages) {
          const messages = chatRoom.messages
          messages[action.payload.id] = action.payload
          chatRoom.messages = messages
          state.chatRooms[action.meta.arg.id] = chatRoom
        }
      })
      .addCase(createChatFileMessage.fulfilled, (state, action) => {
        const chatRoom = state.chatRooms[action.meta.arg.id]
        if (chatRoom && chatRoom.messages) {
          const messages = chatRoom.messages
          messages[action.payload.id] = action.payload
          chatRoom.messages = messages
          state.chatRooms[action.meta.arg.id] = chatRoom
        }
      })
      .addCase(updateChatMessages.fulfilled, (state, action) => {
        const roomId = action.meta.arg.id
        const chatRoom = state.chatRooms[roomId]
        if (chatRoom && chatRoom.messages) {
          const messages = chatRoom.messages
          action.payload.forEach((item) => {
            messages[item.id] = item
          })
          state.chatRooms[action.meta.arg.id] = chatRoom
        }
      })
      .addCase(updateChatRoom.fulfilled, (state, action) => {
        const chatRoom = state.chatRooms[action.meta.arg.id]
        if (chatRoom) {
          state.chatRooms[action.meta.arg.id] = {
            ...chatRoom,
            ...action.payload,
            messages: chatRoom.messages,
          }
        }
      })
      .addCase(updateChatReadStatus.fulfilled, (state, action) => {
        const roomId = action.meta.arg.id
        const chatRoom = state.chatRooms[roomId]
        if (chatRoom) {
          chatRoom.messageReadState = action.payload
          state.chatRooms[action.meta.arg.id] = chatRoom
        }
      })
      .addMatcher(isPending, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'loading'
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'idle'
      })
      .addMatcher(isRejected, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'failed'
        throw action.payload
      })
  },
})

export const { resetStore } = chatSlice.actions

export const selectChatRoom = (id: string) => (state: RootState) => {
  return state.chat.chatRooms[id]
}

export const selectChatRoomFetching = (state: RootState) =>
  state.chat.status[fetchChatRoom.typePrefix] === 'loading'

export const selectChatRoomCreating = (state: RootState) =>
  state.chat.status[registerChatRoom.typePrefix] === 'loading'

export default chatSlice.reducer
