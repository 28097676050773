import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'

const AtHomeReactionGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const pcData = useMemo(() => {
    if (!contract?.athome?.documentRequestForPc) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.documentRequestForPc,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.documentRequestForPc])

  const smartPhoneData = useMemo(() => {
    if (!contract?.athome?.documentRequestForSmartphone) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.documentRequestForSmartphone,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.documentRequestForSmartphone])

  const featurePhoneData = useMemo(() => {
    if (!contract?.athome?.documentRequestForFeaturephone) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.documentRequestForFeaturephone,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.documentRequestForFeaturephone])

  const lineData = useMemo(() => {
    if (!contract?.athome?.documentRequestForLINE) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.documentRequestForLINE,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.documentRequestForLINE])

  const freeCallData = useMemo(() => {
    if (!contract?.athome?.documentRequestForFreeCall) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.documentRequestForFreeCall,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.documentRequestForFreeCall])

  const pcSum = useMemo(() => {
    return total30Days(contract?.athome?.documentRequestForPc || [])
  }, [contract?.athome?.documentRequestForPc])

  const smartphoneSum = useMemo(() => {
    return total30Days(contract?.athome?.documentRequestForSmartphone || [])
  }, [contract?.athome?.documentRequestForSmartphone])

  const featurePhoneSum = useMemo(() => {
    return total30Days(contract?.athome?.documentRequestForFeaturephone || [])
  }, [contract?.athome?.documentRequestForFeaturephone])

  const LINESum = useMemo(() => {
    return total30Days(contract?.athome?.documentRequestForLINE || [])
  }, [contract?.athome?.documentRequestForLINE])

  const freeCallSum = useMemo(() => {
    return total30Days(contract?.athome?.documentRequestForFreeCall || [])
  }, [contract?.athome?.documentRequestForFreeCall])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: `PC`,
        data: pcData,
        backgroundColor: theme.GRAPH_COLOR_03,
        borderWidth: 0,
      },
      {
        label: `スマホ`,
        data: smartPhoneData,
        backgroundColor: theme.GRAPH_COLOR_04,
        borderWidth: 0,
      },
      {
        label: `ケータイ`,
        data: featurePhoneData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
      {
        label: `LINE`,
        data: lineData,
        backgroundColor: theme.GRAPH_COLOR_07,
        borderWidth: 0,
      },
      {
        label: '無料通話',
        data: freeCallData,
        backgroundColor: theme.GRAPH_COLOR_09,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <StackedBarGraph
        data={data}
        options={BAR_GRAPH_OPTIONS}
        plugins={GRAPH_PLUGINS('PV')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日の反響数',
            value: featurePhoneSum + smartphoneSum + pcSum,
            unit: '回',
            detail: {
              list: [
                {
                  label: 'PC',
                  value: pcSum,
                  unit: '回',
                },
                {
                  label: 'スマホ',
                  value: smartphoneSum,
                  unit: '回',
                },
                {
                  label: 'ケータイ',
                  value: featurePhoneSum,
                  unit: '回',
                },
                {
                  label: 'LINE',
                  value: LINESum,
                  unit: '回',
                },
                {
                  label: '無料通話',
                  value: freeCallSum,
                  unit: '回',
                },
              ],
            },
          },
        ]}
      />
    </>
  )
}

export default AtHomeReactionGraph
