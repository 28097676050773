import { useRef } from 'react'
import styled from 'styled-components'

import Button from 'components/button'

const ChatSendButton = styled(Button)<{ icon: string; color: string }>`
  width: 44px;
  height: 44px;
  background-image: url('/image/${(p) => p.icon}.svg');
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
`

const HiddenInput = styled('input')<any>`
  display: none;
`

interface Props {
  onChangeFile: (file: File | undefined) => void
}

const SendFileButton = ({ onChangeFile }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <>
      <ChatSendButton
        icon="chat-clip"
        color="thertary"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click()
          }
        }}
      />
      <HiddenInput
        type="file"
        ref={inputRef}
        accept="image/*, application/pdf"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const file = e.target.files ? e.target.files[0] : undefined
          onChangeFile(file)
        }}
      />
    </>
  )
}

const SendMessageButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean
  onClick: () => void
}) => {
  return (
    <ChatSendButton
      icon="send-message"
      color="primary"
      onClick={onClick}
      data-testid="send"
      disabled={disabled}
    />
  )
}

export { SendFileButton, SendMessageButton }
