import BaseBlock from './block'
import { SectionTitle as BaseSectionTitle } from './typography'
import styled from 'styled-components'
import theme from 'styles/theme'
import React, { useEffect, useRef, useState } from 'react'
import HelpTips from './help-tip'

const SectionTitle = styled(BaseSectionTitle)`
  text-underline: black;
  margin-left: 10px;
  padding-top: 10px;
  display: flex;
`

const PlaceHolder = styled.div<{
  height: number
}>`
  height: 0;
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: ${(p) => p.height}px;
  }
`

interface BlockProps {
  height?: number
}

const Block = styled(BaseBlock)<BlockProps>`
  background: ${theme.NOTIFICATION};
  margin-top: -50px;
  padding-bottom: 10px;
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    position: absolute;
    margin-top: inherit;
    left: 0;
    // Headerのサイズと合わせている
    top: 60px;
    width: 100vw;
  }
`

const Separator = styled.hr`
  background: black;
  height: 1px;
  color: black;
  border: none;
  margin: 10px;
`

const ActionArea = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 10px;
`
const ActionButton = (props: { onClick: () => void }) => {
  return (
    <>
      <button onClick={props.onClick}>表示しない</button>
    </>
  )
}

const NotificationArea = (props: {
  title: string
  tipText?: string
  children?: React.ReactNode
  onAction?: () => void
}) => {
  const blockRef = useRef<HTMLDivElement>(null)
  const [blockHeight, setBlockHeight] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (blockRef.current) {
        setBlockHeight(blockRef.current.offsetHeight - 20)
      }
    }

    // Initial call
    handleResize()

    // Listen to window resize events
    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [blockRef?.current?.offsetHeight])

  return (
    <>
      <PlaceHolder height={blockHeight} />
      <Block ref={blockRef}>
        <SectionTitle>
          {props.title}
          {props.tipText && <HelpTips text={props.tipText} />}
          {props.onAction && (
            <ActionArea>
              <ActionButton onClick={props.onAction} />
            </ActionArea>
          )}
        </SectionTitle>
        <Separator />
        {props.children}
      </Block>
    </>
  )
}

export default NotificationArea
