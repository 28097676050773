import styled, { css, StyledProps } from 'styled-components'

interface TextProps {
  align?: 'left' | 'center' | 'right'
  size?: 'sm' | 'md' | 'lg'
  type?: 'warn'
}

const cssOfSize = (props: StyledProps<TextProps>) => {
  const { size = 'lg' } = props
  if (size === 'sm') {
    return css`
      font-size: 12px;
      line-height: 18px;

      @media only screen and (max-width: ${(p) => p.theme.vp}px) {
        font-size: 10px;
        line-height: 15px;
      }
    `
  }

  if (size === 'md') {
    return css`
      font-size: 14px;
      line-height: 21px;

      @media only screen and (max-width: ${(p) => p.theme.vp}px) {
        font-size: 12px;
        line-height: 18px;
      }
    `
  }

  return css`
    font-size: 16px;
    line-height: 24px;

    @media only screen and (max-width: ${(p) => p.theme.vp}px) {
      font-size: 14px;
      line-height: 21px;
    }
  `
}

const cssOfType = (props: StyledProps<TextProps>) => {
  const { type = '' } = props
  return css`
    color: ${(p) =>
      type === 'warn' ? p.theme.ERROR_FONT_COLOR : p.theme.FONT_COLOR};
  `
}

const Text = styled.p<TextProps>`
  margin: 0;
  text-align: ${(p) => (p.align ? p.align : 'left')};
  ${cssOfSize}
  ${cssOfType}
`

export default Text
