import { fireStorage, fireStorageRef } from 'commons/firebase-storage'
import { getDownloadURL } from 'firebase/storage'
import { getMetadata } from 'firebase/storage'
import React from 'react'

export type FileType = 'image' | 'pdf' | 'other'
const useGetDownloadURL = (path: string) => {
  const [url, setUrl] = React.useState<string | null>(null)
  const [fileName, setFileName] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [fileType, setFileType] = React.useState<FileType | null>(null)

  React.useEffect(() => {
    const storageRef = fireStorageRef(fireStorage, path)

    setFileName(storageRef.name)

    getMetadata(storageRef).then((metadata) => {
      const isImage = metadata.contentType?.startsWith('image/')
      if (isImage) {
        setFileType('image')
        return
      }

      // pdfの場合
      const isPdf = metadata.contentType?.startsWith('application/pdf')
      if (isPdf) {
        setFileType('pdf')
        return
      }

      // それ以外
      setFileType('other')
    })
    getDownloadURL(storageRef)
      .then((url) => setUrl(url))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [path])

  return {
    url,
    fileName,
    loading,
    error,
    fileType,
  }
}
export { useGetDownloadURL }
