import { User } from 'firebase/auth'

export enum UserRole {
  OWNER = 'owner',
  AGENT = 'agent',
}

interface FBUser extends User {
  claims?: {
    role: UserRole.OWNER | UserRole.AGENT
  }
}

export type FirebaseUser = FBUser | null | undefined

// 通知設定
export interface NotificationSetting {
  key: string
  isEnable: boolean
}

export const authenticationFederationProviderIdList = [
  'google.com',
  'facebook.com',
  'twitter.com',
] as const

export const authenticationProviderIdList = [
  'password',
  ...authenticationFederationProviderIdList,
] as const

export type AuthenticationFederationProviderId =
  (typeof authenticationFederationProviderIdList)[number]

export type AuthenticationProviderId =
  (typeof authenticationProviderIdList)[number]

export const isAuthenticationFederationProviderId = (
  idString: string
): idString is AuthenticationFederationProviderId => {
  return authenticationFederationProviderIdList.some((v) => v === idString)
}

export const isAuthenticationProviderId = (
  idString: string
): idString is AuthenticationProviderId => {
  return authenticationProviderIdList.some((v) => v === idString)
}
