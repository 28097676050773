import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import Page from 'commons/page'
import Button from 'components/button'
import Block from 'components/block'
import { ButtonWrapper } from 'components/layout'
import { Text } from 'components/typography'
import { UPDATE_MAIL } from 'constants/common'
import ROUTING_PATH from 'constants/route'
import styled from 'styled-components'
import BasePanel from '../../components/panel'
import NoLogoHeader from '../../components/no-logo-header'

const Panel = styled(BasePanel)`
  background: transparent;
  border: none;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  margin: 0 auto;
`

const FullButton = styled(Button)`
  width: 100%;
`

const EmailSettingDone = () => {
  const [setting, setSetting] = useState<{ before: string; after: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const data = localStorage.getItem(UPDATE_MAIL)
    setSetting(JSON.parse(data || '{}'))
  }, [])

  return (
    <Page
      title="確認用メール送信完了"
      customHeader={
        <NoLogoHeader title="確認用メール送信完了" visibleReturn={false} />
      }
    >
      <Panel>
        <Block>
          <Text>確認用メール送信が完了しました。</Text>
          <Text>
            メールに記載されているURLから、メールアドレス認証を行ってください。
          </Text>
        </Block>
        <Block>
          <Text>送信したEメールアドレス</Text>
          <Text>変更前：{setting?.before}</Text>
          <Text>変更後：{setting?.after}</Text>
        </Block>
        <ButtonWrapper>
          <FullButton onClick={() => navigate(ROUTING_PATH.B0101)}>
            ログイン画面へ
          </FullButton>
        </ButtonWrapper>
      </Panel>
    </Page>
  )
}

export default EmailSettingDone
