import { createSlice } from '@reduxjs/toolkit'

import { ApiError } from 'schema/api'
import { RootState } from 'stores'

interface ErrorState {
  errors: Record<string, ApiError>
  errorPage: ApiError | undefined
}

const initialState: ErrorState = {
  errors: {},
  errorPage: undefined,
}

export const ERROR_STORE_NAME = 'error'

export const errorSlice = createSlice({
  name: ERROR_STORE_NAME,
  initialState,
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    clearErrors: (state) => {
      state.errors = {}
    },
  },
})

export const selectErrors = (state: RootState) => state.error.errors

export const selectErrorPage = (state: RootState) => state.error.errorPage

export const { clearErrors, setErrors } = errorSlice.actions

export default errorSlice.reducer
