import styled from 'styled-components'
import { TwitterLoginButton } from 'react-social-login-buttons'

const SocialLoginButtonLabel = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`

export const TwitterLogo = ({
  labelText,
  onClick,
}: {
  labelText: string
  onClick?: () => any
}) => {
  return (
    <TwitterLoginButton
      style={{ borderRadius: '6px', cursor: onClick ? 'pointer' : 'default' }}
      disabled={!onClick}
      onClick={onClick}
    >
      <SocialLoginButtonLabel>{labelText}</SocialLoginButtonLabel>
    </TwitterLoginButton>
  )
}
