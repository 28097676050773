import { useEffect } from 'react'
import { useAppDispatch } from 'hooks'
import {
  collections,
  subscribeDocument,
  subscribeQuery,
} from 'commons/firebase-store'
import { doc, limitToLast, orderBy, query } from 'firebase/firestore'
import { ChatMessage, ChatRoom } from 'schema/chat'
import { updateChatMessages, updateChatRoom } from 'stores/chat'

export const useMessagesChange = (
  id: string,
  onUpdateLastMessage: (message?: ChatMessage) => void
) => {
  const dispatch = useAppDispatch()

  const onChangeSnapshot = (messages: ChatMessage[]) => {
    if (id && messages.length) {
      dispatch(
        updateChatMessages({
          id,
          messages,
        })
      )
      onUpdateLastMessage(messages[messages.length - 1])
    }
  }

  const onCacheError = () => {}

  useEffect(() => {
    let unsubscribe: any
    if (id) {
      unsubscribe = subscribeQuery(
        query(
          collections.chatMessage(id),
          orderBy('createdAt', 'asc'),
          limitToLast(10)
        ),
        onChangeSnapshot,
        onCacheError,
        { ignorePendingWrites: false }
      )
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch])
}

export const useRoomChange = (id: string) => {
  const dispatch = useAppDispatch()

  const onChangeSnapshot = (room: ChatRoom) => {
    if (id && room) {
      dispatch(
        updateChatRoom({
          id,
          room,
        })
      )
    }
  }

  const onCacheError = () => {}

  useEffect(() => {
    let unsubscribe: any
    if (id) {
      unsubscribe = subscribeDocument(
        doc(collections.chatRoom, id),
        onChangeSnapshot,
        onCacheError,
        { ignorePendingWrites: false }
      )
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch])
}
