import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'

const AtHomeMoviePlaysGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const graphData = useMemo(() => {
    if (!contract?.athome?.moviePlayCount) {
      return []
    }

    return createBarGraphData(contract?.athome?.moviePlayCount, breakpoint)
  }, [breakpoint, contract?.athome?.moviePlayCount])

  const sum = useMemo(() => {
    return total30Days(contract?.athome?.moviePlayCount || [])
  }, [contract?.athome?.moviePlayCount])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: '',
        data: graphData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <StackedBarGraph
        data={data}
        options={{
          ...BAR_GRAPH_OPTIONS,
          plugins: {
            ...BAR_GRAPH_OPTIONS.plugins,
            legend: {
              ...BAR_GRAPH_OPTIONS.plugins?.legend,
              display: false,
            },
          },
        }}
        plugins={GRAPH_PLUGINS('回数')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日の再生数',
            value: sum,
            unit: '回',
          },
        ]}
      />
    </>
  )
}

export default AtHomeMoviePlaysGraph
