import { FirebaseError } from '@firebase/util'
import { AuthErrorCodes } from 'firebase/auth'
import log from 'loglevel'
import { useEffect, useState } from 'react'

import { executeApply, verificationActionCode } from 'commons/firebase-auth'
import Page from 'commons/page'
import Button from 'components/button'
import Block from 'components/block'
import { ButtonWrapper } from 'components/layout'
import { PageTitle, Text } from 'components/typography'
import { ERRORS } from 'constants/common'
import ROUTING_PATH from 'constants/route'
import { useAppDispatch } from 'hooks'
import { clearErrors, setErrors } from 'stores/error'

interface PageProp {
  actionCode: string
  redirectUrl: string | null
}

const RecoverEmail = ({ actionCode, redirectUrl }: PageProp) => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    try {
      setLoading(true)
      dispatch(clearErrors())
      verificationActionCode(actionCode)
        .then(() => {
          executeApply(actionCode).catch((e) => {
            if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
              dispatch(
                setErrors({ action: { message: ERRORS['E0204']?.message } })
              )
            }
          })
        })
        .catch((e) => {
          if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
            dispatch(
              setErrors({ action: { message: ERRORS['E0204']?.message } })
            )
          }
        })
        .then(() => {
          setLoading(false)
        })
    } catch (e) {
      if (e instanceof FirebaseError) {
        log.error(e)
      }
    }
    setLoading(false)
  }, [actionCode, dispatch])

  return (
    <Page title="メールアドレス変更キャンセル" loading={loading} type="guest">
      <PageTitle>メールアドレス変更キャンセル</PageTitle>
      <Block>
        <Text>メールアドレスの変更をキャンセルしました。</Text>
        <Text>引き続きサービスをお使いください。</Text>
      </Block>
      <ButtonWrapper>
        <Button
          onClick={() => {
            window.location.href = redirectUrl || ROUTING_PATH.B0101
          }}
        >
          ログイン画面へ
        </Button>
      </ButtonWrapper>
    </Page>
  )
}

export default RecoverEmail
