import { format } from 'date-fns'
import { FaUser, FaUserTie } from 'react-icons/fa'
import styled from 'styled-components'

import { useAuthProvider } from 'contexts/auth'
import theme from '../../../styles/theme'
import ChatFilePreview from './chat-file-preview'
import { ChatMessage, SendUser } from 'schema/chat'
import { Text } from 'components/typography'

const ChatMessageContainer = styled('div')<any>`
  display: flex;
  align-items: flex-end;
  flex-direction: ${(p) => (p.isSender ? 'row-reverse' : 'row')};
  gap: 0 8px;
  padding: 10px 0;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    padding: 10px 0;
  }
`
const ChatMessageSenderContainer = styled('div')<any>`
  display: flex;
  align-items: center;

  :last-child {
    margin-bottom: 0;
  }
`
const ChatMessageSenderContainerBody = styled('div')<any>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  :last-child {
    margin-bottom: 0;
  }
`

const ChatMessageItemContainer = styled('div')<any>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;

  :last-child {
    margin-bottom: 0;
  }
`

const ChatMessageContent = styled('div')<any>`
  max-width: 800px;
  padding: 10px;
  background: ${(p) => (p.isSender ? p.theme.CHAT_SELF : p.theme.CHAT_OTHER)};
  border-radius: 100px;
  border-radius: ${(p) =>
    p.isSender ? '10px 10px 1px 10px' : '10px 10px 10px 1px'};
  white-space: pre-wrap;

  ${Text} {
    color: ${(p) => (p.isSender ? p.theme.WHITE : p.theme.FONT_COLOR)};
  }
`

interface Props {
  message: ChatMessage
  isRead: boolean
  user: SendUser | null
}

const ChatMessageItem = ({ message, isRead, user }: Props) => {
  const { currentUser } = useAuthProvider()
  const isOutgoing = message.senderId === currentUser?.uid
  const isAgent = user?.role === 'agent'

  return (
    <>
      {user && !isOutgoing && (
        <ChatMessageSenderContainer isSender={isOutgoing}>
          <ChatMessageSenderContainerBody>
            {isAgent && (
              <>
                <FaUserTie color={theme.MAIN} />
                <Text size={'sm'}>エージェント</Text>
              </>
            )}
            {!isAgent && (
              <>
                <FaUser color={theme.MAIN} />
                <Text size={'sm'}>{user?.name}</Text>
              </>
            )}
          </ChatMessageSenderContainerBody>
        </ChatMessageSenderContainer>
      )}
      <ChatMessageContainer isSender={isOutgoing}>
        <ChatMessageItemContainer isSender={isOutgoing}>
          {message.messageKind === 'file' && message.file ? (
            <ChatFilePreview
              path={message.file}
              originalFileName={message.originalFileName}
              isSender={isOutgoing}
            />
          ) : (
            <ChatMessageContent isSender={isOutgoing}>
              <Text>{message.message}</Text>
            </ChatMessageContent>
          )}
        </ChatMessageItemContainer>
        <div>
          {isOutgoing && isRead && <Text size="sm">既読</Text>}
          <Text size="sm">
            {format(new Date(message.createdAt * 1000), 'HH:mm')}
          </Text>
        </div>
      </ChatMessageContainer>
    </>
  )
}

export default ChatMessageItem
