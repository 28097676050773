import log from 'loglevel'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { singinForAgent } from 'commons/firebase-auth'
import Page from 'commons/page'
import Block from 'components/block'
import { PageTitle, Text } from 'components/typography'
import { ErrorMsg } from 'constants/message'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch } from 'hooks'
import { setErrors } from 'stores/error'
import { AuthErrorCodes } from 'firebase/auth'
import { UserRole } from 'schema/user'

const LoginButton = styled.button`
  display: block;
  border: none;
  width: 191px;
  height: 46px;
  margin: 0 auto;
  padding: 0;

  img {
    width: 100%;
  }
`

const AgentLogin = () => {
  const { currentUser, claims } = useAuthProvider()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser && claims && claims?.role === UserRole.AGENT) {
      navigate(ROUTING_PATH.C0201)
    }
  }, [claims, currentUser, navigate])

  const handleSubmit = useCallback(async () => {
    try {
      await singinForAgent()
      navigate(ROUTING_PATH.C0201)
    } catch (e: any) {
      log.error(e)
      if (e.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
        dispatch(setErrors({ login: { message: ErrorMsg.FORBIDDEN } }))
      }
    }
  }, [dispatch, navigate])

  return (
    <Page title="ログイン" type="guest">
      <PageTitle>エージェント向けログインページ</PageTitle>
      <Block>
        <LoginButton onClick={handleSubmit} data-testid="social-login">
          <img src="/image/sign-in-google.png" alt="google" />
        </LoginButton>
      </Block>
      <Block>
        <Text size="sm">
          これは売主様と媒介契約を結んだエージェント向けのツールのログインページです。
        </Text>
        <Text size="sm">
          ログインにはTerassのGoogleアカウントを使用してください
        </Text>
      </Block>
    </Page>
  )
}

export default AgentLogin
