import request from 'apis/request'
const setting = (
  contractId: string,
  address: string,
  size: number,
  buildDate: string
) => {
  return request({
    method: 'post',
    url: `/agents/active-inventory/setting`,
    data: {
      contractId,
      address,
      size,
      buildDate,
    },
  })
}

const clear = (contractId: string) => {
  return request({
    method: 'post',
    url: `/agents/active-inventory/clear`,
    data: {
      contractId,
    },
  })
}

const updateActiveInventoryVisibility = (
  id: string,
  contractId: string,
  isVisible: boolean
) =>
  request({
    method: 'post',
    url: `/agents/active-inventory/update-visibility`,
    data: {
      id,
      contractId,
      isVisible,
    },
  })

const ActiveInventoryApi = {
  setting,
  clear,
  updateActiveInventoryVisibility,
}

export default ActiveInventoryApi
