import request from 'apis/request'
import { NotificationSetting } from '../schema/user'

const verifyEmail = () =>
  request({
    method: 'post',
    url: `/owners/verify`,
    data: {},
  })

const fetchNotificationSettings = () =>
  request<Array<NotificationSetting>>({
    method: 'get',
    url: `/owners/notification/settings`,
    data: {},
  })

const saveNotificationSetting = (key: string, isEnable: boolean) =>
  request<Array<NotificationSetting>>({
    method: 'post',
    url: `/owners/notification/settings`,
    data: {
      key,
      isEnable,
    },
  })

const resentInvite = (mail: string) =>
  request({
    method: 'post',
    url: `/agents/invite`,
    data: {
      mail,
    },
  })

const UserApi = {
  verifyEmail,
  fetchNotificationSettings,
  saveNotificationSetting,
  resentInvite,
}

export default UserApi
