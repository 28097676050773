import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { Textarea } from 'components/input-control'
import { Horizontal } from 'components/layout'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch } from 'hooks'
import { SendFileButton, SendMessageButton } from './chat-message-button'
import { useFileUpload } from '../../../pages/contract/hooks/useFileUpload'
import { Contract } from 'schema/contract'
import { createChatMessage, createChatFileMessage } from 'stores/chat'
import getLayer from 'styles/layer'

const Container = styled('div')<any>`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: ${getLayer('footer')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100vw;
  padding: 10px 20px 4px; // react-textarea-autosizeを使用するとがコンテナが6px大きくなるので下の余白を調整
  background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: fit-content;
    padding: 0;
  }
`

const TextareaWrappre = styled.div`
  position: relative;
  width: 100%;
  max-width: 900px;
`

const ChatTextarea = styled(Textarea)`
  padding-right: 120px;
  resize: vertical;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    box-shadow: none;
  }
`

const MessageMenu = styled(Horizontal)`
  position: absolute;
  bottom: 16px; // react-textarea-autosizeを使用するとがコンテナが6px大きくなるので位置調整必要
  right: 10px;
  gap: 0 10px;
`

const ChatFooter = ({ contract }: { contract?: Contract }) => {
  const { currentUser } = useAuthProvider()
  const dispatch = useAppDispatch()
  const [composing, setComposing] = useState(false)
  const [inputMsg, setInputMsg] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const { id } = useParams<{ id: string }>()
  const senderId = useMemo(() => currentUser?.uid, [currentUser?.uid])
  const contractId = useMemo(() => id ?? contract?.id ?? '', [contract?.id, id])
  const location = useLocation()
  const { state } = location as {
    state: { fixedMessage: string | null } | null
  }

  useEffect(() => {
    setInputMsg(state?.fixedMessage || '')
  }, [state?.fixedMessage])

  const startComposition = () => setComposing(true)
  const endComposition = () => setComposing(false)
  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (composing) {
        return
      }

      if (!e.shiftKey) {
        onCreateMessage(inputMsg, senderId ?? '')
        e.preventDefault()
      }
    }
  }

  const onCreateMessage = (message: string, senderId: string) => {
    if (!contract) return
    if (!message) return
    if (!senderId) return

    dispatch(
      createChatMessage({
        id: contractId,
        messageBody: {
          message,
          senderId,
        },
      })
    )
    setInputMsg('')
  }

  const onCreateFileMessage = (file: string, originalFileName: string) => {
    if (!contract) return
    if (!file) return
    if (!senderId) return
    dispatch(
      createChatFileMessage({
        id: contractId,
        messageBody: {
          file,
          originalFileName,
          senderId: senderId,
        },
      })
    )
    setInputMsg('')
    if (inputRef.current) inputRef.current.value = ''
  }

  const { onChangeFile } = useFileUpload(contractId, onCreateFileMessage)

  return (
    <Container>
      <TextareaWrappre>
        <ChatTextarea
          minRows={1}
          maxRows={9}
          value={inputMsg}
          placeholder="メッセージを入力"
          onKeyDown={onKeyDown}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setInputMsg(e.target.value)
          }}
          onCompositionStart={startComposition}
          onCompositionEnd={endComposition}
        />
        <MessageMenu>
          <SendFileButton onChangeFile={onChangeFile} />
          <SendMessageButton
            disabled={inputMsg === ''}
            onClick={() => onCreateMessage(inputMsg, senderId ?? '')}
            data-testid="send"
          />
        </MessageMenu>
      </TextareaWrappre>
    </Container>
  )
}

export default ChatFooter
