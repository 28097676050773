import firebaseApp from 'commons/firebase-config'
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  FirestoreError,
  getFirestore,
  onSnapshot,
  Query,
} from 'firebase/firestore'
import { ChatMessage, ChatRoom } from 'schema/chat'

export const fireStoreDB = getFirestore(firebaseApp)

export const collectionEnum = {
  chatRoom: 'chats',
  chatRoomMessage: (contractId: string) => `chats/${contractId}/messages`,
}

export const collections = {
  chatRoom: typedCollection<ChatRoom>(collectionEnum.chatRoom),
  chatMessage: (contractId: string) =>
    typedCollection<ChatMessage>(collectionEnum.chatRoomMessage(contractId)),
  chatRoomMessage: (contractId: string) =>
    typedCollection<ChatMessage>(
      collectionEnum.chatRoomMessage(contractId)
    ).withConverter<ChatMessage>({
      fromFirestore: (snapshot, options) =>
        ({ id: snapshot.id, ...snapshot.data(options) } as ChatMessage),
      toFirestore: ({ ...rest }: ChatMessage) => rest,
    }),
}

export const storeDB = {
  chatRoom: typedCollection<ChatRoom>(collectionEnum.chatRoom),
  chatRoomMessages: (contractId: string) =>
    typedCollection<ChatMessage>(collectionEnum.chatRoomMessage(contractId)),
}

export function typedCollection<T extends DocumentData>(
  collectionPath: string
): CollectionReference<T> {
  return collection(fireStoreDB, collectionPath) as CollectionReference<T>
}

export function subscribeQuery<T extends DocumentData>(
  query: Query<T>,
  onChange?: (changes: T[]) => void,
  onError?: (error: FirestoreError) => void,
  { ignorePendingWrites = true }: { ignorePendingWrites?: boolean } = {}
): () => void {
  return onSnapshot(
    query,
    (snapshot) => {
      if (ignorePendingWrites && snapshot.metadata.hasPendingWrites) return
      const changes = snapshot.docs.map((e) =>
        e.data({ serverTimestamps: 'estimate' })
      )
      onChange?.(changes)
    },
    onError
  )
}

// export async function subscribeQueryPromise<T extends DocumentData>(
//   query: Query<T>,
//   options: { ignorePendingWrites?: boolean } = {}
// ): Promise<T[]> {
//   return new Promise((resolve, rejects) => {
//     subscribeQuery(
//       query,
//       changes => {
//         resolve(changes)
//       },
//       rejects,
//       options
//     )
//   })
// }

export function subscribeDocument<T extends DocumentData>(
  documentReference: DocumentReference<T>,
  onChange: (change: T) => void,
  onError?: (error: FirestoreError) => void,
  { ignorePendingWrites = true }: { ignorePendingWrites?: boolean } = {}
): () => void {
  return onSnapshot(
    documentReference,
    (snapshot) => {
      if (ignorePendingWrites && snapshot.metadata.hasPendingWrites) return
      const data = snapshot.data({ serverTimestamps: 'estimate' })
      if (data) onChange(data)
    },
    onError
  )
}

// export function subscribePromisDocument<T extends DocumentData>(
//   documentReference: DocumentReference<T>,
//   options: { ignorePendingWrites?: boolean }
// ): Promise<T> {
//   return new Promise((resolve, rejects) => {
//     subscribeDocument(
//       documentReference,
//       changes => {
//         resolve(changes)
//       },
//       rejects,
//       options
//     )
//   })
// }
