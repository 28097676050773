import Page from 'commons/page'
import Block from 'components/block'
import NoLogoHeader from '../../components/no-logo-header'
import SettingToggle from '../../components/setting-toggle'
import BasePanel from '../../components/panel'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import UserApi from '../../apis/user'
import { NotificationSetting as NotificationSettingRecord } from '../../schema/user'

// 通知設定種別
const NotificationTypes = [
  {
    key: 'notification.new.chat',
    name: 'チャットの新着メッセージ',
  },
]

const Panel = styled(BasePanel)`
  background: transparent;
  border: none;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  margin: 0 auto;
`

const NotificationSetting = () => {
  const [userSettings, setUserSettings] = useState<
    NotificationSettingRecord[] | null
  >()
  const [settings, setsSettings] = useState<
    { key: string; name: string; isEnable: boolean }[]
  >([])

  useEffect(() => {
    ;(async () => {
      const result = (await UserApi.fetchNotificationSettings()).data
      setUserSettings(result)
    })()
  }, [])

  useEffect(() => {
    if (!userSettings) {
      return
    }
    const notificationSettingList = NotificationTypes.map((item) => {
      const userSetting = userSettings.find(
        (matchItem) => matchItem.key === item.key
      )
      if (userSetting) {
        return { ...item, isEnable: userSetting.isEnable }
      } else {
        return { ...item, isEnable: true }
      }
    })

    setsSettings(notificationSettingList)
  }, [userSettings])

  const onChange = async (key: string, value: boolean) => {
    const updatedSettings = settings.map((setting) => {
      if (setting.key === key) {
        return {
          ...setting,
          isEnable: value,
        }
      }
      return setting
    })
    setsSettings(updatedSettings)

    // API送信
    await UserApi.saveNotificationSetting(key, value)
  }

  return (
    <Page title="通知設定" customHeader={<NoLogoHeader title={'通知設定'} />}>
      <Panel>
        <Block>
          {settings.map((item) => (
            <SettingToggle
              key={item.key}
              targetKey={item.key}
              labelName={item.name}
              value={item.isEnable}
              onChange={onChange}
            />
          ))}
        </Block>
      </Panel>
    </Page>
  )
}

export default NotificationSetting
