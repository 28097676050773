import { ChartOptions } from 'chart.js'
import theme from 'styles/theme'

export const BAR_GRAPH_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 30,
      bottom: 0,
      left: 0,
      right: 10,
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: theme.FONT_COLOR,
        font: {
          size: 12,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        color: theme.FONT_COLOR,
        font: {
          size: 12,
        },
        stepSize: 1,
      },
      suggestedMax: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: true,
      padding: 4,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      titleFont: {
        size: 10,
      },
      titleMarginBottom: 0,
      bodyFont: {
        size: 10,
        weight: 'bold',
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      align: 'end',
      labels: {
        padding: 4,
        boxWidth: 6,
        boxHeight: 6,
        color: theme.FONT_COLOR,
        font: {
          size: 10,
          lineHeight: 16,
          family: theme.FONT,
        },
      },
    },
    datalabels: {
      color: 'transparent',
    },
  },
}

export const LINE_GRAPH_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    innerWidth: '80%',
    padding: {
      top: 30,
      bottom: 0,
      left: 0,
      right: 10,
    },
  },
  scales: {
    xAxes: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'end',
      labels: {
        padding: 10,
        boxHeight: 6,
        color: theme.FONT_COLOR,
        font: {
          size: 10,
          lineHeight: 16,
        },
        usePointStyle: true,
        pointStyle: 'rect',
      },
    },
    datalabels: {
      color: 'transparent',
    },
  },
}

export const GRAPH_PLUGINS = (unit: string) => {
  return {
    afterDraw: (chart: any) => {
      const ctx = chart.ctx
      ctx.canvas.parentNode.style.height = '200px'

      ctx.save()
      ctx.font = `${theme.FONT}`
      ctx.fillStyle = theme.FONT_COLOR
      ctx.fontSize = '10px'

      ctx.textAlign = 'left'
      ctx.fillText(unit, 5, 15)

      ctx.restore()
    },
  }
}
