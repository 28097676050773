import styled from 'styled-components'
import getLayer from 'styles/layer'
import GlobalMenu from './global-menu'
import ReturnLink from './return-link'

export const HeaderContainer = styled.div`
  z-index: ${getLayer('header')};
  top: 0px;
  right: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 60px;
  }
`

const Title = styled.h2`
  font-size: 18px;
`

const NoLogoHeader = ({
  title,
  visibleReturn,
  visibleGlobalMenu = true,
}: {
  title: string
  visibleReturn?: boolean
  visibleGlobalMenu?: boolean
}) => {
  return (
    <>
      <HeaderContainer>
        <Title>{title}</Title>
      </HeaderContainer>
      {visibleGlobalMenu && <GlobalMenu />}
      <ReturnLink visible={visibleReturn} />
    </>
  )
}
export default NoLogoHeader
