import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import styled from 'styled-components'
import theme from '../../styles/theme'

const Container = styled.div`
  width: 100%;
  max-width: 750px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    max-width: 100%;
  }
`

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)

// 設定したいものを定義
export interface BarOptions {
  barColor?: string
  suggestedMax?: number
  yAxisLabel?: string
}
const BarGraph = ({
  dataLabelName,
  graphLabels,
  graphData,
  options,
}: {
  dataLabelName: string
  graphLabels: string[]
  graphData: number[]
  options?: BarOptions
}) => {
  const data = {
    labels: graphLabels,
    datasets: [
      {
        label: dataLabelName,
        data: graphData,
        backgroundColor: options?.barColor,
        borderWidth: 0,
      },
    ],
  }
  return (
    <Container>
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 30,
              bottom: 0,
              left: 0,
              right: 10,
            },
          },
          scales: {
            x: {
              ticks: {
                color: theme.FONT_COLOR,
                font: {
                  size: 12,
                },
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                color: theme.FONT_COLOR,
                font: {
                  size: 12,
                },
              },
              suggestedMax: options?.suggestedMax,
            },
          },
          plugins: {
            tooltip: {
              enabled: true,
              padding: 4,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              titleFont: {
                size: 10,
              },
              titleMarginBottom: 0,
              bodyFont: {
                size: 10,
                weight: 'bold',
              },
            },
            legend: {
              display: true,
              position: 'bottom',
              align: 'end',
              labels: {
                padding: 4,
                boxWidth: 6,
                boxHeight: 6,
                color: theme.FONT_COLOR,
                font: {
                  size: 10,
                  lineHeight: 16,
                  family: theme.FONT,
                },
              },
            },
            datalabels: {
              color: 'transparent',
            },
          },
        }}
        plugins={[
          {
            ...ChartDataLabels,
            ...{
              afterDraw: (chart: any) => {
                const ctx = chart.ctx
                ctx.canvas.parentNode.style.height = '200px'

                ctx.save()
                ctx.font = `${theme.FONT}`
                ctx.fillStyle = theme.FONT_COLOR
                ctx.fontSize = '10px'

                ctx.textAlign = 'left'
                ctx.fillText(options?.yAxisLabel ?? '', 5, 15)

                ctx.restore()
              },
            },
          },
        ]}
      />
    </Container>
  )
}

export default BarGraph
