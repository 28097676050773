import { useEffect, useMemo } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Page from 'commons/page'
import Block from 'components/block'
import { Horizontal } from 'components/layout'
import Panel from 'components/panel'
import { SectionTitle } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import { BarGraph as BaseBarGraph } from 'components/graph'

import {
  fetchContractById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
} from 'stores/contract'
import { useBreakpoints } from '../../../styles'
import {
  addDays,
  format,
  isSameDay,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns'
import MonthTally from '../../../features/contracts/month-tally'
import { UserRole } from '../../../schema/user'
import ROUTING_PATH from '../../../constants/route'
import HelpTips from '../../../components/help-tip'
import LatestImportedDatetime from '../../../features/contracts/latest-imported-datetime'

const LinkButton = styled(Link)`
  display: flex;
  gap: 6px;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  background-color: ${(p) => p.theme.MAIN};
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  color: ${(p) => p.theme.WHITE};
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none;
`
const ButtonArea = styled.div`
  margin-top: 15px;
  font-size: 12px;
`

const GraphArea = styled(Horizontal)`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
  }
`
// 日単位の抽出
const extractParDay = (items: { date: Date; quantity: number }[]) => {
  // 30日分のデータを表示対象とする
  const today = startOfDay(new Date())
  const ownDaysAgo = subDays(today, 1)
  // 集計対象の過去最終日
  const endDate = subDays(today, 30)

  const extractedData: number[] = []
  let currentDate = endDate

  while (currentDate <= ownDaysAgo) {
    const itemDate = startOfDay(currentDate)
    const foundItem = items.find((item) => {
      return isSameDay(item.date, itemDate)
    })

    if (foundItem) {
      extractedData.push(foundItem.quantity)
    } else {
      extractedData.push(0)
    }

    currentDate = addDays(currentDate, 1) // 次の日付に移動
  }
  return extractedData
}

// 週単位の抽出
const extractParWeek = (items: { date: Date; quantity: number }[]) => {
  // 30日分のデータを表示対象とする
  const today = startOfDay(new Date())
  const oneDaysAgo = subDays(today, 1)
  const endDate = subDays(today, 30) // 週単位の集計のため表示曜日による+曜日分を集計対象とする

  const extractedByWeek: { date: Date; quantity: number }[] = []

  let currentWeekStart = startOfWeek(oneDaysAgo, { weekStartsOn: 1 }) // 今週の開始日を取得
  while (currentWeekStart >= startOfWeek(endDate, { weekStartsOn: 1 })) {
    const currentWeekEnd = addDays(currentWeekStart, 6) // 今週の終了日を取得

    const weekItem = { date: currentWeekStart, quantity: 0 }

    for (const item of items) {
      const itemDate = startOfDay(item.date)

      if (itemDate >= currentWeekStart && itemDate <= currentWeekEnd) {
        weekItem.quantity += item.quantity
      }
    }

    extractedByWeek.push(weekItem)
    currentWeekStart = subDays(currentWeekStart, 7) // 前の週の開始日に移動
  }

  return extractedByWeek.map((value) => value.quantity).reverse()
}

function parDayLabel() {
  const today = startOfDay(new Date())
  const oneDaysAgo = subDays(today, 1)
  const endDate = subDays(today, 30)

  const dateList: string[] = []
  let currentDate = oneDaysAgo
  while (currentDate >= endDate) {
    const formattedDate = format(currentDate, 'MM/dd')
    dateList.push(formattedDate)
    currentDate = subDays(currentDate, 1) // 前の日付に移動
  }

  return dateList.reverse()
}

function parWeekLabel() {
  const today = new Date()
  const oneDaysAgo = subDays(today, 1)
  const endDate = subDays(today, 30)
  const weekStartDateList: string[] = []

  let currentWeekStart = startOfWeek(oneDaysAgo, { weekStartsOn: 1 }) // 今週の開始日（月曜日）を取得
  while (currentWeekStart >= startOfWeek(endDate, { weekStartsOn: 1 })) {
    const formattedWeekStartDate = format(currentWeekStart, 'MM/dd')
    weekStartDateList.push(formattedWeekStartDate)
    currentWeekStart = subWeeks(currentWeekStart, 1) // 前の週の開始日に移動
  }

  return weekStartDateList.reverse()
}

const getLabels = (isPc: boolean) => {
  if (isPc) {
    return parDayLabel()
  }

  return parWeekLabel()
}

const convertGraphData = (
  items: { date: Date; quantity: number }[],
  isPc: boolean
) => {
  if (!items) {
    return []
  }

  if (isPc) {
    return extractParDay(items)
  }

  return extractParWeek(items)
}

const Index = () => {
  const { claims } = useAuthProvider()
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const dispatch = useAppDispatch()

  const { id } = useParams<{ id: string }>()
  const isOwner = useMemo(() => claims?.role === UserRole.OWNER, [claims?.role])

  const breakpoint = useBreakpoints()

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    dispatch(fetchContractById({ role: claims?.role, id }))
  }, [dispatch, claims?.role, id])

  const HasApiError = useMemo(() => {
    return contractFailed
  }, [contractFailed])

  const isPc = useMemo(() => {
    return breakpoint === 'pc'
  }, [breakpoint])

  const graphLabels = useMemo(() => {
    return getLabels(isPc)
  }, [isPc])

  const viewingRequestGraphData = useMemo<number[]>(
    () =>
      convertGraphData(
        contract?.other?.viewingRequestCountPerDay?.map((item) => ({
          date: new Date(item.date._seconds * 1000),
          quantity: item.count,
        })) || [],
        isPc
      ),
    [contract, isPc]
  )

  const documentRequestGraphData = useMemo<number[]>(
    () =>
      convertGraphData(
        contract?.other?.documentRequestCountPerDay?.map((item) => ({
          date: new Date(item.date._seconds * 1000),
          quantity: item.count,
        })) || [],
        isPc
      ),
    [contract, isPc]
  )

  const purchaseApplicationGraphData = useMemo<number[]>(
    () =>
      convertGraphData(
        contract?.other?.purchaseApplicationCountPerDay?.map((item) => ({
          date: new Date(item.date._seconds * 1000),
          quantity: item.count,
        })) || [],
        isPc
      ),
    [contract, isPc]
  )

  const otherInquiryGraphData = useMemo<number[]>(
    () =>
      convertGraphData(
        contract?.other?.otherInquiryCountPerDay?.map((item) => ({
          date: new Date(item.date._seconds * 1000),
          quantity: item.count,
        })) || [],
        isPc
      ),
    [contract, isPc]
  )

  const BarGraphArea = ({
    graphName,
    graphData,
  }: {
    graphName: string
    graphData: number[]
  }) => {
    return (
      <GraphArea justify="space-between">
        <BaseBarGraph
          graphLabels={graphLabels}
          graphData={graphData}
          options={{ suggestedMax: 5, yAxisLabel: '件数', barColor: '#006E4B' }}
          dataLabelName={graphName}
        />
        <MonthTally
          list={[
            {
              label: `直近30日の${graphName}`,
              value: graphData.reduce((sum, num) => sum + num, 0),
              unit: '件',
            },
          ]}
        />
      </GraphArea>
    )
  }

  const visibleViewingRequestDetailButton = useMemo(() => {
    return (
      !!contract?.other?.viewingRequestDetails?.length &&
      contract?.other?.viewingRequestDetails?.length > 0
    )
  }, [contract])

  const visibleDocumentRequestDetailButton = useMemo(() => {
    return (
      !!contract?.other?.documentRequestDetails?.length &&
      contract?.other?.documentRequestDetails?.length > 0
    )
  }, [contract])

  const visiblePurchaseApplicationDetailButton = useMemo(() => {
    return (
      !!contract?.other?.purchaseApplicationDetails?.length &&
      contract?.other?.purchaseApplicationDetails?.length > 0
    )
  }, [contract])

  const visibleOtherInquiryDetailsDetailButton = useMemo(() => {
    return (
      !!contract?.other?.otherInquiryDetails?.length &&
      contract?.other?.otherInquiryDetails?.length > 0
    )
  }, [contract])

  const toViewingRequest = generatePath(
    claims?.role === UserRole.OWNER
      ? ROUTING_PATH.OwnerContractOtherViewingRequest
      : ROUTING_PATH.AgentContractOtherViewingRequest,
    { id: id }
  )

  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title={
            isOwner ? '業者からの問い合わせ' : '2秒でブッカクからの問い合わせ'
          }
          loading={contractLoading}
          type="user"
        >
          <LatestImportedDatetime
            props={{ importedDate: contract?.other?.latestImportedDate }}
          />
          <Block>
            <SectionTitle>
              {isOwner
                ? '業者からの問い合わせ'
                : '2秒でブッカクからの問い合わせ'}
            </SectionTitle>
          </Block>
          <Block>
            <SectionTitle>
              内見依頼数
              <HelpTips
                text={
                  <>
                    週1件程度の内見依頼が適正な価格と言われています。
                    <br />
                    内見依頼がない場合は、掲載内容や価格の見直しをエージェントとご相談ください。
                  </>
                }
              />
            </SectionTitle>
            <Panel>
              <BarGraphArea
                graphName="内見依頼数"
                graphData={viewingRequestGraphData}
              />
            </Panel>
            <ButtonArea>
              {visibleViewingRequestDetailButton && (
                <LinkButton to={toViewingRequest}>
                  内見依頼の詳細を確認する
                </LinkButton>
              )}
            </ButtonArea>
          </Block>
          <Block>
            <SectionTitle>物件資料請求数</SectionTitle>
            <Panel>
              <BarGraphArea
                graphName="物件資料請求数"
                graphData={documentRequestGraphData}
              />
            </Panel>
            <ButtonArea>
              {!isOwner && visibleDocumentRequestDetailButton && (
                <LinkButton
                  to={generatePath(
                    ROUTING_PATH.AgentContractOtherDocumentRequest,
                    { id }
                  )}
                >
                  物件資料請求の詳細を確認する
                </LinkButton>
              )}
            </ButtonArea>
          </Block>
          {!isOwner && (
            <Block>
              <SectionTitle>購入申し込み数</SectionTitle>
              <Panel>
                <BarGraphArea
                  graphName="購入申し込み数"
                  graphData={purchaseApplicationGraphData}
                />
              </Panel>
              <ButtonArea>
                {visiblePurchaseApplicationDetailButton && (
                  <LinkButton
                    to={generatePath(
                      ROUTING_PATH.AgentContractOtherPurchaseApplication,
                      { id }
                    )}
                  >
                    購入申し込みの詳細を確認する
                  </LinkButton>
                )}
              </ButtonArea>
            </Block>
          )}
          {!isOwner && (
            <Block>
              <SectionTitle>その他問い合わせ数</SectionTitle>
              <Panel>
                <BarGraphArea
                  graphName="その他問い合わせ数"
                  graphData={otherInquiryGraphData}
                />
              </Panel>
              <ButtonArea>
                {visibleOtherInquiryDetailsDetailButton && (
                  <LinkButton
                    to={generatePath(ROUTING_PATH.AgentContractOtherInquiry, {
                      id,
                    })}
                  >
                    その他問い合わせの詳細を確認する
                  </LinkButton>
                )}
              </ButtonArea>
            </Block>
          )}
        </Page>
      )}
    </>
  )
}

export default Index
