import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Page from 'commons/page'
import Block from 'components/block'
import { PageTitle as BasePageTitle, SectionTitle } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import {
  fetchContractById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
  selectFeatureByIdFailed,
} from 'stores/contract'
import { ListWrapper as BaseListWrapper } from '../../../components/layout'
import styled from 'styled-components'
import { UserRole } from '../../../schema/user'
import SubPageHeader from '../../../components/sub-page-header'
import { compareDesc } from 'date-fns'
import { OtherListInquiryItem } from '../../../features/contracts/list/other-list-inquery-item'

const PageTitle = styled(BasePageTitle)`
  text-align: left;
`
const ListWrapper = styled(BaseListWrapper)`
  margin-bottom: 30px;
`

const ViewingRequest = () => {
  const { claims } = useAuthProvider()
  const isOwner = useMemo(() => claims?.role === UserRole.OWNER, [claims?.role])
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const featureFailed = useAppSelector(selectFeatureByIdFailed)
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    dispatch(fetchContractById({ role: claims?.role, id }))
  }, [dispatch, claims?.role, id])

  const data = useMemo(() => {
    return contract
  }, [contract])

  const HasApiError = useMemo(() => {
    return contractFailed || featureFailed
  }, [contractFailed, featureFailed])

  const title = isOwner
    ? '業者からの問い合わせ'
    : '2秒でブッカクからの問い合わせ'

  const { details } = useMemo(() => {
    if (!data) {
      return {
        details: [],
      }
    }

    return {
      details:
        data.other?.viewingRequestDetails
          .slice()
          .sort((a, b) => compareDesc(a.date, b.date)) ?? [],
    }
  }, [data])

  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title={title}
          loading={contractLoading}
          type="user"
          customHeader={<SubPageHeader />}
        >
          <PageTitle>{title}</PageTitle>
          {details.length !== 0 && (
            <Block>
              <SectionTitle>内見依頼詳細</SectionTitle>
              <ListWrapper>
                {details.map((item, index) => (
                  <OtherListInquiryItem
                    key={`other-list-item-${index}`}
                    item={item}
                    isVisibleInquiryLabel={false}
                  />
                ))}
              </ListWrapper>
            </Block>
          )}
        </Page>
      )}
    </>
  )
}

export default ViewingRequest
