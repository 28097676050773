import { format, getDay, getUnixTime, subDays } from 'date-fns'
import { Timestamp } from 'firebase/firestore'

import { PoratalSiteInfo } from 'schema/contract'
import { formatedDate } from 'utils'

const Days = function (bp: string) {
  return bp === 'pc' ? 30 : 30 + getDay(new Date())
}

const yesterday = subDays(new Date(), 1)

export function createGraphLabel(breakpoint: string) {
  const list: Array<string> = []
  for (let i = 0; i < Days(breakpoint); i++) {
    const date = subDays(yesterday, i)
    const day = getDay(date)
    if (breakpoint === 'pc') {
      list.push(format(date, 'MM/dd'))
    } else {
      if (day === 1) {
        list.push(format(date, 'MM/dd'))
      }
    }
  }

  return list.reverse()
}

export function createBarGraphData(arr: PoratalSiteInfo[], breakpoint: string) {
  const list: Array<number> = []
  const dataArr = arr.map((data) => {
    return {
      quantity: data.quantity,
      date: formatedDate(data.date),
    }
  })
  let count = 0
  for (let i = 0; i < Days(breakpoint); i++) {
    const date = subDays(yesterday, i)
    const day = getDay(date)
    const data = dataArr?.find((obj) => {
      return obj.date === format(date, 'yyyy/MM/dd')
    })
    if (breakpoint === 'pc') {
      list.push(data?.quantity || 0)
    } else {
      if (day === 1) {
        list.push((count = count + (data?.quantity || 0)))
        count = 0
      } else {
        count += data?.quantity || 0
      }
    }
  }

  return list.reverse()
}

export function createTransitionRateData(
  listPv: PoratalSiteInfo[],
  detailPv: PoratalSiteInfo[],
  breakpoint: string
) {
  const list: Array<number> = []
  const listPvArr = listPv.map((data) => {
    return {
      quantity: data.quantity,
      date: formatedDate(data.date),
    }
  })
  const detailPvArr = detailPv.map((data) => {
    return {
      quantity: data.quantity,
      date: formatedDate(data.date),
    }
  })
  let listSum = 0
  let detailSum = 0
  for (let i = 0; i < Days(breakpoint); i++) {
    const date = subDays(yesterday, i)
    const day = getDay(date)
    const listPvData = listPvArr?.find((obj) => {
      return obj.date === format(date, 'yyyy/MM/dd')
    })
    const detailPvData = detailPvArr?.find((obj) => {
      return obj.date === format(date, 'yyyy/MM/dd')
    })
    if (breakpoint === 'pc') {
      if (!listPvData) {
        list.push(0)
        return []
      }
      list.push(((detailPvData?.quantity || 0) / listPvData.quantity) * 100)
    } else {
      if (day === 1) {
        listSum += listPvData?.quantity || 0
        detailSum += detailPvData?.quantity || 0
        list.push((detailSum / listSum) * 100)
        listSum = 0
        detailSum = 0
      } else {
        listSum += listPvData?.quantity || 0
        detailSum += detailPvData?.quantity || 0
      }
    }
  }

  return list.reverse()
}

export function total30Days(list: PoratalSiteInfo[]) {
  const monthAgo = getUnixTime(subDays(subDays(new Date(), 1), 30))
  return (
    list
      .filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0) || 0
  )
}

export function average30Days(list: PoratalSiteInfo[]) {
  const monthAgo = getUnixTime(subDays(subDays(new Date(), 1), 30))
  return (
    list
      .filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity) / 30
      }, 0) || 0
  )
}
