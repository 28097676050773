import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 21px 10px 22px;
  border: none;
  border-radius: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  line-height: 21px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    outline: none;
  }
`

export default Textarea
