import styled from 'styled-components'

import { ChangeEvent } from 'react'
import Toggle from './toggle'

const NameLabel = styled.label`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    font-size: 12px;
  }
  width: 80%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  box-sizing: border-box;
  align-items: center;
  vertical-align: center;
  color: ${(p) => p.theme.FONT_COLOR};
  width: 100%;
`
const Box = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`

const SettingToggle = (props: {
  labelName: string
  targetKey: string
  value: boolean
  onChange: (key: string, value: boolean) => void
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(props.targetKey, e.target.checked)
  }

  return (
    <Wrapper>
      <Box width={'80%'}>
        <NameLabel>{props.labelName}</NameLabel>
      </Box>
      <Box width={'20%'}>
        <Toggle defaultChecked={props.value} onChange={onChange} />
      </Box>
    </Wrapper>
  )
}

export default SettingToggle
