import { useEffect, useState } from 'react'
import theme from './theme'

const useWindowSize = () => {
  const [size, setSize] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  return size
}

export const useBreakpoints = () => {
  const size = useWindowSize()
  const { vp } = theme
  if (size <= vp) {
    return 'sp'
  }

  return 'pc'
}
