import { useEffect, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'

import Page from 'commons/page'
import { MoreLink } from 'components/link'
import Block from 'components/block'
import { ListWrapper } from 'components/layout'
import { SectionTitle } from 'components/typography'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  Contract,
  ContractLink,
  Feature,
  FeatureLink,
  StatusType,
} from 'schema/contract'
import { UserRole } from 'schema/user'
import {
  fetchContract,
  fetchFeature,
  selectContractList,
  selectContractListLoading,
  selectFeatureList,
  selectFeatureListLoading,
} from 'stores/contract'
import { useBreakpoints } from 'styles'
import NoData from 'components/no-data'
import {
  ContractListItem,
  FeatureListItem,
} from '../features/contracts/list/list-item'
import {
  CONTRACT_STATUS_TYPE,
  FEATURE_STATUS_TYPE,
} from '../constants/contract'
import {
  ContractedStatusTub,
  FetaureStatusTub,
} from '../features/contracts/status-tab'
import { clearErrors } from 'stores/error'

const PC_LIST_MAX_LENGTH = 6
const SP_LIST_MAX_LENGTH = 5

const Home = () => {
  const { claims } = useAuthProvider()
  const contracts = useAppSelector(selectContractList)
  const features = useAppSelector(selectFeatureList)
  const contractLoading = useAppSelector(selectContractListLoading)
  const featureLoading = useAppSelector(selectFeatureListLoading)
  const dispatch = useAppDispatch()
  const breakpoint = useBreakpoints()
  const [contractedStatus, setContractedStatus] =
    useState<StatusType>('inProgress')
  const [featureStatus, setFeatureStatus] = useState<StatusType>('inProgress')

  useEffect(() => {
    dispatch(clearErrors())
    if (claims?.role != null) {
      dispatch(fetchContract({ role: claims.role }))
      if (claims.role === UserRole.AGENT) {
        dispatch(fetchFeature())
      }
    }
  }, [claims?.role, dispatch])

  const destinationToDetail = useMemo(() => {
    return claims?.role === UserRole.OWNER
      ? ROUTING_PATH.B0302
      : ROUTING_PATH.C0302
  }, [claims?.role])

  const destinationToChat = useMemo(() => {
    return claims?.role === UserRole.OWNER
      ? ROUTING_PATH.B0303
      : ROUTING_PATH.C0303
  }, [claims?.role])

  const contractList = useMemo<Array<ContractLink>>(() => {
    const typeList = CONTRACT_STATUS_TYPE[contractedStatus]
    if (!typeList) return []

    return contracts
      ?.filter((contract: Contract) => {
        return typeList?.indexOf(contract.status) > -1
      })
      .map((contract: Contract) => {
        const toDetail = generatePath(destinationToDetail, { id: contract.id })
        const toChat = generatePath(destinationToChat, { id: contract.id })
        return {
          ...contract,
          toDetail,
          toChat,
        }
      })
  }, [contractedStatus, contracts, destinationToChat, destinationToDetail])

  const featureList = useMemo<Array<FeatureLink>>(() => {
    const typeList = FEATURE_STATUS_TYPE[featureStatus]
    if (!typeList) return []

    return features
      ?.filter((feature: Feature) => {
        return typeList?.indexOf(feature.status) > -1
      })
      .filter((feature: Feature) => {
        return !feature.isHidden
      })
      .map((feature: Feature) => {
        const toDetail = generatePath(destinationToDetail, { id: feature.id })
        return {
          ...feature,
          toDetail,
        }
      })
  }, [featureStatus, features, destinationToDetail])

  const LIST_MAX_LENGTH = useMemo(() => {
    return breakpoint === 'pc' ? PC_LIST_MAX_LENGTH : SP_LIST_MAX_LENGTH
  }, [breakpoint])

  const ContractList = () => {
    if (contractLoading) {
      return <></>
    }

    if (contractList?.length === 0) {
      return <NoData />
    }

    return (
      <>
        {contractList?.slice(0, LIST_MAX_LENGTH).map((contract) => (
          <ContractListItem
            key={`contract-${contract.id}`}
            type="inProgress"
            data={contract}
          />
        ))}
      </>
    )
  }

  const FutureList = () => {
    if (featureLoading) {
      return <></>
    }

    if (featureList?.length === 0) {
      return <NoData />
    }
    return (
      <>
        {featureList?.slice(0, LIST_MAX_LENGTH).map((feature) => {
          return (
            <FeatureListItem
              key={`feature-${feature.id}`}
              type="feature"
              data={feature}
              image={`/image/${feature.type}.png`}
              imageAlt={feature.type}
            />
          )
        })}
      </>
    )
  }

  return (
    <Page
      title="物件一覧"
      loading={contractLoading || featureLoading}
      type="user"
    >
      <Block>
        <SectionTitle>媒介契約</SectionTitle>
        <ContractedStatusTub
          status={contractedStatus}
          setStatus={setContractedStatus}
        />
        <ListWrapper>
          <ContractList />
        </ListWrapper>
        {contractList.length > LIST_MAX_LENGTH && (
          <MoreLink
            to={`${
              claims?.role === UserRole.OWNER
                ? ROUTING_PATH.B0301
                : ROUTING_PATH.C0301
            }?type=contract&status=${contractedStatus}`}
          />
        )}
      </Block>
      {claims?.role === UserRole.AGENT && (
        <Block>
          <SectionTitle>先物掲載</SectionTitle>
          <FetaureStatusTub
            status={featureStatus}
            setStatus={setFeatureStatus}
          />
          <ListWrapper>
            <FutureList />
          </ListWrapper>
          {featureList.length > LIST_MAX_LENGTH && (
            <MoreLink
              to={`${ROUTING_PATH.C0301}?type=feature&status=${featureStatus}`}
            />
          )}
        </Block>
      )}
    </Page>
  )
}

export default Home
