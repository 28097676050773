import { Fragment } from 'react'
import styled from 'styled-components'

import { StrongText, Text } from 'components/typography'
import { Horizontal } from 'components/layout'

interface MonthTallyProps {
  label: string
  value: number
  unit: string
  detail?: {
    title?: string
    list: {
      label: string
      value: number
      unit: string
    }[]
  }
  helpTip?: JSX.Element
}

const Unit = styled(Text)`
  display: inline-block;
  margin-left: 4px;
`

const Container = styled.div`
  margin-left: 10px;
  width: 350px;

  > div + div {
    margin-top: 4px;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 100%;
    margin-top: 10px;
    margin-left: auto;

    > div + div {
      margin-top: 10px;
    }
  }
`

const Detail = styled.div`
  > div + div {
    margin-top: 4px;
    padding-left: 10px;
  }
`
const Tips = styled.div`
  margin-top: 3px;
`
const MonthTally = ({ list }: { list: MonthTallyProps[] }) => (
  <Container>
    {list.map((data, i) => (
      <Fragment key={`month-tally-${i}`}>
        <Horizontal justify="space-between" align="baseline">
          <Horizontal align="center">
            <StrongText size="sm">{data.label}</StrongText>
            <Tips>{data.helpTip}</Tips>
          </Horizontal>
          <Horizontal align="center" gap={1}>
            <StrongText>{data.value?.toLocaleString()}</StrongText>
            <Unit size="md">{data.unit}</Unit>
          </Horizontal>
        </Horizontal>
        {data.detail && (
          <Detail>
            <div>
              {data.detail && (
                <StrongText size="sm">{data.detail?.title}</StrongText>
              )}
            </div>
            {data.detail?.list.map((detail, j) => (
              <Horizontal
                key={`month-tally-${i}-detail-${j}`}
                justify="space-between"
                align="baseline"
              >
                <Text size="sm">{detail.label}</Text>
                <Horizontal align="baseline">
                  <StrongText size="sm">
                    {detail.value?.toLocaleString()}
                  </StrongText>
                  <Unit size="md">{detail.unit}</Unit>
                </Horizontal>
              </Horizontal>
            ))}
          </Detail>
        )}
      </Fragment>
    ))}
  </Container>
)

export default MonthTally
