import styled from 'styled-components'
import { GoogleLoginButton } from 'react-social-login-buttons'

const SocialLoginButtonLabel = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`
export const GoogleLogo = ({
  labelText,
  onClick,
}: {
  labelText: string
  onClick?: () => any
}) => {
  return (
    <GoogleLoginButton
      style={{ borderRadius: '6px', cursor: onClick ? 'pointer' : 'default' }}
      disabled={!onClick}
      onClick={onClick}
    >
      <SocialLoginButtonLabel>{labelText}</SocialLoginButtonLabel>
    </GoogleLoginButton>
  )
}
