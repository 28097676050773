import styled from 'styled-components'

const SubSectionTitle = styled.h4`
  margin: 0 0 10px;
  color: ${(p) => p.theme.FONT_COLOR};
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    font-size: 10px;
    line-height: 15px;
  }
`

export default SubSectionTitle
