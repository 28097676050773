import { format, isToday, isYesterday } from 'date-fns'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Text } from 'components/typography'
import { ChatMessage } from 'schema/chat'

const Container = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: 2px 10px;
  border: 1px solid ${(p) => p.theme.BORDER};
  border-radius: 12px;
  box-sizing: border-box;
`

const DatePanel = ({ message }: { message: ChatMessage }) => {
  const viewDate = useMemo(() => {
    const date = new Date(message.createdAt * 1000)
    if (isToday(date)) {
      return '今日'
    }
    if (isYesterday(date)) {
      return '昨日'
    }

    return format(date, 'yyyy/MM/dd')
  }, [message.createdAt])

  return (
    <Container>
      <Text size="sm">{viewDate}</Text>
    </Container>
  )
}

export default DatePanel
