import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 750px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    max-width: 100%;
  }
`

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
ChartJS.register(ChartDataLabels)

const LineGraph = ({
  data,
  options,
  plugins,
}: {
  data: any
  options: any
  plugins: any
}) => {
  return (
    <Container>
      <Line
        data={data}
        options={options}
        plugins={[
          {
            ...ChartDataLabels,
            ...plugins,
          },
        ]}
      />
    </Container>
  )
}

export default LineGraph
