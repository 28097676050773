import { ParsedToken } from 'firebase/auth'
import log from 'loglevel'
import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react'

import { getAuthMeta, initFirebaseAuth } from 'commons/firebase-auth'
import { JWT_TOKEN_KEY_NAME } from 'constants/common'
import { FirebaseUser, UserRole } from 'schema/user'

interface CustomeParsedToken extends ParsedToken {
  role?: UserRole.OWNER | UserRole.AGENT
  availableWeb?: string | object
}

interface IAuthContext {
  currentUser: FirebaseUser
  signInCheck: boolean
  claims?: CustomeParsedToken
}

const AuthContext = createContext<IAuthContext>({
  currentUser: undefined,
  signInCheck: false,
  claims: undefined,
})

async function setIdToken(user: FirebaseUser) {
  const idToken = await user?.getIdToken()
  if (!idToken) {
    return
  }

  localStorage.setItem(JWT_TOKEN_KEY_NAME, idToken)
}

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser>(null)
  const [claims, setClaims] = useState<CustomeParsedToken | undefined>()
  const [signInCheck, setSignInCheck] = useState(false)

  useEffect(() => {
    const unsubscribed = initFirebaseAuth((user: FirebaseUser) => {
      log.debug(user)

      if (user) {
        setIdToken(user)
        setCurrentUser(user)
        getAuthMeta(user).then((result) => {
          log.debug(result)
          setClaims(result?.claims)
          setSignInCheck(true)
        })
      } else {
        setCurrentUser(null)
        localStorage.removeItem(JWT_TOKEN_KEY_NAME)
        setSignInCheck(true)
      }

      return () => {
        unsubscribed()
      }
    })
  }, [])

  const AuthProviderValue = useMemo(
    () => ({ currentUser, signInCheck, claims }),
    [currentUser, signInCheck, claims]
  )

  return (
    <AuthContext.Provider value={AuthProviderValue}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuthProvider = () => useContext(AuthContext)

export { AuthProvider, useAuthProvider }
