import React from 'react'
import styled, { css } from 'styled-components'

import Header from 'components/header'
import GlobalMenu from 'components/global-menu'
import { useAuthProvider } from 'contexts/auth'
import { ViewMode } from 'schema/common'
import theme from 'styles/theme'

const LayoutContainer = styled.div`
  background-color: ${theme.BACKGROUND_SURFACE};
`

interface LayoutProps {
  children?: React.ReactNode
  type: ViewMode
  infiniteScroll: boolean
  customHeader?: React.ReactNode
}

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 80px;
  background-color: ${(p) => p.theme.BACKGROUND_BASE};

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }
`

const GuestLayout = (props: { type: ViewMode }) => {
  const { type } = props
  if (type === 'guest') {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 660px;
      min-height: calc(100vh - 80px);
      margin: 0 auto;

      @media only screen and (max-width: ${(p) => p.theme.vp}px) {
        width: max-content;
        min-height: calc(100vh - 60px);
      }
    `
  }

  return css``
}

const Contents = styled.div<{ type: ViewMode; infiniteScroll: boolean }>`
  width: 100%;
  max-width: ${(p) => p.theme.vp}px;
  margin: 0 auto;
  padding: ${(p) => (p.infiniteScroll ? '0 30px' : '50px 30px')};
  box-sizing: border-box;
  ${GuestLayout};

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    padding: ${(p) => (p.infiniteScroll ? '0 30px' : '30px')};
    width: 100%;
  }
`

const Layout = ({
  type = 'user',
  children,
  infiniteScroll,
  customHeader,
}: LayoutProps) => {
  const { currentUser } = useAuthProvider()
  return (
    <LayoutContainer>
      {currentUser && !customHeader && <GlobalMenu />}
      {customHeader || <Header />}
      <Container>
        <Contents type={type} infiniteScroll={infiniteScroll}>
          {children}
        </Contents>
      </Container>
    </LayoutContainer>
  )
}

export default Layout
