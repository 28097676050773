import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import log from 'loglevel'

import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  fetchSignInMethodsForEmail,
} from 'firebase/auth'
import ROUTING_PATH from 'constants/route'
import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { ButtonWrapper } from 'components/layout'
import { PageTitle, Text } from 'components/typography'
import { CONTACT_EMAIL } from 'constants/common'

interface PageProp {
  redirectUrl: string | null
}

const SignInWithEmailLink = ({ redirectUrl }: PageProp) => {
  const [userExists, setUserExists] = useState<boolean>(true)

  const auth = getAuth()
  const navigate = useNavigate()

  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem('emailForSignIn')

    if (!email) {
      email = window.prompt('メールアドレスを入力')
    }

    // ユーザが存在しない状態で signInWithEmailLink を呼ぶと
    // ユーザを作成してログインという振る舞いになる
    // option 等で制御できないので
    // 先に fetchSignInMethodsForEmail を呼ぶことでユーザが存在しているか確認する
    // ただし、この方法はなんらかの手段で認証基盤にユーザが追加されている状態だと
    // ログインだけは可能になるので別の手段があれば変更する
    fetchSignInMethodsForEmail(auth, email as string)
      .then((signInMethods) => {
        if (signInMethods.length > 0) {
          signInWithEmailLink(auth, email as string, window.location.href)
            .then(() => {
              window.localStorage.removeItem('emailForSignIn')
              navigate(ROUTING_PATH.B0201)
            })
            .catch((error) => {
              log.error(error)
              navigate(ROUTING_PATH.B0101)
            })
        } else {
          setUserExists(false)
        }
      })
      .catch((error) => {
        log.error(error)
        navigate(ROUTING_PATH.B0101)
      })
  }

  return userExists ? (
    <div></div>
  ) : (
    <Page title="USER NOT FOUND" type="guest">
      <PageTitle>USER NOT FOUND</PageTitle>
      <Block>
        <Text>ユーザ情報が見つかりませんでした。</Text>
      </Block>
      <Block>
        <Text>問い合わせは、{CONTACT_EMAIL}までお願いいたします。</Text>
      </Block>
      <ButtonWrapper>
        <Button
          color="secondary"
          onClick={() => {
            window.location.href = redirectUrl || ROUTING_PATH.B0101
          }}
        >
          ログインページへ戻る
        </Button>
      </ButtonWrapper>
    </Page>
  )
}

export default SignInWithEmailLink
