import request from 'apis/request'
import { UserRole } from 'schema/user'
import { Contract, Feature } from 'schema/contract'

const fetchContract = (userRole: UserRole) =>
  request<Array<Contract>>({
    method: 'get',
    url: `/${userRole}s/contracts`,
    data: {},
  })

const fetchContractById = (userRole: UserRole, id: string) =>
  request<Contract>({
    method: 'get',
    url: `/${userRole}s/contracts/${id}`,
    data: {},
  })

const confirmContractNotification = (userRole: UserRole, id: string) =>
  request<Contract>({
    method: 'post',
    url: `/${userRole}s/contracts/${id}/confirm-notification`,
    data: {},
  })

const confirmFeatureNotification = (userRole: UserRole, id: string) =>
  request<Contract>({
    method: 'post',
    url: `/${userRole}s/features/${id}/confirm-notification`,
    data: {},
  })

const fetchFeature = () =>
  request<Array<Feature>>({
    method: 'get',
    url: `/agents/features`,
    data: {},
  })

const fetchFeatureById = (id: string) =>
  request<Feature>({
    method: 'get',
    url: `/agents/features/${id}`,
    data: {},
  })

const updateSameComparableSalesVisibility = (
  contractId: string,
  index: number,
  isVisible: boolean
) =>
  request({
    method: 'post',
    url: `/agents/contracts/${contractId}/update-comparable-sales-visibility`,
    data: {
      type: 'same',
      index,
      isVisible,
    },
  })

const updateSimilarComparableSalesVisibility = (
  contractId: string,
  index: number,
  isVisible: boolean
) =>
  request({
    method: 'post',
    url: `/agents/contracts/${contractId}/update-comparable-sales-visibility`,
    data: {
      type: 'similar',
      index,
      isVisible,
    },
  })

const updateInquiryVisibility = (
  contractId: string,
  inquiryId: string,
  isVisible: boolean
) =>
  request({
    method: 'post',
    url: `/agents/contracts/${contractId}/update-inquiry-visibility`,
    data: {
      inquiryId,
      isVisible,
    },
  })

const updateReporting = (contractId: string, isReportable: boolean) =>
  request({
    method: 'post',
    url: `/agents/contracts/${contractId}/update-reporting`,
    data: {
      isReport: isReportable,
    },
  })

const ContractApi = {
  fetchContract,
  fetchContractById,
  fetchFeature,
  fetchFeatureById,
  updateSameComparableSalesVisibility,
  updateSimilarComparableSalesVisibility,
  updateInquiryVisibility,
  updateReporting,
  confirmContractNotification,
  confirmFeatureNotification,
}

export default ContractApi
