import { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router'

import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useMessagesChange } from './useFirestoreChange'
import { getUnreadMsgCount } from '../logic/chat'
import {
  fetchChatRoom,
  selectChatRoom,
  selectChatRoomCreating,
  selectChatRoomFetching,
  updateChatReadStatus,
} from 'stores/chat'
import { ChatMessage } from 'schema/chat'
import { Contract } from 'schema/contract'

const useFetchChatRoom = (
  contract: Contract | undefined,
  enableUpdateLastMessage: boolean
) => {
  const fetchRef = useRef(false)
  const { currentUser } = useAuthProvider()
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const contractId = contract?.id ?? ''
  const chatRoom = useAppSelector(selectChatRoom(contractId))
  const chatRoomLoading = useAppSelector(selectChatRoomFetching)
  const chatRoomCreating = useAppSelector(selectChatRoomCreating)
  const loading = useMemo(() => {
    return chatRoomCreating || chatRoomLoading
  }, [chatRoomCreating, chatRoomLoading])

  const updateLastMessage = (lastMessage?: ChatMessage) => {
    if (!enableUpdateLastMessage) {
      return
    }
    if (!lastMessage) return
    if (contractId && currentUser?.uid) {
      const messageReadState = Object.assign(
        {},
        chatRoom?.messageReadState ?? {}
      )
      messageReadState[currentUser?.uid] = lastMessage.id
      dispatch(
        updateChatReadStatus({
          id: contractId,
          userId: currentUser?.uid,
          lastMessage,
        })
      )
    }
  }

  useMessagesChange(contractId, updateLastMessage)

  useEffect(() => {
    if (!loading && contract && !fetchRef.current) {
      fetchRef.current = true
      dispatch(
        fetchChatRoom({
          id: contract.id,
          userId: currentUser?.uid ?? '',
          users: {
            ownerId: contract.ownerId,
            agentId: contract.agentId,
          },
          updateLastMesage: enableUpdateLastMessage,
        })
      )
    }
  }, [dispatch, loading, contract, currentUser?.uid, enableUpdateLastMessage])

  const messages =
    contractId === id && chatRoom && chatRoom?.messages
      ? Object.values(chatRoom?.messages)
      : []

  return {
    chatRoom,
    messages,
    loading,
    unreadCnt: getUnreadMsgCount(chatRoom, currentUser?.uid),
  }
}

export default useFetchChatRoom
