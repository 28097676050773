import Button from 'components/button'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 30px;

  ${Button} + ${Button} {
    margin-top: 10px;
  }
`

export default ButtonWrapper
