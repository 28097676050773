import { HTMLInputTypeAttribute } from 'react'
import { css, StyledProps } from 'styled-components'

export interface InputProps {
  error?: boolean
  type?: HTMLInputTypeAttribute
}

const cssOfError = (props: StyledProps<InputProps>) => {
  if (props.error) {
    return css`
      border: 1px solid ${props.theme.ERROR_BG_COLOR};
      border-radius: 6px;
      background-color: ${props.theme.ERROR_BG_COLOR};
      color: ${props.theme.ERROR_FONT_COLOR};

      &::placeholder {
        color: ${props.theme.ERROR_FONT_COLOR};
      }

      &:hover,
      &:focus {
        outline: none !important;
      }
    `
  }

  return css`
    border: none;
    border-radius: 6px;
    background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
    color: ${(p) => p.theme.FONT_COLOR};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

    &::placeholder {
      color: rgba(51, 65, 87, 0.3);
      font-size: 14px;
    }

    &:hover,
    &:focus {
      outline: none !important;
    }
  `
}

const baseInputStyle = css`
  width: min(100%, 600px);
  height: 44px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  appearance: none;

  ${cssOfError}

  &:disabled {
    background-color: ${(p) => p.theme.DISABLED};
    pointer-events: none;
  }
`

export default baseInputStyle
