import { AuthErrorCodes } from 'firebase/auth'
import log from 'loglevel'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  resetPassword,
  verificationPasswordResetVerifyCode,
} from 'commons/firebase-auth'
import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { Form, FormControl } from 'components/form'
import { Input } from 'components/input-control'
import { ButtonWrapper } from 'components/layout'
import { Text } from 'components/typography'
import { ERRORS } from 'constants/common'
import ROUTING_PATH from 'constants/route'
import { ErrorMsg } from 'constants/message'
import { useAppDispatch } from 'hooks'
import { clearErrors, setErrors } from 'stores/error'
import NoLogoHeader from '../../components/no-logo-header'

interface PageProp {
  actionCode: string
  redirectUrl: string | null
}

interface ResetPasswordProp {
  password: string
  rePassword: string
}

const ResetPassword = ({ actionCode, redirectUrl }: PageProp) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<'edit' | 'done'>('edit')
  const [isError, setIsError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearErrors())
    verificationPasswordResetVerifyCode(actionCode).catch((e) => {
      setIsError(true)
      log.error(e)
      if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
        dispatch(setErrors({ action: { ...ERRORS['E0204'] } }))
        return
      }
      if (e.code === AuthErrorCodes.EXPIRED_OOB_CODE) {
        dispatch(setErrors({ action: { ...ERRORS['E0205'] } }))
        return
      }

      // その他エラーの場合はもURLの有効期限が切れたと出す
      dispatch(setErrors({ action: { ...ERRORS['E0205'] } }))
    })
  }, [actionCode, dispatch])

  const schema = yup.object({
    password: yup.string().required(ErrorMsg.REQUIRED),
    rePassword: yup
      .string()
      .when('newPassword', (pw, field) =>
        pw
          ? field
              .required(ErrorMsg.REQUIRED)
              .oneOf([yup.ref('newPassword')], ErrorMsg.DIFFERENT_PASSWORD)
          : field
      ),
  })

  const form = useForm<ResetPasswordProp>({
    resolver: yupResolver(schema),
  })

  const handleSubmit = useCallback(
    async (data: ResetPasswordProp) => {
      try {
        setLoading(true)
        await resetPassword(actionCode, data.password)
        setStatus('done')
      } catch (e) {
        log.error(e)
      }

      setLoading(false)
    },
    [actionCode]
  )

  return (
    <Page
      title="パスワード再設定"
      loading={loading}
      type="guest"
      customHeader={
        <NoLogoHeader
          title={'パスワード再設定'}
          visibleGlobalMenu={false}
          visibleReturn={false}
        />
      }
    >
      {status === 'edit' && !isError && (
        <>
          <Block>
            <Text>新しいパスワードを入力してください。</Text>
          </Block>
          <Block>
            <Form form={form} onSubmit={handleSubmit}>
              <FormControl label="新しいパスワード" name="password">
                <Input
                  type="password"
                  placeholder="●●●●●●●●●"
                  aria-label="新しいパスワード"
                />
              </FormControl>
              <FormControl label="新しいパスワード（確認）" name="rePassword">
                <Input
                  type="password"
                  placeholder="●●●●●●●●●"
                  aria-label="新しいパスワード（確認）"
                />
              </FormControl>
              <Block>
                <Button>設定</Button>
              </Block>
            </Form>
          </Block>
        </>
      )}
      {status === 'done' && (
        <>
          <Block>
            <Text>パスワード再設定が完了しました。</Text>
          </Block>
          <ButtonWrapper>
            <Button
              onClick={() => {
                window.location.href = redirectUrl || ROUTING_PATH.B0101
              }}
            >
              ログイン画面へ
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Page>
  )
}

export default ResetPassword
