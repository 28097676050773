import NotificationArea from '../../components/notification-area'
import styled from 'styled-components'
import ContractApi from '../../apis/contract'
import { useAuthProvider } from '../../contexts/auth'

const UnorderedList = styled.ul`
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
`
const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    font-size: 12px;
  }
`

const NextActionArea = (props: {
  isContract: boolean
  id: string
  suumoUnderAverageListPvPercent?: number | null
  suumoUnderAverageTransitionRate?: number | null
  refresh: () => void
}) => {
  const { claims } = useAuthProvider()

  const isAgent = claims?.role === 'agent'
  const onHidden = async () => {
    if (props.isContract) {
      await ContractApi.confirmContractNotification(claims?.role!, props.id)
      props.refresh()
      return
    }
    await ContractApi.confirmFeatureNotification(claims?.role!, props.id)

    props.refresh()
  }
  if (
    !props.suumoUnderAverageListPvPercent &&
    !props.suumoUnderAverageTransitionRate
  ) {
    return <></>
  }
  return (
    <NotificationArea
      title={'お知らせ'}
      tipText={
        'SUUMOの掲載で一覧PVなどがエリア平均値を下回った場合に表示されます。'
      }
      onAction={onHidden}
    >
      <UnorderedList>
        {props.suumoUnderAverageListPvPercent && (
          <ListItem>
            一覧PVが{props.suumoUnderAverageListPvPercent}
            %平均より下回りました。
            <br />
            {isAgent &&
              '対象の物件が検索結果に表示されていません。suumoの検索の仕組み上、500万刻みで検索が行われるので、それを意識して値段を変更してみましょう。'}
            {!isAgent &&
              '問い合わせも少ないようであれば、周辺の成約・売り出し事例をご確認いただき、適切な売り出し方法をエージェントとご相談ください。'}
          </ListItem>
        )}
        {props.suumoUnderAverageTransitionRate && (
          <ListItem>
            {isAgent && (
              <>
                一覧から物件詳細画面への遷移率が
                {props.suumoUnderAverageTransitionRate}%平均を下回りました。
                <br />
                検索結果には表示されていますが、他の物件に閲覧が流れている可能性があります。
                同条件の他物件と比較しつつ、1-3枚目の写真の入れ替え、一覧キャッチ(有料オプション)、価格変更を検討してみましょう。
              </>
            )}
            {!isAgent && (
              <>
                検索結果上でのクリック率が
                {props.suumoUnderAverageTransitionRate}%平均より下回りました。
                <br />
                売り出し方法や価格をエージェントと見直すことで改善できる可能性があります。
              </>
            )}
          </ListItem>
        )}
      </UnorderedList>
    </NotificationArea>
  )
}

export default NextActionArea
