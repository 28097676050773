import styled, { css } from 'styled-components'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Transition, TransitionStatus } from 'react-transition-group'
import getLayer from 'styles/layer'

interface TransitionProps {
  state: TransitionStatus
}

const modalWrapperAnimate = (props: TransitionProps) => {
  const { state } = props
  if (state === 'entered') {
    return css`
      opacity: 1;
      pointer-events: visible;
    `
  }
  return css`
    opacity: 0;
  `
}

const ModalWrapper = styled.div<TransitionProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: ${(p) => p.theme.OVERLAY};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${getLayer('modal')};

  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  ${modalWrapperAnimate}
`

const modalContentAnimate = (props: TransitionProps) => {
  const { state } = props
  if (state === 'entered') {
    return css`
      transform: translateY(0);
    `
  }
  return css`
    transform: translateY(-200px);
  `
}

export const ModalContent = styled.div<TransitionProps>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;

  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  ${modalContentAnimate}
`

const CloseIconWrapper = styled.div`
  cursor: pointer;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    img {
      width: 12px;
      height: 12px;
    }
  }
`

const ModalHeader = styled.div`
  position: relative;
  height: 80px;

  ${CloseIconWrapper} {
    position: absolute;
    top: 28px;
    left: 40px;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 60px;

    ${CloseIconWrapper} {
      top: 20px;
      left: 20px;
    }
  }
`

export const ModalBody = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1;
  background-color: ${(p) => p.theme.OVERLAY};
`

export interface ModalProps {
  show?: boolean
  onClose: () => void
  children: React.ReactNode
  noHeader?: boolean
  className?: string
}

const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  children,
  noHeader,
  className,
}) => {
  // モーダル表示時、一時的にbodyのスクロールを無効にする。
  useEffect(() => {
    if (show) {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.left = '0px'
      document.body.style.right = '0px'
      document.body.style.position = 'fixed'
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.left = ''
      document.body.style.right = ''
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
    }
  }, [show])

  const component = (
    <Transition in={show} unmountOnExit timeout={{ enter: 0, exit: 350 }}>
      {(state: TransitionStatus) => (
        <ModalWrapper
          onMouseDown={onClose}
          state={state}
          className={className}
          role="dialog"
          aria-label="backdrop"
        >
          <ModalContent onMouseDown={(e) => e.stopPropagation()} state={state}>
            {noHeader ? null : (
              <ModalHeader>
                <CloseIconWrapper onClick={onClose} aria-label="close">
                  <img src="/image/close-wh.svg" alt="close" />
                </CloseIconWrapper>
              </ModalHeader>
            )}
            <ModalBody>{children}</ModalBody>
          </ModalContent>
        </ModalWrapper>
      )}
    </Transition>
  )

  return ReactDOM.createPortal(
    component,
    document.querySelector('#root') || document.body
  )
}

export default Modal
