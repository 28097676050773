import { isSameDay } from 'date-fns'
import { Fragment, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import Page from 'commons/page'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import ChatMessageItem from '../../features/contracts/chat/chat-message'
import DatePanel from '../../features/contracts/chat/chat-date'
import ChatFooter from '../../features/contracts/chat/chat-footer'
import ChatHeader from '../../features/contracts/chat/chat-header'
import useFetchChatRoom from './hooks/useFetchChatRoom'
import { useRoomChange } from './hooks/useFirestoreChange'
import { useChatScroll } from './hooks/useChatScroll'
import { getPartnerId, isReadLastMessage } from './logic/chat'
import { fetchContractById, selectContractById } from 'stores/contract'
import { ChatMessage, SendUser } from 'schema/chat'

interface ViewChatMessage extends ChatMessage {
  isRead: boolean
  firstTimeOfDay: boolean
}

const ChatPanelContainer = styled('div')<any>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0 20px;
  max-height: calc(100vh - 80px - 84px);

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    max-height: calc(100vh - 60px - 70px);
  }
`

const ChatListContainer = styled('div')<any>`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0 20px;
  padding: 20px 0 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const ChatPannel = () => {
  const { claims, currentUser } = useAuthProvider()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const contract = useAppSelector(selectContractById)
  const contractId = useMemo(() => id ?? contract?.id ?? '', [contract?.id, id])
  const senderId = useMemo(() => currentUser?.uid, [currentUser?.uid])

  useRoomChange(contractId)

  const { chatRoom, messages, loading } = useFetchChatRoom(contract, true)
  const viewMessage = useMemo(() => {
    const list: ViewChatMessage[] = []
    let prevDate: Date
    const partnerId = getPartnerId(chatRoom, currentUser?.uid) // 相手のID
    let readFlag: boolean =
      partnerId && chatRoom && chatRoom.messageReadState
        ? !!chatRoom.messageReadState[partnerId]
        : false

    messages.forEach((message) => {
      let firstTimeOfDay = true
      const isReadLastFlag = isReadLastMessage(
        currentUser?.uid,
        chatRoom,
        message
      )

      const messageDate = new Date(message.createdAt * 1000)
      firstTimeOfDay = !isSameDay(prevDate, messageDate)
      prevDate = messageDate
      list.push({
        ...message,
        isRead: readFlag,
        firstTimeOfDay,
      })

      if (readFlag && isReadLastFlag) {
        readFlag = false
      }
    })

    return list
  }, [chatRoom, currentUser?.uid, messages])

  const ref = useChatScroll(chatRoom?.messages)

  useEffect(() => {
    if (claims && claims.role && contractId) {
      dispatch(fetchContractById({ role: claims?.role, id: contractId }))
    }
  }, [contractId, dispatch, claims])

  const findUser = (uid: string): SendUser | null => {
    if (uid === contract?.agentId) {
      return {
        id: contract?.agentId || '',
        role: 'agent',
      }
    }

    const owner = contract?.owners.find((owner) => owner.uid === uid)
    if (owner) {
      return {
        id: owner.uid,
        name: owner.name,
        role: 'owner',
      }
    }

    return null
  }

  return (
    <>
      <Page
        title={`チャット | ${contract?.name}`}
        loading={loading}
        type="user"
        infiniteScroll
        customHeader={<ChatHeader contract={contract} />}
      >
        <ChatPanelContainer>
          <ChatListContainer ref={ref}>
            {!loading &&
              senderId &&
              chatRoom &&
              viewMessage.map((item) => {
                const user = findUser(item.senderId)
                return (
                  <Fragment key={item.id}>
                    {item.firstTimeOfDay && <DatePanel message={item} />}
                    <ChatMessageItem
                      message={item}
                      isRead={item.isRead}
                      user={user}
                    />
                  </Fragment>
                )
              })}
          </ChatListContainer>
        </ChatPanelContainer>
      </Page>
      <ChatFooter contract={contract} />
    </>
  )
}

export default ChatPannel
