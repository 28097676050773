import {
  MapContainer as BaseMapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import Leaflet from 'leaflet'
import styled from 'styled-components'
import { ReactNode } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import MarkerClusterGroup from 'react-leaflet-cluster'

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export interface MarkerProps {
  lat: number
  lon: number
  popup?: ReactNode
  markerItemNumberLabel?: string
}

interface PrivateMarkerProps extends MarkerProps {
  type: 'center' | 'pin'
}
const MapContainer = styled(BaseMapContainer)`
  width: 100%;
  height: 100%;
  z-index: 0;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 50vh;
  }
  /*マーカーのCSS*/
  .center_icon {
    position: absolute;
  }

  .center_icon:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    left: -9px;
    top: -22px;
    border: solid 1px;
    background: currentColor;
    border-radius: 10px 10px 10px 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: content-box;
  }

  // センターマーカーの中心の丸
  .center_icon:after {
    content: '';
    position: absolute;
    left: -3px;
    top: -16px;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 4px;
  }

  .pin_icon {
    position: absolute;
  }

  .pin_icon:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    left: -9px;
    top: -22px;
    border: solid 1px;
    background: currentColor;
    border-radius: 10px 10px 10px 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: content-box;
  }

  /*マーカーの色のCSS*/
  .center_color {
    background-color: ${(p) => p.theme.RED};
    color: ${(p) => p.theme.RED};
    border-color: ${(p) => p.theme.RED};
  }

  .pin_color {
    background-color: ${(p) => p.theme.MAIN};
    color: ${(p) => p.theme.MAIN};
    border-color: ${(p) => p.theme.MAIN};
  }
  .marker-icon {
    color: black;
    position: fixed;
    inset: 0;
    margin: auto;
    top: -20px;
    left: -3px;
  }
`

const MapIcon = styled.div<{ length?: number }>`
  color: black;
  position: fixed;
  inset: 0;
  margin: auto;
  top: -20px;
  // 一桁と二桁で位置がずれるので調整、三桁は考慮していない
  left: ${(p) => ((p.length ?? 1) === 1 ? '-1.5px' : '-5px')};
`
const MapMarker = ({ props }: { props: PrivateMarkerProps }) => {
  const mapIconText =
    props.markerItemNumberLabel !== undefined ? props.markerItemNumberLabel : ''

  const icon = Leaflet.divIcon({
    html: renderToStaticMarkup(
      <MapIcon length={mapIconText.length}>{mapIconText}</MapIcon>
    ),
    className:
      props.type === 'pin' ? 'pin_color pin_icon' : 'center_color center_icon',
    iconSize: [0, 0],
  })

  return (
    <Marker position={[props.lat, props.lon]} icon={icon}>
      {props.popup && <Popup>{props.popup}</Popup>}
    </Marker>
  )
}

interface PositionType {
  lat: number
  lon: number
}
const calcPosition = (
  position: [number, number] | undefined,
  markers: MarkerProps[]
): PositionType | undefined => {
  if (position) {
    return {
      lat: position[0],
      lon: position[1],
    }
  }

  if (markers.length > 0) {
    return { lat: markers[0]!.lat, lon: markers[0]!.lon }
  }

  return undefined
}

const Map = (props: {
  position?: [number, number] | undefined
  markers: MarkerProps[]
}) => {
  const position = calcPosition(props.position, props.markers)

  if (!position) {
    return <></>
  }
  return (
    <MapContainer
      center={[position.lat, position.lon]}
      zoom={14}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* センターマーカー */}
      {props.position && (
        <MapMarker
          props={{
            lat: props.position[0],
            lon: props.position[1],
            type: 'center',
          }}
        />
      )}
      {/* propsのmarker */}
      <MarkerClusterGroup showCoverageOnHover={false}>
        {props.markers
          .map((marker) => {
            return {
              ...marker,
              type: 'pin',
            } as PrivateMarkerProps
          })
          .map((marker, index) => (
            <MapMarker key={`map-marker-${index}`} props={marker} />
          ))}
      </MarkerClusterGroup>
    </MapContainer>
  )
}

export default Map
