import styled from 'styled-components'
import { FacebookLoginButton } from 'react-social-login-buttons'
import { ReactComponent as FacebookIcon } from '../../assets/facebook-icon.svg'

const SocialLoginButtonLabel = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`
export const FacebookLogo = ({
  labelText,
  // @ts-ignore
  onClick,
}: {
  labelText: string
  onClick?: () => any
}) => {
  return (
    <FacebookLoginButton
      style={{ borderRadius: '6px', cursor: onClick ? 'pointer' : 'default' }}
      icon={FacebookIcon}
      disabled={!onClick}
      onClick={onClick}
    >
      <SocialLoginButtonLabel>{labelText}</SocialLoginButtonLabel>
    </FacebookLoginButton>
  )
}
