/**
 * ルーティング
 */

/**
 * A: 共通
 * B: 売り主向け
 * C: エージェント向け
 *
 * 01XX: ログイン前共通
 * 02XX: ログイン後共通
 * 03XX: 媒介契約
 * 04XX: アカウント関連
 */
const ROUTE = {
  // 共通
  A0101: '/', // ログイン前トップ
  // 売り主向け
  B0101: '/owners', // ログイン
  B0102: '/owners/forgot', // パスワード忘れ
  B0103: '/owners/action', // アクションページ
  OwnerSignInLink: '/owners/signinlink', // メールリンクログインのためのメール送信後ページ
  OwnerSignInLinkHelp: '/owners/signinlink/help', // メールリンクログインのためのヘルプページ

  B0201: '/owners/home', // ホーム
  B0301: '/owners/contracts', // 契約一覧
  B0302: '/owners/contracts/:id', // 契約詳細
  B0303: '/owners/contracts/:id/chat', // チャット
  B0304: '/owners/contracts/:id/suumo', // suumo詳細
  B0305: '/owners/contracts/:id/athome', // athome詳細
  B0311: '/owners/contracts/:id/comparable-sales', // 周辺事例一覧
  B0401: '/owners/settings/email', // メールアドレス変更
  B0402: '/owners/settings/email/done', // メールアドレス変更
  B0403: '/owners/settings/password', // パスワード変更
  B0404: '/owners/settings/notification', // 通知設定
  B0405: '/owners/settings/authentication', // ログイン設定
  OwnerContractOther: '/owners/contracts/:id/other', // REINS問合せサマリページ
  OwnerContractOtherViewingRequest:
    '/owners/contracts/:id/other/viewing-request',
  OwnerActiveInventories: '/owners/contracts/:id/active-inventories', // 売出物件一覧
  OwnerSuccessfullyConcludedDeals:
    '/owners/contracts/:id/successfully-concluded-deals', // 成約事例一覧

  // エージェント向け
  C0101: '/agents', // ログイン
  C0201: '/agents/home', // ホーム
  C0301: '/agents/contracts', // 契約一覧
  C0302: '/agents/contracts/:id', // 契約詳細
  C0303: '/agents/contracts/:id/chat', // チャット
  C0304: '/agents/contracts/:id/suumo', // suumo詳細
  C0305: '/agents/contracts/:id/athome', // athome詳細
  C0311: '/agents/contracts/:id/comparable-sales', // 周辺事例一覧
  AgentContractOther: '/agents/contracts/:id/other', // REINS問合せサマリページ
  AgentContractOtherViewingRequest:
    '/agents/contracts/:id/other/viewing-request',
  AgentContractOtherDocumentRequest:
    '/agents/contracts/:id/other/document-request',
  AgentContractOtherPurchaseApplication:
    '/agents/contracts/:id/other/purchase-application',
  AgentContractOtherInquiry: '/agents/contracts/:id/other/other-inquiry',
  AgentActiveInventories: '/agents/contracts/:id/active-inventories', // 売出物件一覧
  AgentSuccessfullyConcludedDeals:
    '/agents/contracts/:id/successfully-concluded-deals', // 成約事例一覧
}

export default ROUTE
