import styled from 'styled-components'
import getLayer from 'styles/layer'

const Container = styled.div`
  z-index: ${getLayer('header')} !important;
  position: fixed;
  width: 22px;
  height: 16px;
  left: 22px;
  top: 32px;
}

@media only screen and (max-width: ${(p) => p.theme.vp}px) {
  top: 20px;
}
`

const ReturnLink = ({ visible }: { visible?: boolean }) => {
  const onHandler = () => {
    // ブラウザの戻るを実施
    window.history.back()
  }

  return (
    <Container hidden={!(visible === undefined ? true : visible)}>
      <img src="/image/arrow-left.svg" alt="return" onClick={onHandler} />
    </Container>
  )
}

export default ReturnLink
