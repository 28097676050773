import { Link } from 'react-router-dom'
import styled, { css, StyledProps } from 'styled-components'

import Button from 'components/button'
import { Horizontal } from 'components/layout'
import { StrongText, Text } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import useFetchChatRoom from '../../../pages/contract/hooks/useFetchChatRoom'
import { Contract, ContractLink, FeatureLink } from 'schema/contract'
import { UserRole } from 'schema/user'
import { convertPrice, formatedDate } from 'utils'

interface ContractListItemProps {
  type: string | null
  data: ContractLink
}

interface FeatureListItemProps {
  type: string | null
  data: FeatureLink
  image: string
  imageAlt: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 40px) / 3);
  padding: 10px 0;
  border-bottom: 1px solid ${(p) => p.theme.BORDER};
  box-sizing: border-box;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 100%;
  }
`

const Contents = styled.div`
  max-width: calc(100% - 10px);
  overflow: hidden;
  width: 100%;
`

const ContentsWithImage = styled(Contents)`
  display: flex;
  align-items: center;

  > img {
    display: block;
    height: 22px;
    margin-right: 10px;
  }
`

const ServiceIcon = styled.img`
  width: 44px;
  object-fit: contain;
`

const PropertyName = styled(StrongText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const OwnerName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const cssOfUnread = (props: StyledProps<{ unread?: number | false }>) => {
  const { unread } = props
  if (unread) {
    return css`
      :after {
        content: '';
        position: absolute;
        top: -4px;
        right: -2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${(p) => p.theme.UNREAD};
      }
    `
  }

  return css``
}

const LinkButton = styled(Button)<{ icon: string; unread?: number | false }>`
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-image: url('/image/${(p) => p.icon}.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  ${cssOfUnread}
`

export const ContractListItem = ({ type, data }: ContractListItemProps) => {
  const { claims } = useAuthProvider()
  const { unreadCnt } = useFetchChatRoom(data as Contract, false)
  const { termStartAt, termEndAt } = data

  return (
    <Container>
      <Contents>
        <PropertyName size="sm">{data.name}</PropertyName>
        <Horizontal align="center" gap={8}>
          <img src="/image/price.svg" alt="price" />
          <Text size="md">{convertPrice(data.price)}円</Text>
        </Horizontal>
        <Horizontal align="center" gap={8}>
          <img src="/image/calendar.svg" alt="calendar" />
          <Text size="md">
            {formatedDate(termStartAt)} - {formatedDate(termEndAt)}
          </Text>
        </Horizontal>
        {claims?.role === UserRole.AGENT && (
          <Horizontal align="center" gap={8}>
            <img src="/image/owner.svg" alt="owner" />
            <OwnerName size="md">{data.ownerName}さん</OwnerName>
          </Horizontal>
        )}
      </Contents>
      <Horizontal>
        <LinkButton
          as={Link}
          icon="detail"
          to={`${data.toDetail}?type=${type}`}
        />
        <LinkButton as={Link} icon="chat" to={data.toChat} unread={unreadCnt} />
      </Horizontal>
    </Container>
  )
}

export const FeatureListItem = ({
  type,
  data,
  image,
  imageAlt,
}: FeatureListItemProps) => {
  const { termStartAt, termEndAt } = data

  return (
    <Container>
      <ContentsWithImage>
        <ServiceIcon src={image} alt={imageAlt} />
        <div>
          <PropertyName size="sm">{data.name}</PropertyName>
          <Horizontal align="center" gap={8}>
            <img src="/image/price.svg" alt="price" />
            <Text size="md">{convertPrice(data.price)}円</Text>
          </Horizontal>
          <Horizontal align="center" gap={8}>
            <img src="/image/calendar.svg" alt="calendar" />
            <Text size="md">
              {formatedDate(termStartAt)} - {formatedDate(termEndAt)}
            </Text>
          </Horizontal>
        </div>
      </ContentsWithImage>
      <Horizontal>
        <LinkButton
          as={Link}
          icon="detail"
          to={`${data.toDetail}?type=${type}`}
        />
      </Horizontal>
    </Container>
  )
}
