import { initializeApp, getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
  authDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
  projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
  storageBucket: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET'],
  messagingSenderId: process.env['REACT_APP_FIREBASE_MESSAGE_SENDER_ID'],
  appId: process.env['REACT_APP_FIREBASE_SENDER_ID'],
}

const firebaseApp = initializeApp(firebaseConfig)

const emulatorInUse =
  process.env['REACT_APP_EMULATOR_IN_USE'] === 'yes' ? true : false
const emulatorAuthPort = process.env['REACT_APP_EMULATOR_AUTH_PORT'] ?? '9099'
const emulatorFunctionsPort =
  process.env['REACT_APP_EMULATOR_FUNCTIONS_PORT'] == null
    ? 5001
    : parseInt(process.env['REACT_APP_EMULATOR_FUNCTIONS_PORT'])
const emulatorFirestorePort =
  process.env['REACT_APP_EMULATOR_FIRESTORE_PORT'] == null
    ? 8080
    : parseInt(process.env['REACT_APP_EMULATOR_FIRESTORE_PORT'])

if (emulatorInUse) {
  const auth = getAuth()
  connectAuthEmulator(auth, `http://localhost:${emulatorAuthPort}`)
  const functions = getFunctions(getApp())
  connectFunctionsEmulator(functions, 'localhost', emulatorFunctionsPort)
  const db = getFirestore()
  connectFirestoreEmulator(db, 'localhost', emulatorFirestorePort)
}

export default firebaseApp
