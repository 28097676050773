import { Text } from '../../components/typography'

interface LatestImportedDateTimeProps {
  importedDate: Date | null | undefined
}

const LatestImportedDatetime = ({
  props,
}: {
  props: LatestImportedDateTimeProps
}) => {
  if (!props.importedDate) {
    return <></>
  }

  return (
    <Text
      size={'sm'}
      align={'center'}
      style={{ fontWeight: 'bold', paddingBottom: '10px' }}
    >
      最新データ取得日時 : {props.importedDate.toLocaleString('ja-JP')}
    </Text>
  )
}

export default LatestImportedDatetime
