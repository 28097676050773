import styled, { css, StyledProps } from 'styled-components'

interface ButtonProps {
  color?: 'primary' | 'secondary' | 'thertary' | 'fourth'
}

const cssOfColor = (props: StyledProps<ButtonProps>) => {
  const { color = 'primary' } = props
  if (color === 'secondary') {
    return css`
      background-color: ${(p) => p.theme.SECONDARY};
      border-color: ${(p) => p.theme.SECONDARY};
      color: ${(p) => p.theme.WHITE};
    `
  }

  if (color === 'thertary') {
    return css`
      background-color: ${(p) => p.theme.THERTARY};
      border-color: ${(p) => p.theme.BORDER};
      color: ${(p) => p.theme.WHITE};
    `
  }

  if (color === 'fourth') {
    return css`
      background-color: ${(p) => p.theme.WHITE};
      border-color: ${(p) => p.theme.MAIN};
      color: ${(p) => p.theme.BLACK};
    `
  }

  return css`
    background-color: ${(p) => p.theme.MAIN};
    border-color: ${(p) => p.theme.MAIN};
    color: ${(p) => p.theme.WHITE};
  `
}

const Button = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: min(100%, 600px);
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  ${cssOfColor}

  :hover {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }

  &:disabled {
    background-color: ${(p) => p.theme.DISABLED};
    border-color: ${(p) => p.theme.DISABLED};
    color: ${(p) => p.theme.WHITE};
    pointer-events: none;
    cursor: default;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    max-width: 100%;
  }
`

export default Button
