import styled from 'styled-components'

const RequiredIcon = styled.span`
  display: inline-block;
  padding: 0 4px;
  background-color: ${(p) => p.theme.RED};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${(p) => p.theme.WHITE};
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
`

const NameLabel = styled.label`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    font-size: 12px;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  box-sizing: border-box;
  align-items: center;
  vertical-align: center;
  color: ${(p) => p.theme.FONT_COLOR};

  ${RequiredIcon} {
    margin-left: 4px;
  }
`
const Label = (props: {
  htmlFor: string | undefined
  labelName: string | undefined
  required: boolean | undefined
}) => (
  <LabelWrapper>
    <NameLabel htmlFor={props.htmlFor}>{props.labelName}</NameLabel>
    {props.required ? <RequiredIcon>必須</RequiredIcon> : null}
  </LabelWrapper>
)

export default Label
