import styled from 'styled-components'
import { Horizontal as BaseHorizontal } from 'components/layout'
import { StrongText, Text } from 'components/typography'
import { UnitInformation } from 'schema/contract'
import { convertPrice } from 'utils'
import theme from '../../../styles/theme'
import Toggle from '../../../components/toggle'

const Container = styled.div<{ isVertical?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(p) => (p.isVertical ? '100%' : 'calc((100% - 40px) / 3)')};
  padding: 10px 0;
  border-bottom: 1px solid ${(p) => p.theme.BORDER};
  box-sizing: border-box;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 100%;
  }
`
const Horizontal = styled(BaseHorizontal)`
  width: 100%;
`

const NameArea = styled(BaseHorizontal)`
  max-width: 85%;
`
const ToggleArea = styled(BaseHorizontal)`
  width: 15%;
`

const Contents = styled.div`
  max-width: calc(100% - 10px);
  overflow: hidden;
  width: 100%;
`

const PropertyName = styled(StrongText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`
const PropertyValue = styled(Text)<{ color?: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: ${(p) => p.color}; !important;
`
const PropertyLabel = styled(Text)<{ color?: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: ${(p) => p.color}; !important;
`

const PinLabel = styled.div`
  width: 20px;
  height: 20px;
  padding-top: 1px;
  background-color: ${(p) => p.theme.MAIN};
  border-radius: 50%;
  text-align: center;
  box-sizing: border-box;
  font-size: 12px;
`

export interface ComparableSalesListItemProps {
  unitInformation: UnitInformation & { itemNumberLabel?: string }
  isVisibleToggle?: boolean
  onChangeToggle?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  isVertical?: boolean
}

const PinNumberLabel = ({ label }: { label?: string }) => {
  if (label === undefined) {
    return <></>
  }

  return <PinLabel>{label}</PinLabel>
}

export const ComparableSalesListItem = ({
  unitInformation,
  isVisibleToggle,
  onChangeToggle,
  isVertical,
}: ComparableSalesListItemProps) => {
  const formatYM = (ym: string) => {
    // yyyy-mm -> yyyy年/mm
    return `${ym.slice(0, 4)}/${ym.slice(5, 7)}`
  }
  return (
    <Container isVertical={isVertical}>
      <Contents>
        <Horizontal justify="space-between">
          <NameArea>
            <PinNumberLabel label={unitInformation.itemNumberLabel} />
            <PropertyName size="sm">{unitInformation.name}</PropertyName>
          </NameArea>
          {isVisibleToggle && (
            <ToggleArea>
              <Toggle
                defaultChecked={unitInformation.isVisible ?? true}
                onChange={onChangeToggle}
              />
            </ToggleArea>
          )}
        </Horizontal>
        <Horizontal align="center" gap={8}>
          <Horizontal>
            <PropertyLabel color={theme.MAIN}>売出価格：</PropertyLabel>
            <PropertyValue color={theme.MAIN}>
              {convertPrice(unitInformation.salesPrice)}円
            </PropertyValue>
          </Horizontal>
        </Horizontal>
        <Horizontal align="center" gap={8}>
          <Horizontal>
            <PropertyLabel>物件詳細：</PropertyLabel>
            <PropertyValue>
              {unitInformation.unitSize}m2、{unitInformation.floorPlan}、
              {unitInformation.story}階
            </PropertyValue>
          </Horizontal>
        </Horizontal>
        <Horizontal align="center" gap={8}>
          <Horizontal>
            <PropertyLabel>売出時期：</PropertyLabel>
            <PropertyValue style={{ marginRight: '10px' }}>
              {formatYM(unitInformation.salesYM)}
            </PropertyValue>
            <PropertyLabel>築年月：</PropertyLabel>
            <PropertyValue>{formatYM(unitInformation.builtDate)}</PropertyValue>
          </Horizontal>
        </Horizontal>
        <Horizontal align="center" gap={8}>
          <Horizontal>
            <PropertyLabel>物件住所：</PropertyLabel>
            <PropertyValue>{unitInformation.address}</PropertyValue>
          </Horizontal>
        </Horizontal>
      </Contents>
    </Container>
  )
}
