import { useMemo } from 'react'
import styled from 'styled-components'

import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { UserRole } from 'schema/user'
import getLayer from 'styles/layer'
import TopLogo from './top-logo'

export const HeaderContainer = styled.div`
  z-index: ${getLayer('header')};
  top: 0px;
  right: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 60px;
  }
`

const Header = () => {
  const { claims } = useAuthProvider()

  const destination = useMemo(() => {
    return claims?.role === UserRole.AGENT
      ? ROUTING_PATH.C0201
      : ROUTING_PATH.B0201
  }, [claims?.role])

  return (
    <HeaderContainer>
      <TopLogo to={destination} />
    </HeaderContainer>
  )
}

export const HeaderComponent = () => (
  <HeaderContainer>
    <TopLogo />
  </HeaderContainer>
)

export default Header
