import { ErrorMessage } from '@hookform/error-message'
import React, { ReactNode } from 'react'
import { Controller, FieldErrors, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import Label from '../label'

export interface FormControlProps {
  label?: string
  name: string
  className?: string
  required?: boolean
  children: ReactNode
}

const Container = styled.div`
  width: 100%;
  font-size: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
`

const ControllerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const Message = styled.div`
  font-weight: 500;
  color: ${(p) => p.theme.ERROR_FONT_COLOR};
  font-size: 12px;
  height: 20px;
  margin-left: 10px;
  padding-top: 2px;
  position: absolute;
  bottom: -20px;
  width: max-content;
`

const hasError = (name: string, errors?: FieldErrors) => {
  if (!errors) {
    return false
  }

  if (name.indexOf('.') === -1) {
    return !!errors[name]?.['message']
  }

  // NOTE ネスト構造を作る場合対応が必要。

  return false
}

const FormControl: React.FC<FormControlProps> = ({
  label,
  children,
  name,
  className = '',
  required,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <Container className={`form-control ${className}`}>
      <Label
        htmlFor={name}
        labelName={label ?? undefined}
        required={required}
      />
      <ControllerContainer>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return (
              <Controller
                name={name}
                control={control}
                render={({ field }) => React.cloneElement(child, { ...field })}
              />
            )
          }
          throw new Error('child is not a valid element.')
        })}
        {hasError(name, errors) && (
          <ErrorMessage errors={errors} name={name} as={Message} role="alert" />
        )}
      </ControllerContainer>
    </Container>
  )
}

export default FormControl
