import styled from 'styled-components'

import baseInputStyle, { InputProps } from './baseStyle'

const Input = styled.input.attrs<InputProps>((p) => ({
  autoComplete: 'off',
  value: p.value || '',
  type: p.type || 'text',
}))<InputProps>`
  ${baseInputStyle}
`

export default Input
