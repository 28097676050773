import { ChartData } from 'chart.js'
import { useMemo, useCallback } from 'react'

import { StackedBarGraph } from 'components/graph'
import { Contract, Feature, PoratalSiteInfo } from 'schema/contract'
import theme from 'styles/theme'
import { useBreakpoints } from 'styles'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
} from '../../../pages/contract/logic/graph'

const PortalSiteGraph = ({ contract }: { contract?: Contract | Feature }) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const getGraphData = useCallback(
    (reactions: PoratalSiteInfo[]) => {
      return createBarGraphData(reactions, breakpoint)
    },
    [breakpoint]
  )

  const suumoData = useMemo(() => {
    if (!contract?.suumo?.detailPv) {
      return []
    }

    return getGraphData(contract?.suumo?.detailPv)
  }, [contract?.suumo?.detailPv, getGraphData])

  const homesData = useMemo(() => {
    if (!contract?.homes?.detailPv) {
      return []
    }

    return getGraphData(contract?.homes?.detailPv)
  }, [contract?.homes?.detailPv, getGraphData])

  const athomeData = useMemo(() => {
    if (!contract?.athome?.detailPv) {
      return []
    }

    return getGraphData(contract?.athome?.detailPv)
  }, [contract?.athome?.detailPv, getGraphData])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: 'Suumo',
        data: suumoData,
        backgroundColor: theme.PORTAL_SITE_1,
        borderWidth: 0,
      },
      {
        label: `HOME's`,
        data: homesData,
        backgroundColor: theme.PORTAL_SITE_2,
        borderWidth: 0,
      },
      {
        label: `athome`,
        data: athomeData,
        backgroundColor: theme.PORTAL_SITE_3,
        borderWidth: 0,
      },
    ],
  }

  return (
    <StackedBarGraph
      data={data}
      options={BAR_GRAPH_OPTIONS}
      plugins={GRAPH_PLUGINS('PV')}
    />
  )
}

export default PortalSiteGraph
