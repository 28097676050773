import { fireStorage, fireStorageRef } from 'commons/firebase-storage'
import { useState } from 'react'
import { useUploadFile } from 'react-firebase-hooks/storage'
import { uuidv4 } from '@firebase/util'

const useFileUpload = (
  roomId: string,
  callBack: (path: string, originalFileName: string) => void
) => {
  const [uploadFile, uploading, snapshot, error] = useUploadFile()
  const [selectedFile, setSelectedFile] = useState<File>()

  const onUploadFile = async (file: File) => {
    const originalFileName = file.name
    const uid = uuidv4()
    const newFileName = `${uid}_${originalFileName}`

    const ref = fireStorageRef(fireStorage, `messages/${roomId}/${newFileName}`)
    const result = await uploadFile(ref, file, {
      contentType: file?.type ?? 'image/jpeg',
    })
    setSelectedFile(undefined)
    if (result?.metadata.fullPath)
      callBack(result?.metadata.fullPath, originalFileName)
    return result
  }

  const onChangeFile = async (file?: File) => {
    setSelectedFile(file)
    if (!file) {
      return
    }
    await onUploadFile(file)
  }

  return {
    uploading,
    error,
    snapshot,
    selectedFile,
    onChangeFile: onChangeFile,
    onUploadFile: onUploadFile,
  }
}
export { useFileUpload }
