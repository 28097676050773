import { createGlobalStyle } from 'styled-components'
import type { ThemeType } from 'styles/theme'

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  body {
    margin: 0;
    font-family: ${(p) => p.theme.FONT || 'system-ui'};
  }
`

export default GlobalStyle
