import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'

const AtHomePVGraph = ({ contract }: { contract?: Contract | Feature }) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const pcData = useMemo(() => {
    if (!contract?.athome?.detailPvForPC) {
      return []
    }

    return createBarGraphData(contract?.athome?.detailPvForPC, breakpoint)
  }, [breakpoint, contract?.athome?.detailPvForPC])

  const smartPhoneData = useMemo(() => {
    if (!contract?.athome?.detailPvForSmartphone) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.detailPvForSmartphone,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.detailPvForSmartphone])

  const featurePhoneData = useMemo(() => {
    if (!contract?.athome?.detailPvForFeaturephone) {
      return []
    }

    return createBarGraphData(
      contract?.athome?.detailPvForFeaturephone,
      breakpoint
    )
  }, [breakpoint, contract?.athome?.detailPvForFeaturephone])

  const pcSum = useMemo(() => {
    return total30Days(contract?.athome?.detailPvForPC || [])
  }, [contract?.athome?.detailPvForPC])

  const smartphoneSum = useMemo(() => {
    return total30Days(contract?.athome?.detailPvForSmartphone || [])
  }, [contract?.athome?.detailPvForSmartphone])

  const featurePhoneSum = useMemo(() => {
    return total30Days(contract?.athome?.detailPvForFeaturephone || [])
  }, [contract?.athome?.detailPvForFeaturephone])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: `PC`,
        data: pcData,
        backgroundColor: theme.GRAPH_COLOR_03,
        borderWidth: 0,
      },
      {
        label: `スマホ`,
        data: smartPhoneData,
        backgroundColor: theme.GRAPH_COLOR_04,
        borderWidth: 0,
      },
      {
        label: 'ケータイ',
        data: featurePhoneData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <StackedBarGraph
        data={data}
        options={BAR_GRAPH_OPTIONS}
        plugins={GRAPH_PLUGINS('PV')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日の詳細PV数',
            value: featurePhoneSum + smartphoneSum + pcSum,
            unit: '回',
            detail: {
              list: [
                {
                  label: 'PC',
                  value: pcSum,
                  unit: '回',
                },
                {
                  label: 'スマホ',
                  value: smartphoneSum,
                  unit: '回',
                },
                {
                  label: 'ケータイ',
                  value: featurePhoneSum,
                  unit: '回',
                },
              ],
            },
          },
        ]}
      />
    </>
  )
}

export default AtHomePVGraph
