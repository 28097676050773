import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { ButtonWrapper } from 'components/layout'
import { PageTitle, Text } from 'components/typography'
import ROUTING_PATH from 'constants/route'
import { TextLink } from 'components/link'

const SignInLink = () => {
  return (
    <Page title="メール送信完了" type="guest">
      <PageTitle>メール送信完了</PageTitle>
      <Block>
        <Text>ログインURLを送信しました。</Text>
        <Text>メールに記載されているURLからログインしてください。</Text>
      </Block>
      <Block>
        <Text>送信したEメールアドレス</Text>
        <Text>{window.localStorage.getItem('emailForSignIn')}</Text>
      </Block>
      <Block>
        <TextLink to={ROUTING_PATH.OwnerSignInLinkHelp}>
          メールが届かない場合はこちら
        </TextLink>
      </Block>
      <ButtonWrapper>
        <Button
          color="secondary"
          onClick={() => {
            window.location.href = ROUTING_PATH.B0101
          }}
        >
          ログインページへ戻る
        </Button>
      </ButtonWrapper>
    </Page>
  )
}

export default SignInLink
