import styled from 'styled-components'
import { ErrorMessage } from 'schema/error'

const ErrorContainer = styled.div`
  width: 311px;
  min-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin: 0 auto 20px;
  background-color: #ffd5c8;
  box-sizing: border-box;

  > div {
    color: ${(p) => p.theme.ERROR_FONT_COLOR};
    font-size: 14px;
    font-weight: 700;
  }
`

interface ErrorAreaProps {
  messages: Array<ErrorMessage>
}

const ErrorArea = ({ messages }: ErrorAreaProps) => (
  <ErrorContainer>
    {messages.map((m) => (
      <div key={m.key} role="alert">
        {m.messageNode || m.message}
      </div>
    ))}
  </ErrorContainer>
)

export default ErrorArea
