import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import contractApi from 'apis/contract'
import { AsyncTaskStatus } from 'schema/common'
import { UserRole } from 'schema/user'
import { Contract, Feature } from 'schema/contract'
import { RootState } from 'stores'
import { getTypePrefix } from 'utils'

export const CONTRACT_STORE_NAME = 'contract'

export const fetchContract = createAsyncThunk<
  { data: Array<Contract> },
  { role: UserRole }
>('fetchContract', async ({ role }, { rejectWithValue }) => {
  try {
    return await contractApi.fetchContract(role)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchContractById = createAsyncThunk<
  { data: Contract },
  { role: UserRole; id: string }
>('fetchContractById', async ({ role, id }, { rejectWithValue }) => {
  try {
    return await contractApi.fetchContractById(role, id)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchFeature = createAsyncThunk<{ data: Array<Feature> }>(
  'fetchFeature',
  async (_, { rejectWithValue }) => {
    try {
      return await contractApi.fetchFeature()
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchFeatureById = createAsyncThunk<
  { data: Feature },
  { id: string }
>('fetchFeatureById', async ({ id }, { rejectWithValue }) => {
  try {
    return await contractApi.fetchFeatureById(id)
  } catch (err) {
    return rejectWithValue(err)
  }
})

interface ContractState {
  contracts: Array<Contract>
  contract?: Contract
  features: Array<Feature>
  feature?: Feature
  status: Record<string, AsyncTaskStatus>
}

export const initialState: ContractState = {
  contracts: [],
  contract: undefined,
  features: [],
  status: {
    [fetchContract.typePrefix]: 'idle',
    [fetchContractById.typePrefix]: 'idle',
    [fetchFeature.typePrefix]: 'idle',
    [fetchFeatureById.typePrefix]: 'idle',
  },
}

export const contractSlice = createSlice({
  name: CONTRACT_STORE_NAME,
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContract.fulfilled, (state, action) => {
        state.contracts = action.payload.data
      })
      .addCase(fetchContractById.fulfilled, (state, action) => {
        state.contract = action.payload.data
      })
      .addCase(fetchFeature.fulfilled, (state, action) => {
        state.features = action.payload.data
      })
      .addCase(fetchFeatureById.fulfilled, (state, action) => {
        state.feature = action.payload.data
      })
      .addMatcher(isPending, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'loading'
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'idle'
      })
      .addMatcher(isRejected, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'failed'
        throw action.payload
      })
  },
})

export const { resetStore } = contractSlice.actions

export const selectContractList = (state: RootState) => state.contract.contracts

export const selectContractById = (state: RootState) => state.contract.contract

export const selectFeatureList = (state: RootState) => state.contract.features

export const selectFeatureById = (state: RootState) => state.contract.feature

export const selectContractListLoading = (state: RootState) =>
  state.contract.status[fetchContract.typePrefix] === 'loading'

export const selectContractByIdLoading = (state: RootState) =>
  state.contract.status[fetchContractById.typePrefix] === 'loading'

export const selectContractByIdFailed = (state: RootState) =>
  state.contract.status[fetchContractById.typePrefix] === 'failed'

export const selectFeatureListLoading = (state: RootState) =>
  state.contract.status[fetchFeature.typePrefix] === 'loading'

export const selectFeatureByIdLoading = (state: RootState) =>
  state.contract.status[fetchFeatureById.typePrefix] === 'loading'

export const selectFeatureByIdFailed = (state: RootState) =>
  state.contract.status[fetchFeatureById.typePrefix] === 'failed'

export default contractSlice.reducer
