import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Page from 'commons/page'
import Block from 'components/block'
import { Horizontal } from 'components/layout'
import Panel from 'components/panel'
import { SectionTitle } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import AtHomeFavoriteGraph from '../../features/contracts/graph/athome-favorite-graph'
import AtHomeMoviePlaysGraph from '../../features/contracts/graph/athome-movie-plays-graph'
import AtHomePVGraph from '../../features/contracts/graph/athome-pv-graph'
import AtHomeReactionGraph from '../../features/contracts/graph/athome-reaction-graph'
import {
  fetchContractById,
  fetchFeatureById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
  selectFeatureById,
  selectFeatureByIdFailed,
  selectFeatureByIdLoading,
} from 'stores/contract'
import { UserRole } from 'schema/user'
import { useQuery } from 'utils'
import HelpTips from '../../components/help-tip'
import LatestImportedDatetime from '../../features/contracts/latest-imported-datetime'

const GraphArea = styled(Horizontal)`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
  }
`

const AtHomeDetail = () => {
  const { claims } = useAuthProvider()
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const feature = useAppSelector(selectFeatureById)
  const featureLoading = useAppSelector(selectFeatureByIdLoading)
  const featureFailed = useAppSelector(selectFeatureByIdFailed)
  const dispatch = useAppDispatch()
  const queryString = useQuery()
  const type = queryString.get('type')
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    if (type === 'feature') {
      if (claims?.role === UserRole.AGENT) {
        dispatch(fetchFeatureById({ id }))
      } else {
        // TODO 売り主がアクセスした場合の処理
      }
    } else {
      dispatch(fetchContractById({ role: claims?.role, id }))
    }
  }, [dispatch, claims?.role, type, id])

  const data = useMemo(() => {
    return type === 'feature' ? feature : contract
  }, [contract, feature, type])

  const HasApiError = useMemo(() => {
    return contractFailed || featureFailed
  }, [contractFailed, featureFailed])

  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title={`athome詳細 | ${contract?.name}`}
          loading={contractLoading || featureLoading}
          type="user"
        >
          <LatestImportedDatetime
            props={{
              importedDate:
                contract?.athomeLatestImportedDate ??
                feature?.athomeLatestImportedDate,
            }}
          />
          <Block>
            <SectionTitle>athome詳細</SectionTitle>
          </Block>
          <Block>
            <SectionTitle>
              詳細PV
              <HelpTips text="対象物件の詳細ページが表示された回数" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <AtHomePVGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>
              反響数
              <HelpTips text="掲載サイト上からのお問い合わせ件数" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <AtHomeReactionGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>
              お気に入り登録数
              <HelpTips text="対象物件がその日時点でお気に入り登録されている件数" />
            </SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <AtHomeFavoriteGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
          <Block>
            <SectionTitle>パノラマ・フォトムービー再生数</SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <AtHomeMoviePlaysGraph contract={data} />
              </GraphArea>
            </Panel>
          </Block>
        </Page>
      )}
    </>
  )
}

export default AtHomeDetail
