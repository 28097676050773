import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import ResetPassword from './reset-password'
import VerificationEmail from './verification-email'
import RecoverEmail from './recover-email'
import SignInWithEmailLink from './signin-email-link'
import Page from 'commons/page'
import { getParameterByName } from 'utils'

const Action = () => {
  const [mode, setMode] = useState<string | null>('')
  const [actionCode, setActionCode] = useState<string>('')
  const [redirectUrl, setRedirectUrl] = useState<string | null>('')
  const location = useLocation()

  useEffect(() => {
    const mode = getParameterByName(location.search, 'mode')
    const oobCode = getParameterByName(location.search, 'oobCode')
    const continueUrl = getParameterByName(location.search, 'continueUrl')
    if (!oobCode) {
      return
    }

    setMode(mode)
    setActionCode(oobCode)
    setRedirectUrl(continueUrl || '')
  }, [actionCode, location.search])

  switch (mode) {
    case 'resetPassword':
      // パスワードリセット
      return <ResetPassword actionCode={actionCode} redirectUrl={redirectUrl} />
    case 'recoverEmail':
      // メールアドレス変更キャンセル
      return <RecoverEmail actionCode={actionCode} redirectUrl={redirectUrl} />
    case 'verifyEmail':
      // メールアドレス認証
      return (
        <VerificationEmail actionCode={actionCode} redirectUrl={redirectUrl} />
      )
    // メールリンクログイン
    case 'signIn':
      return <SignInWithEmailLink redirectUrl={redirectUrl} />
    default:
      return <Page title="エラー" type="guest" />
  }
}

export default Action
