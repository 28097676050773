import { getUnixTime, subDays } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import styled, { css, StyledProps } from 'styled-components'

import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { Horizontal, ListWrapper } from 'components/layout'
import Panel from 'components/panel'
import {
  SectionTitle,
  StrongText,
  SubSectionTitle,
  Text,
} from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import ROUTING_PATH from 'constants/route'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import useFetchChatRoom from './hooks/useFetchChatRoom'
import PortalSiteGraph from '../../features/contracts/graph/portal-site-graph'
import { UserRole } from 'schema/user'
import {
  fetchContractById,
  fetchFeatureById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
  selectFeatureById,
  selectFeatureByIdFailed,
  selectFeatureByIdLoading,
} from 'stores/contract'
import { convertPrice, formatedDate, useQuery } from 'utils'
import MonthTally from '../../features/contracts/month-tally'
import { Contract } from '../../schema/contract'

import PropertyExampleSettingDialog from '../../features/contracts/dialog/property-example-setting-dialog'
import ActiveInventoryList from '../../features/contracts/list/active-inventory-list'
import { MoreLink } from '../../components/link'
import HelpTips from '../../components/help-tip'
import Toggle from '../../components/toggle'
import ContractApi from '../../apis/contract'
import SuccessfullyConcludedDealList from '../../features/contracts/list/successfully-concluded-deal-list'
import NextActionArea from '../../features/contracts/next-action-area'

const PropertyInfo = styled(Horizontal)`
  width: 400px;
  margin-top: 10px;
  margin-left: auto;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`

const ContractPropertyInfo = styled(Horizontal)`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: auto;

  > div {
    width: 50%;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`

const PropertyName = styled(StrongText)`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    margin-bottom: 20px;
  }
`

const Label = styled(StrongText)`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: auto;
  }
`

const GraphArea = styled(Horizontal)`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
  }
`

const DetailLinks = styled(Horizontal)`
  width: 100%;
  margin-top: 17px;
  display: flex;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-wrap: wrap;
  }
`

const DetailLink = styled(Link)<{ width?: string }>`
  display: flex;
  gap: 6px;
  justify-content: center;
  width: 50%;
  max-width: ${(p) => p.width ?? '150px'};
  padding: 12px 0;
  background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  color: ${(p) => p.theme.FONT_COLOR};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: ${(p) => p.width ?? '47%'};
    max-width: ${(p) => p.width ?? 'initial'};
  }
`
const DetailAnnotation = styled(Horizontal)`
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #463e28;
`
const FixedPhraseWrapper = styled(Horizontal)`
  gap: 20px;
`

const Spacer = styled.div`
  margin-bottom: 80px;
`

const FixedPhrase = styled(Button)`
  min-height: 40px;
  width: calc(100% / 3);
  padding: 4px;

  span {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    word-break: keep-all;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

const cssOfUnread = (props: StyledProps<{ unread?: number | false }>) => {
  const { unread } = props
  if (unread) {
    return css`
      :after {
        content: '';
        position: absolute;
        top: -5px;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${(p) => p.theme.UNREAD};
      }
    `
  }

  return css``
}

const ToChat = styled(Button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-image: url('/image/chat-lg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  ${cssOfUnread}
`

const ExternalLinks = styled(Horizontal)`
  gap: 10px;
  width: 100%;

  a,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98px;
    height: 40px;
    padding: 0 10px;
    background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
    border-radius: 3px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    pointer: cursor;
  }

  a img {
    display: block;
    width: 100%;
  }

  span {
    visibility: hidden;
  }
`

// 区切り線
const Separator = styled.hr`
  line-height: 1em;
  width: 100%;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  height: 1.5em;
  :before {
    content: '';
    background: #b6b6b6;
    position: absolute;
    left: 0;
    top: 30%;
    width: 100%;
    height: 1px;
  }
  :after {
    content: attr(data-content);
    position: relative;
    color: #818078;
    background-color: #fcfcfa;
  }
`

const ContractDetail = () => {
  const { claims } = useAuthProvider()
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const feature = useAppSelector(selectFeatureById)
  const featureLoading = useAppSelector(selectFeatureByIdLoading)
  const featureFailed = useAppSelector(selectFeatureByIdFailed)
  const dispatch = useAppDispatch()
  const queryString = useQuery()
  const type = queryString.get('type')
  const { id } = useParams<{ id: string }>()
  const toChat = useMemo(() => {
    const destination =
      claims?.role === UserRole.OWNER ? ROUTING_PATH.B0303 : ROUTING_PATH.C0303
    return generatePath(destination, { id: id })
  }, [claims?.role, id])
  const isOwner = useMemo(() => claims?.role === UserRole.OWNER, [claims?.role])
  const { unreadCnt } = useFetchChatRoom(contract, false)

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    if (type === 'feature') {
      if (claims?.role === UserRole.AGENT) {
        dispatch(fetchFeatureById({ id }))
      } else {
        // TODO 売り主がアクセスした場合の処理
      }
    } else {
      dispatch(fetchContractById({ role: claims?.role, id }))
    }
  }, [dispatch, claims?.role, type, id])

  const reFetch = () => {
    if (type === 'feature') {
      if (claims?.role === UserRole.AGENT) {
        dispatch(fetchFeatureById({ id: id! }))
      } else {
        // TODO 売り主がアクセスした場合の処理
      }
    } else {
      dispatch(fetchContractById({ role: claims!.role!, id: id! }))
    }
  }
  const data = useMemo(() => {
    return type === 'feature' ? feature : contract
  }, [contract, feature, type])

  const isContract = useMemo(() => {
    return type !== 'feature'
  }, [type])

  const pv = useMemo(() => {
    const monthAgo = getUnixTime(subDays(subDays(new Date(), 1), 30))
    const suumo = data?.suumo?.detailPv
      ?.filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0)
    const homes = data?.homes?.detailPv
      ?.filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0)
    const athome = data?.athome?.detailPv
      ?.filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0)

    return (suumo ?? 0) + (homes ?? 0) + (athome ?? 0)
  }, [data?.athome?.detailPv, data?.homes?.detailPv, data?.suumo?.detailPv])

  const reaction = useMemo(() => {
    const monthAgo = getUnixTime(subDays(subDays(new Date(), 1), 30))
    const suumoDocumentRequestSum = [...(data?.suumo?.documentRequest || [])]
      ?.filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0)

    const suumoVisitorReservationSum = [
      ...(data?.suumo?.visitorReservation || []),
    ]
      ?.filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0)

    const athomeDocumentRequestSum = [...(data?.athome?.documentRequest || [])]
      ?.filter((data) => {
        return data.date instanceof Timestamp
          ? data.date.toMillis()
          : data.date._seconds > monthAgo
      })
      .reduce((sum, data) => {
        return (sum += data.quantity)
      }, 0)

    return (
      suumoDocumentRequestSum +
      suumoVisitorReservationSum +
      athomeDocumentRequestSum
    )
  }, [
    data?.athome?.documentRequest,
    data?.suumo?.documentRequest,
    data?.suumo?.visitorReservation,
  ])

  const SuumoDetailLink = useMemo(() => {
    if (!data) {
      return ''
    }

    const destination =
      claims?.role === UserRole.OWNER ? ROUTING_PATH.B0304 : ROUTING_PATH.C0304
    return generatePath(destination, { id: data?.id })
  }, [claims?.role, data])

  const AtHomeDetailLink = useMemo(() => {
    if (!data) {
      return ''
    }

    const destination =
      claims?.role === UserRole.OWNER ? ROUTING_PATH.B0305 : ROUTING_PATH.C0305
    return generatePath(destination, { id: data?.id })
  }, [claims?.role, data])

  const HasApiError = useMemo(() => {
    return contractFailed || featureFailed
  }, [contractFailed, featureFailed])

  const [detailAnnotation, setDetailAnnotation] = useState('')
  useEffect(() => {
    if (!contract) {
      return
    }

    let sites: string[] = []
    if (!contract.suumo) {
      sites.push('SUUMO')
    }

    if (!contract.homes) {
      sites.push("HOME's")
    }

    if (!contract.athome) {
      sites.push('athome')
    }

    if (sites.length > 0) {
      setDetailAnnotation(`この物件は${sites.join(',')}には掲載されていません`)
    } else {
      setDetailAnnotation('')
    }
  }, [contract])

  const OwnerInfo = () => {
    if (isOwner) {
      return <></>
    }

    if (!isContract) {
      return <></>
    }

    if (!data) {
      return <></>
    }
    const contract = data as Contract
    return (
      <div>
        <Horizontal justify="space-between" align="center">
          <Label size="sm">売主名</Label>
          <Horizontal align="baseline">
            <StrongText size="md">{contract.ownerName} 様</StrongText>
          </Horizontal>
        </Horizontal>
        <Horizontal justify="space-between" align="center">
          <Label size="sm">売主Email</Label>
          <StrongText size="md">{contract.ownerMail}</StrongText>
        </Horizontal>
        <Horizontal justify="space-between" align="center">
          <Label size="sm">売主最終ログイン日時</Label>
          <Horizontal align="baseline">
            <StrongText size="md">
              {contract.ownerLastLoginAt === null
                ? '未ログイン'
                : formatedDate(contract.ownerLastLoginAt, 'yyyy/MM/dd HH:mm')}
            </StrongText>
          </Horizontal>
        </Horizontal>
      </div>
    )
  }

  const ReportSetting = () => {
    if (isOwner) {
      return <></>
    }

    if (!isContract) {
      return <></>
    }

    if (!data) {
      return <></>
    }
    const contract = data as Contract

    if (!contract.isNormalBusiness) {
      return <></>
    }

    const help = (
      <>
        業者物件（一般媒介）の場合に表示されます。
        <br />
        媒介報告メールが必要な場合はONにしてください。
      </>
    )
    const onChangeToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
      await ContractApi.updateReporting(id!, e.target.checked)
      reFetch()
    }

    return (
      <>
        <Separator />
        <Horizontal justify="space-between" align="center">
          <Horizontal
            justify="space-between"
            align="center"
            style={{ width: '100%' }}
          >
            <Horizontal>
              <Label size="md">媒介報告メール</Label>
              <HelpTips text={help} />
            </Horizontal>
          </Horizontal>
          <div>
            <Toggle
              defaultChecked={contract.isReportable!}
              onChange={onChangeToggle}
            />
          </div>
        </Horizontal>
      </>
    )
  }

  const ContractDetailPanel = () => {
    return (
      <>
        <ContractPropertyInfo gap={20}>
          <div>
            <Horizontal justify="space-between" align="center">
              <Label size="sm">物件名</Label>
              <StrongText size="md">{data?.name}</StrongText>
            </Horizontal>
            <Horizontal justify="space-between" align="center">
              <Label size="sm">価格</Label>
              <StrongText size="md">{convertPrice(data?.price)}円</StrongText>
            </Horizontal>
            <Horizontal justify="space-between" align="center">
              <Label size="sm">契約期間</Label>
              <StrongText size="md">
                {formatedDate(data?.termStartAt)} -{' '}
                {formatedDate(data?.termEndAt)}
              </StrongText>
            </Horizontal>
          </div>
          <OwnerInfo />
        </ContractPropertyInfo>

        <ReportSetting />
      </>
    )
  }
  const FutureDetailPanel = () => {
    return (
      <>
        <PropertyName size="md">{data?.name}</PropertyName>
        <PropertyInfo gap={20}>
          <Horizontal justify="space-between" align="center">
            <Label size="sm">物件名</Label>
            <Horizontal align="baseline">
              <StrongText size="md">{data?.name}</StrongText>
            </Horizontal>
          </Horizontal>
        </PropertyInfo>
        <PropertyInfo gap={20}>
          <Horizontal justify="space-between" align="center">
            <Label size="sm">価格</Label>
            <Horizontal align="baseline">
              <StrongText size="md">{convertPrice(data?.price)}円</StrongText>
            </Horizontal>
          </Horizontal>
        </PropertyInfo>
        <PropertyInfo justify="flex-start" gap={20}>
          <Horizontal justify="space-between" align="center">
            <Label size="sm">契約期間</Label>
            <Horizontal align="baseline">
              <StrongText size="md">
                {formatedDate(data?.termStartAt)} -{' '}
                {formatedDate(data?.termEndAt)}
              </StrongText>
            </Horizontal>
          </Horizontal>
        </PropertyInfo>
      </>
    )
  }

  const DetailPanel = () => {
    if (isContract) {
      return <ContractDetailPanel />
    }

    return <FutureDetailPanel />
  }

  const ActiveInventoryArea = () => {
    const activeInventoryItems = contract?.activeInventory?.items ?? []

    if (activeInventoryItems.length === 0) {
      return <></>
    }

    const to =
      activeInventoryItems.length === 0
        ? undefined
        : generatePath(
            claims?.role === UserRole.OWNER
              ? ROUTING_PATH.OwnerActiveInventories
              : ROUTING_PATH.AgentActiveInventories,
            { id: data?.id }
          )

    return (
      <Block>
        <SubSectionTitle>周辺の売出物件</SubSectionTitle>
        {!isOwner && (
          <Text size={'sm'}>
            売主様の画面に表示するかを物件毎に設定できます。
          </Text>
        )}
        <ListWrapper>
          <ActiveInventoryList
            list={activeInventoryItems.slice(0, 3)}
            contractId={id!}
            isOwner={isOwner}
            reFetch={reFetch}
          />
        </ListWrapper>
        {!!to && <MoreLink to={to} />}
      </Block>
    )
  }

  const SuccessfullyConcludedDealArea = () => {
    const items = contract?.successfullyConcludedDeal?.items ?? []

    if (items.length === 0) {
      return <></>
    }

    const to =
      items.length === 0
        ? undefined
        : generatePath(
            claims?.role === UserRole.OWNER
              ? ROUTING_PATH.OwnerSuccessfullyConcludedDeals
              : ROUTING_PATH.AgentSuccessfullyConcludedDeals,
            { id: data?.id }
          )

    return (
      <Block>
        <SubSectionTitle>周辺の成約事例</SubSectionTitle>
        <ListWrapper>
          <SuccessfullyConcludedDealList list={items.slice(0, 3)} />
        </ListWrapper>
        {!!to && <MoreLink to={to} />}
      </Block>
    )
  }
  const PropertyExampleInfoArea = (props: { onSuccess: () => void }) => {
    if (!isContract) {
      return <></>
    }

    if (!data) {
      return <></>
    }

    const contract = data as Contract

    return (
      <Block>
        {!isOwner && (
          <>
            <SubSectionTitle>周辺の売出物件と成約事例</SubSectionTitle>
            <PropertyExampleSettingDialog
              contractId={id!}
              config={
                contract.activeInventory?.config ||
                contract.successfullyConcludedDeal?.config
              }
              onSuccess={props.onSuccess}
            />
          </>
        )}

        <ActiveInventoryArea />
        <SuccessfullyConcludedDealArea />
      </Block>
    )
  }

  const toReinsDetailPage = generatePath(
    claims?.role === UserRole.OWNER
      ? ROUTING_PATH.OwnerContractOther
      : ROUTING_PATH.AgentContractOther,
    { id: id }
  )

  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title="物件詳細"
          loading={contractLoading || featureLoading}
          type="user"
        >
          <NextActionArea
            isContract={isContract}
            id={id!}
            suumoUnderAverageListPvPercent={
              isContract
                ? contract?.nextActionInfo?.suumoUnderAverageListPvPercent
                : feature?.nextActionInfo?.suumoUnderAverageListPvPercent
            }
            suumoUnderAverageTransitionRate={
              isContract
                ? contract?.nextActionInfo?.suumoUnderAverageTransitionRate
                : feature?.nextActionInfo?.suumoUnderAverageTransitionRate
            }
            refresh={reFetch}
          />
          <Block>
            <SectionTitle>物件情報</SectionTitle>
            <Panel>
              <DetailPanel />
            </Panel>
          </Block>
          <Block>
            <SectionTitle>各種ポータルサイト上での動き</SectionTitle>
            <Panel>
              <GraphArea justify="space-between">
                <PortalSiteGraph contract={data} />
                <MonthTally
                  list={[
                    {
                      label: '直近30日の表示数',
                      value: pv,
                      unit: '回',
                    },
                    {
                      label: '直近30日の反響数',
                      value: reaction,
                      unit: '回',
                    },
                  ]}
                />
              </GraphArea>
            </Panel>
            <DetailLinks gap={16}>
              {data?.suumo && (
                <DetailLink to={`${SuumoDetailLink}?type=${type}`}>
                  suumoの詳細データ
                  <img src="/image/arrow-right-gray.svg" alt="suumo" />
                </DetailLink>
              )}
              {data?.athome && (
                <DetailLink to={`${AtHomeDetailLink}?type=${type}`}>
                  athomeの詳細データ
                  <img src="/image/arrow-right-gray.svg" alt="athome" />
                </DetailLink>
              )}
              {isContract && (data as Contract)?.isPublishedReins && (
                <DetailLink width={'250px'} to={`${toReinsDetailPage}`}>
                  {isOwner
                    ? '業者からの問い合わせデータ'
                    : '2秒でブッカクからの問い合わせデータ'}
                  <img src="/image/arrow-right-gray.svg" alt="" />
                </DetailLink>
              )}
            </DetailLinks>
            <DetailAnnotation>{detailAnnotation}</DetailAnnotation>
          </Block>
          {isOwner && (
            <Block>
              <SubSectionTitle>定型文送信</SubSectionTitle>
              <FixedPhraseWrapper>
                <FixedPhrase
                  as={Link}
                  to={toChat}
                  state={{ fixedMessage: '売却価格を相談したい' }}
                >
                  <span>売却価格を</span>
                  <span>相談したい</span>
                </FixedPhrase>
                <FixedPhrase
                  as={Link}
                  to={toChat}
                  state={{ fixedMessage: '掲載内容を相談したい' }}
                >
                  <span>掲載内容を</span>
                  <span>相談したい</span>
                </FixedPhrase>
                <FixedPhrase
                  as={Link}
                  to={toChat}
                  state={{ fixedMessage: '媒介契約を延長したい' }}
                >
                  <span>媒介契約を</span>
                  <span>延長したい</span>
                </FixedPhrase>
              </FixedPhraseWrapper>
            </Block>
          )}
          <Block>
            <SubSectionTitle>
              各種ポータルサイト上での表示を確認する
            </SubSectionTitle>
            <ExternalLinks>
              {data?.suumo?.url && (
                <a href={data.suumo.url} target="_blank" rel="noreferrer">
                  <img src="/image/suumo.png" alt="SUUMO"></img>
                </a>
              )}
              {data?.athome?.url && (
                <a href={data.athome.url} target="_blank" rel="noreferrer">
                  <img src="/image/athome.png" alt="AtHome"></img>
                </a>
              )}
              {data?.homes?.url && (
                <a href={data.homes.url} target="_blank" rel="noreferrer">
                  <img src="/image/homes.png" alt="Homes"></img>
                </a>
              )}
            </ExternalLinks>
          </Block>
          <PropertyExampleInfoArea onSuccess={() => reFetch()} />
          <Spacer />
        </Page>
      )}
      {isContract && <ToChat as={Link} to={toChat} unread={unreadCnt} />}
    </>
  )
}

export default ContractDetail
