import Modal from 'components/modal'
import { useState } from 'react'
import styled from 'styled-components'
import { useGetDownloadURL } from '../../../pages/contract/hooks/useGetDownloadURL'
import { FileIcon, defaultStyles } from 'react-file-icon'
import LazyLoad from 'react-lazyload'

const Container = styled('div')<any>`
  dialog::backdrop {
    background-color: ${(p) => p.theme.OVERLAY};
  }
`

const ChatMessageImage = styled('img')<any>`
  border-radius: 6px;
  max-width: 224px;
  min-width: 160px;
  height: 160px;
  object-fit: cover;
`

const ExpantionImage = styled('img')<any>`
  width: 100vw;
  object-fit: contain;
`

const Icon = styled('div')<any>`
  width: 48px;
  margin: 16px;
  cursor: pointer;
`

const FileArea = styled('div')<any>``
const IconArea = styled('div')<any>`
  display: flex;
  flex-direction: ${(p) => (p.isSender ? 'row-reverse' : 'row')};
`

const ViewPdf = ({
  url,
  fileName,
  isSender,
}: {
  url: string | null
  fileName: string | null
  isSender: boolean
}) => {
  const onClick = () => {
    console.log('click', url)
    if (!url || !fileName) {
      return
    }

    fetch(url)
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = fileName
          a.click()
        })
      })
      .catch((err) => console.error(err))
  }

  return (
    <FileArea>
      ファイル名: {fileName}
      <IconArea isSender={isSender}>
        <Icon onClick={onClick}>
          <FileIcon
            extension="pdf"
            {...defaultStyles.pdf}
            gradientColor="gray"
          />
        </Icon>
      </IconArea>
    </FileArea>
  )
}

const FileViewer = (
  url: string | null,
  fileName: string | null,
  fileType: 'image' | 'pdf' | 'other' | null,
  setShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
  show: boolean,
  isSender: boolean
) => {
  if (!url) {
    return <></>
  }

  // 画像の場合
  if (fileType === 'image') {
    return (
      <>
        <LazyLoad>
          <ChatMessageImage src={url} onClick={() => setShow(true)} />
          <Modal show={show} onClose={() => setShow(false)}>
            <ExpantionImage src={url} />
          </Modal>
        </LazyLoad>
      </>
    )
  }

  // PDFの場合
  if (fileType === 'pdf') {
    return <ViewPdf url={url} fileName={fileName} isSender={isSender} />
  }

  // 以外の場合
  return (
    <>
      <p>ファイルを表示できません</p>
    </>
  )
}

const ChatFilePreview = ({
  path,
  originalFileName,
  isSender,
}: {
  path: string
  originalFileName: string | undefined
  isSender: boolean
}) => {
  const [show, setShow] = useState(false)
  const { url, fileName, fileType } = useGetDownloadURL(path)

  return (
    <Container>
      {FileViewer(
        url,
        originalFileName ?? fileName,
        fileType,
        setShow,
        show,
        isSender
      )}
    </Container>
  )
}
export default ChatFilePreview
