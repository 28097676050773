import styled from 'styled-components'

import { StatusType } from 'schema/contract'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 2px;
  border: 1px solid ${(p) => p.theme.BORDER};
  border-radius: 6px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    margin: 10px auto;
  }
`

const Status = styled.button<{ active: boolean }>`
  width: 128px;
  background-color: ${(p) => (p.active ? p.theme.MAIN : 'transparent')};
  border: none;
  border-radius: 6px;
  color: ${(p) => (p.active === true ? p.theme.WHITE : p.theme.BORDER)};
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
`

export const ContractedStatusTub = ({
  status,
  setStatus,
}: {
  status: StatusType
  setStatus: (status: StatusType) => void
}) => {
  return (
    <Container>
      <Status
        onClick={() => setStatus('inProgress')}
        active={status === 'inProgress'}
      >
        売却中
      </Status>
      <Status onClick={() => setStatus('done')} active={status === 'done'}>
        売却済/掲載終了
      </Status>
    </Container>
  )
}

export const FetaureStatusTub = ({
  status,
  setStatus,
}: {
  status: StatusType
  setStatus: (status: StatusType) => void
}) => {
  return (
    <Container>
      <Status
        onClick={() => setStatus('inProgress')}
        active={status === 'inProgress'}
      >
        掲載中
      </Status>
      <Status onClick={() => setStatus('done')} active={status === 'done'}>
        掲載前/掲載終了
      </Status>
    </Container>
  )
}
