import { Text } from 'components/typography'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding: 50px 0 80px;
`

const NoData = () => {
  return (
    <Container>
      <Text size="md" align="center">
        データがありません
      </Text>
    </Container>
  )
}

export default NoData
