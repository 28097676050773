import { ErrorLog } from 'schema/error'

export const parseErrorInfo = (
  info: ErrorEvent | Error | PromiseRejectionEvent
): ErrorLog => {
  let column
  let line
  let filename
  let message
  let stack
  let type = 'error'

  if (info instanceof ErrorEvent) {
    column = info.colno
    line = info.lineno
    filename = info.filename
    message = info.message
    stack = info.error.stack
    type = info.type
  } else if (info instanceof Error) {
    message = info.message
    stack = info.stack
  } else if (info instanceof PromiseRejectionEvent) {
    message = info.reason.message
    stack = info.reason.stack
    type = info.type
  }

  return {
    origin: window.location.origin,
    userAgent: navigator.userAgent,
    error: {
      column,
      line,
      filename,
      message,
      stack,
      type,
    },
    timestamp: Date.now(),
  }
}
