import styled from 'styled-components'

const PageTitle = styled.h2`
  margin: 0 0 30px;
  color: ${(p) => p.theme.FONT_COLOR};
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    margin: 0 0 30px;
    font-size: 16px;
    line-height: 16px;
  }
`

export default PageTitle
