import styled, { css, StyledProps } from 'styled-components'

interface StrongTextProps {
  align?: 'left' | 'center' | 'right'
  size?: 'sm' | 'md' | 'lg'
}

const cssOfSize = (props: StyledProps<StrongTextProps>) => {
  const { size = 'lg' } = props
  if (size === 'sm') {
    return css`
      font-size: 14px;
      line-height: 21px;

      @media only screen and (max-width: ${(p) => p.theme.vp}px) {
        font-size: 12px;
        line-height: 18px;
      }
    `
  }

  if (size === 'md') {
    return css`
      font-size: 20px;
      line-height: 30px;

      @media only screen and (max-width: ${(p) => p.theme.vp}px) {
        font-size: 16px;
        line-height: 16px;
      }
    `
  }

  return css`
    font-size: 22px;
    line-height: 33px;

    @media only screen and (max-width: ${(p) => p.theme.vp}px) {
      font-size: 18px;
      line-height: 16px;
    }
  `
}

const StrongText = styled.p<StrongTextProps>`
  margin: 0;
  color: ${(p) => p.theme.FONT_COLOR};
  font-weight: 700;
  text-align: ${(p) => (p.align ? p.align : 'left')};
  ${cssOfSize}
`

export default StrongText
