export const ErrorMsg = {
  FAILURE_REAUTH: 'パスワードが違います。',
  FORBIDDEN: 'アクセス権がありません。',
  DIFFERENT_PASSWORD: '新しいパスワードと異なります。',
  INVALID_EMAIL: 'メールアドレスのフォーマットではありません。',
  NOT_FOUND: 'ページが見つかりません。',
  NOT_FOUND_EMAIL: 'メールアドレスが見つかりません。',
  REQUIRED: '必須項目です。',
  SAME_EMAIL: '現在のメールアドレスと同一です。',
  CREDENTIAL_ALREADY_IN_USE:
    'このアカウントは他のアカウントに紐づけられています。以前の設定を解除してから実施してください。',
  AUTHENTICATION_FEDERATION_DEFAULT_ERROR:
    '予期せぬエラーが発生したためアカウントを設定できませんでした。改善されない場合は担当エージェントにご連絡ください。',
  UNAUTHORIZED_USER_ERROR:
    '入力されたアカウントはソーシャルログインの設定がされていません。メールアドレスでログイン後、ソーシャルログインの設定をした後にご利用ください。',
}
