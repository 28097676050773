import { useEffect } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import getLayer from 'styles/layer'
import theme from 'styles/theme'

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: ${getLayer('loading')};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Contents = styled.div`
  width: 100%;
  height: 100%;
`

const Loading = () => {
  useEffect(() => {
    const target = document.getElementsByTagName('body')

    if (!target[0]) {
      return
    }

    target[0].style.overflow = 'hidden'

    return () => {
      if (!target[0]) {
        return
      }

      target[0].style.overflow = 'visible'
    }
  }, [])

  return (
    <Container>
      <Contents>
        <ReactLoading type="bubbles" color={theme.MAIN} />
      </Contents>
    </Container>
  )
}

export default Loading
