import { FirebaseError } from '@firebase/util'
import log from 'loglevel'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  getCredential,
  updateAccountPassword,
  reauth,
} from 'commons/firebase-auth'
import Page from 'commons/page'
import Button from 'components/button'
import Block from 'components/block'
import { Form, FormControl } from 'components/form'
import { Input } from 'components/input-control'
import { ButtonWrapper } from 'components/layout'
import { Text } from 'components/typography'
import { ErrorMsg } from 'constants/message'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { AuthErrorCodes } from 'firebase/auth'
import { Link } from 'react-router-dom'
import NoLogoHeader from '../../components/no-logo-header'
import BasePanel from '../../components/panel'
import styled from 'styled-components'

const Panel = styled(BasePanel)`
  background: transparent;
  border: none;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  margin: 0 auto;
`

const FullButton = styled(Button)`
  width: 100%;
`

const FullInput = styled(Input)`
  width: 100%;
`

interface UpdatePasswordProp {
  currentPassword: string
  newPassword: string
}

const PasswordSetting = () => {
  const { currentUser } = useAuthProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<'edit' | 'done'>('edit')
  const [pageTitle, setPageTitle] = useState<
    'パスワード変更' | 'パスワード変更完了'
  >('パスワード変更')
  const [visibleReturn, setVisibleReturn] = useState(true)

  const schema = yup.object({
    currentPassword: yup.string().required(ErrorMsg.REQUIRED),
    newPassword: yup.string(),
    rePassword: yup
      .string()
      .when('newPassword', (pw, field) =>
        pw
          ? field
              .required(ErrorMsg.REQUIRED)
              .oneOf([yup.ref('newPassword')], ErrorMsg.DIFFERENT_PASSWORD)
          : field
      ),
  })

  const form = useForm<UpdatePasswordProp & { rePassword: string }>({
    resolver: yupResolver(schema),
  })
  const handleSubmit = useCallback(
    async (data: UpdatePasswordProp) => {
      if (!currentUser) {
        return
      }

      try {
        setLoading(true)
        const credential = getCredential(
          currentUser?.email ?? '',
          data.currentPassword
        )

        await reauth(currentUser, credential).catch((e) => {
          if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
            form.setError('currentPassword', {
              type: 'custom',
              message: ErrorMsg.FAILURE_REAUTH,
            })
            form.setValue('currentPassword', '')
          }
          throw e
        })

        if (data.newPassword) {
          await updateAccountPassword(currentUser, data.newPassword)
        }

        toCompleted()
      } catch (e) {
        if (e instanceof FirebaseError) {
          log.error(e)
        }
      }

      form.setValue('currentPassword', '')
      form.setValue('newPassword', '')
      form.setValue('rePassword', '')
      setLoading(false)
    },
    [currentUser, form]
  )

  const toCompleted = () => {
    setStatus('done')
    setPageTitle('パスワード変更完了')
    setVisibleReturn(false)
  }

  return (
    <Page
      title="パスワード変更"
      loading={loading}
      customHeader={
        <NoLogoHeader title={pageTitle} visibleReturn={visibleReturn} />
      }
    >
      {status === 'edit' && (
        <Panel>
          <Block>
            <Text>現在のパスワードと新しいパスワードを入力してください。</Text>
          </Block>
          <Block>
            <Form form={form} onSubmit={handleSubmit}>
              <Block>
                <FormControl label="現在のパスワード" name="currentPassword">
                  <FullInput
                    type="password"
                    placeholder="●●●●●●●●●"
                    aria-label="現在のパスワード"
                  />
                </FormControl>
                <FormControl label="新しいパスワード" name="newPassword">
                  <FullInput
                    type="password"
                    placeholder="●●●●●●●●●"
                    aria-label="新しいパスワード"
                  />
                </FormControl>
                <FormControl label="新しいパスワード（確認）" name="rePassword">
                  <FullInput
                    type="password"
                    placeholder="●●●●●●●●●"
                    aria-label="新しいパスワード（確認）"
                  />
                </FormControl>
              </Block>
              <ButtonWrapper>
                <FullButton>変更する</FullButton>
              </ButtonWrapper>
            </Form>
          </Block>
        </Panel>
      )}
      {status === 'done' && (
        <Panel>
          <Block>
            <Text>パスワードの変更が完了しました。</Text>
            <ButtonWrapper>
              <FullButton as={Link} to={ROUTING_PATH.B0201}>
                物件一覧画面へ
              </FullButton>
            </ButtonWrapper>
          </Block>
        </Panel>
      )}
    </Page>
  )
}

export default PasswordSetting
