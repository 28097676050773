import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface MoreLinkProps {
  to: string
  align?: 'left' | 'center' | 'right'
}

const Container = styled(Link)<MoreLinkProps>`
  display: block;
  margin-top: 10px;
  margin-left: auto;
  color: ${(p) => p.theme.FONT_COLOR};
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  text-align: ${(p) => (p.align ? p.align : 'left')};
  text-decoration: none;
  text-align: right;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    font-size: 12px;
  }

  img {
    margin-left: 4px;
  }
`

const MoreLink = (props: MoreLinkProps) => {
  return (
    <Container to={props.to}>
      MORE
      <img src="/image/arrow-right-navy.svg" alt="more" />
    </Container>
  )
}

export default MoreLink
