import styled from 'styled-components'
import BaseToggle from 'react-toggle'

import 'react-toggle/style.css'
import { ChangeEvent } from 'react'

const Wrapper = styled.div`
  // on時
  .react-toggle--checked .react-toggle-track {
    background-color: ${(p) => p.theme.MAIN};
  }

  // off時
  .react-toggle-track {
    background-color: #b8b8b8;
  }

  // offのhover時
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #b8b8b8;
  }

  // onのhover時
  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: ${(p) => p.theme.MAIN};
  }

  .react-toggle--checked .react-toggle-thumb {
    border: none;
  }
`

const Toggle = (props: {
  defaultChecked: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Wrapper>
      <BaseToggle
        checked={props.defaultChecked}
        icons={false}
        onChange={props.onChange}
      />
    </Wrapper>
  )
}

export default Toggle
