import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import userApi from 'apis/user'
import { AsyncTaskStatus } from 'schema/common'
import { RootState } from 'stores'
import { getTypePrefix } from 'utils'

export const USER_STORE_NAME = 'user'

export const verifyEmail = createAsyncThunk<{}>(
  'verifyEmail',
  async (_, { rejectWithValue }) => {
    try {
      return await userApi.verifyEmail()
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

interface UserState {
  status: Record<string, AsyncTaskStatus>
}

export const initialState: UserState = {
  status: {
    [verifyEmail.typePrefix]: 'idle',
  },
}

export const userSlice = createSlice({
  name: USER_STORE_NAME,
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.fulfilled, () => {})
      .addMatcher(isPending, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'loading'
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'idle'
      })
      .addMatcher(isRejected, (state, action) => {
        state.status[getTypePrefix(action.type)] = 'failed'
        throw action.payload
      })
  },
})

export const { resetStore } = userSlice.actions

export const selectVerifyEmailLoading = (state: RootState) =>
  state.contract.status[verifyEmail.typePrefix] === 'loading'

export default userSlice.reducer
