import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface TextLinkProps {
  align?: 'left' | 'center' | 'right'
}

const TextLink = styled(Link)<TextLinkProps>`
  display: block;
  color: ${(p) => p.theme.LINK};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: ${(p) => (p.align ? p.align : 'left')};
`

export default TextLink
