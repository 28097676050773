import { yupResolver } from '@hookform/resolvers/yup'
import log from 'loglevel'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import {
  singinForOwnerWithAuthenticationFederation,
  AuthErrorCodes,
  deleteUnauthorizedUser,
  signout,
} from 'commons/firebase-auth'
import Page from 'commons/page'
import Button from 'components/button'
import { Form, FormControl } from 'components/form'
import { Input } from 'components/input-control'
import Block from 'components/block'
import { ButtonWrapper } from 'components/layout'
import { PageTitle } from 'components/typography'
import { Text } from 'components/typography'
import { JWT_TOKEN_KEY_NAME } from 'constants/common'
import { ErrorMsg } from 'constants/message'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { UserRole, isAuthenticationFederationProviderId } from 'schema/user'
import styled from 'styled-components'
import { useAppDispatch } from 'hooks'
import { setErrors } from 'stores/error'
import { FacebookLogo } from '../../components/logo/facebook-logo'
import { GoogleLogo } from '../../components/logo/google-logo'
import { TwitterLogo } from '../../components/logo/twitter-logo'
import AuthApi from 'apis/auth'

// 区切り線
const Separator = styled.hr`
  line-height: 1em;
  width: 100%;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  :before {
    content: '';
    background: #b6b6b6;
    position: absolute;
    left: 0;
    top: 30%;
    width: 100%;
    height: 1px;
  }
  :after {
    content: attr(data-content);
    position: relative;
    color: #818078;
    background-color: #fcfcfa;
  }
`

interface LoginProp {
  email: string
}

const OwnerLogin = () => {
  const { currentUser, claims } = useAuthProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const schema = yup.object({
    email: yup
      .string()
      .required(ErrorMsg.REQUIRED)
      .email(ErrorMsg.INVALID_EMAIL),
  })

  const form = useForm<LoginProp>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (currentUser && claims && claims?.role === UserRole.OWNER) {
      navigate(ROUTING_PATH.B0201)
    }
  }, [claims, currentUser, navigate])

  const handleSubmit = useCallback(
    async (data: LoginProp) => {
      try {
        setLoading(true)
        await AuthApi.sendSignInLink(data.email)
          .then(() => {
            window.localStorage.setItem('emailForSignIn', data.email)
            navigate(ROUTING_PATH.OwnerSignInLink)
          })
          .catch((e) => {
            switch (e.code) {
              case AuthErrorCodes.USER_DELETED:
                form.setError('email', {
                  type: 'custom',
                  message: ErrorMsg.NOT_FOUND_EMAIL,
                })
                break
              default:
                throw e
            }
          })
      } catch (e: any) {
        log.error(e)
      }

      setLoading(false)
    },
    [form, navigate]
  )

  const ssoLogin = async (providerId: string) => {
    try {
      if (isAuthenticationFederationProviderId(providerId)) {
        await singinForOwnerWithAuthenticationFederation(providerId)
        navigate(ROUTING_PATH.B0201)
      } else {
        throw new Error('Invalid provider id')
      }
    } catch (e: any) {
      log.error(e)
      if (e.code === AuthErrorCodes.UNAUTHORIZED_USER) {
        deleteUnauthorizedUser().catch((error) => {
          console.log(error)
        })
        signout().then(() => {
          localStorage.removeItem(JWT_TOKEN_KEY_NAME)
          dispatch(
            setErrors({ login: { message: ErrorMsg.UNAUTHORIZED_USER_ERROR } })
          )
          navigate(ROUTING_PATH.B0101)
        })
      }
    }
  }

  return (
    <Page title="ログイン" loading={loading} type="guest">
      <PageTitle>売主用ログインページ</PageTitle>
      <Block style={{ marginBottom: '10px' }}>
        <Form form={form} onSubmit={handleSubmit}>
          <FormControl label="Eメールアドレス" name="email">
            <Input
              type="email"
              placeholder="abc@example.com"
              maxLength={200}
              aria-label="Eメールアドレス"
            />
          </FormControl>
          <ButtonWrapper>
            <Button>ログインURLを送信する</Button>
          </ButtonWrapper>
        </Form>
      </Block>
      <Separator data-content="または" />
      <Block>
        <GoogleLogo
          labelText={'Googleでログイン'}
          onClick={async () => {
            await ssoLogin('google.com')
          }}
        />
        <FacebookLogo
          labelText={'Facebookでログイン'}
          onClick={async () => {
            await ssoLogin('facebook.com')
          }}
        />
        <TwitterLogo
          labelText={'Twitterでログイン'}
          onClick={async () => {
            await ssoLogin('twitter.com')
          }}
        />
      </Block>
      <Block>
        <Text size="sm">
          こちらは、Terassに所属するエージェントと媒介契約を結んだ売主様向けのログインページです。
        </Text>
        <Text size="sm">
          ログインには担当エージェントにお伝えしたメールアドレスをご利用ください。
        </Text>
        <Text size="sm">
          問い合わせは、お手数をおかけしますが、担当エージェントにお伝えください。
        </Text>
      </Block>
    </Page>
  )
}

export default OwnerLogin
