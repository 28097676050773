import log from 'loglevel'
import Page from 'commons/page'
import {
  AuthenticationFederationProvider,
  fetchAuthenticationFederationProvider,
  linkAuthenticationFederationProvider,
  unlinkAuthenticationFederationProvider,
} from 'commons/firebase-auth'
import Block from 'components/block'
import NoLogoHeader from '../../components/no-logo-header'
import BasePanel from '../../components/panel'
import styled from 'styled-components'
import { ReactNode, useEffect, useState } from 'react'
import { AuthenticationFederationProviderId } from '../../schema/user'
import { AuthErrorCodes } from 'firebase/auth'
import { FirebaseError } from '@firebase/util'

import { TwitterLogo } from '../../components/logo/twitter-logo'
import { FacebookLogo } from '../../components/logo/facebook-logo'
import { GoogleLogo } from '../../components/logo/google-logo'

import { BsFillCheckCircleFill } from 'react-icons/bs'
import BaseDialog from '../../components/dialog'
import Button from '../../components/button'
import { StrongText } from '../../components/typography'

const Panel = styled(BasePanel)`
  background: transparent;
  border: none;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  margin: 0 auto;
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    margin: 0;
    padding: 0;
  }
`

const StatusArea = styled.div`
  display: flex;
  cursor: pointer;
`

const CheckIcon = styled(BsFillCheckCircleFill)`
  margin-top: 20px;
  color: #44c763;
`

const StatusLabel = styled.div`
  font-size: 14px;
  margin-left: 25px;
  font-weight: bold;
  position: absolute;
  text-decoration: underline;
  line-height: 60px;
`

const ErrorMessage = styled(StrongText)`
  color: red;
`

const LinkedAccountMail = styled.div`
  font-size: 12px;
  padding-left: 10px;
  padding-bottom: 5px;
`

type FederationTarget = {
  componentKey: string
  providerName: string
  authenticationProviderId: AuthenticationFederationProviderId
  enabled: boolean
  logo?: ReactNode
  linkedAccountMail?: string | null
}

const federationTarget: FederationTarget[] = [
  {
    componentKey: 'google',
    providerName: 'Google',
    authenticationProviderId: 'google.com',
    enabled: false,
    logo: <GoogleLogo labelText={'Google'} />,
  },
  {
    componentKey: 'facebook',
    providerName: 'Facebook',
    authenticationProviderId: 'facebook.com',
    enabled: false,
    logo: <FacebookLogo labelText={'Facebook'} />,
  },
  {
    componentKey: 'twitter',
    providerName: 'Twitter',
    authenticationProviderId: 'twitter.com',
    enabled: false,
    logo: <TwitterLogo labelText={'Twitter'} />,
  },
]

const Row = styled.div`
  display: flex;
  white-space: nowrap;
`

const LogoArea = styled.div`
  width: 80%;
`
const ButtonArea = styled.div`
  gap: 20px;
  padding-top: 0px;
  display: flex;
`

const CloseButton = styled(Button)`
  width: 100%;
  background-color: white;
  color: black;
`
const ExecuteButton = styled(Button)`
  width: 100%;
`

const AuthenticationSetting = () => {
  const [userSetting, setUserSetting] = useState<
    AuthenticationFederationProvider[] | null
  >()
  const [setting, setSetting] = useState<FederationTarget[]>([])

  useEffect(() => {
    ;(async () => {
      const result = fetchAuthenticationFederationProvider()
      setUserSetting(result)
    })()
  }, [])

  useEffect(() => {
    if (!userSetting) {
      return
    }

    setSetting(
      federationTarget.map((item) => {
        const foundSetting = userSetting.find(
          (enabledItem) =>
            enabledItem.providerId === item.authenticationProviderId
        )

        return {
          ...item,
          enabled: !!foundSetting,
          linkedAccountMail: foundSetting?.linkedAccountMail,
        }
      })
    )
  }, [userSetting])

  const linkedUpdateSetting = (provider: AuthenticationFederationProvider) => {
    const updatedSetting = setting.map((item) => {
      if (item.authenticationProviderId === provider.providerId) {
        return {
          ...item,
          enabled: true,
          linkedAccountMail: provider.linkedAccountMail,
        }
      }
      return item
    })
    setSetting(updatedSetting)
  }

  const unLinkedUpdateSetting = (
    providerId: AuthenticationFederationProviderId
  ) => {
    const updatedSetting = setting.map((item) => {
      if (item.authenticationProviderId === providerId) {
        return { ...item, enabled: false, linkedAccountMail: null }
      }
      return item
    })
    setSetting(updatedSetting)
  }

  const linkSocialAccount = async (
    key: string,
    close: () => void,
    showError: () => void
  ) => {
    const providerId = key as AuthenticationFederationProviderId
    try {
      const linkedResult = await linkAuthenticationFederationProvider(
        providerId
      )

      linkedUpdateSetting(linkedResult)
      close()
    } catch (e: unknown) {
      log.error(e)
      if (e instanceof FirebaseError) {
        if (
          (e as FirebaseError).code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE
        ) {
          showError()
        }
      }
    }
  }
  const unLinkSocialAccount = async (
    key: string,
    close: () => void,
    showError: () => void
  ) => {
    const providerId = key as AuthenticationFederationProviderId

    try {
      await unlinkAuthenticationFederationProvider(providerId)
      unLinkedUpdateSetting(providerId)
      close()
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        log.error(e)
        if ((e as FirebaseError).code === AuthErrorCodes.NO_SUCH_PROVIDER) {
          showError()
        }
      }
    }
  }

  const ConfirmDialogBody = (props: {
    body: ReactNode
    executeLabel: string
    onClose: () => void
    onExecute: () => Promise<void>
  }) => {
    return (
      <>
        <Block>
          <StrongText size={'md'}>{props.body}</StrongText>
        </Block>
        <Block>
          <ButtonArea>
            <CloseButton onClick={props.onClose}>
              <span>閉じる</span>
            </CloseButton>
            <ExecuteButton onClick={props.onExecute}>
              <span>{props.executeLabel}</span>
            </ExecuteButton>
          </ButtonArea>
        </Block>
      </>
    )
  }

  const ErrorDialogBody = (props: { body: ReactNode; onClose: () => void }) => {
    return (
      <>
        <Block>{props.body}</Block>
        <Block>
          <ButtonArea>
            <CloseButton onClick={props.onClose}>
              <span>閉じる</span>
            </CloseButton>
          </ButtonArea>
        </Block>
      </>
    )
  }

  //設定/解除Area
  const SettingArea = ({
    federationTarget,
  }: {
    federationTarget: FederationTarget
  }) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const [showError, setShowError] = useState(false)
    const onClickLink = async () => {
      // Dialog
      setShowConfirm(true)
    }

    const onClickUnLink = async () => {
      setShowConfirm(true)
    }

    const onConfirmClose = () => {
      setShowConfirm(false)
    }

    const onErrorClose = () => {
      setShowError(false)
      setShowConfirm(false)
    }

    const ConfirmDialog = (props: {
      children: ReactNode
      show: boolean
      onClose: () => void
    }) => {
      return (
        <BaseDialog
          show={props.show}
          onClose={props.onClose}
          styleOptions={{
            height: 130,
            position: 'top',
          }}
        >
          {props.children}
        </BaseDialog>
      )
    }

    const showErrorDialog = () => {
      setShowError(true)
    }

    const ErrorDialog = (props: {
      children: ReactNode
      show: boolean
      onClose: () => void
    }) => {
      return (
        <BaseDialog
          show={props.show}
          onClose={props.onClose}
          styleOptions={{
            height: 190,
            position: 'top',
          }}
        >
          {props.children}
        </BaseDialog>
      )
    }

    if (federationTarget.enabled) {
      return (
        <>
          <StatusArea onClick={() => onClickUnLink()}>
            <CheckIcon size={20} />
            <StatusLabel>解除する</StatusLabel>
          </StatusArea>
          <ConfirmDialog show={showConfirm} onClose={onConfirmClose}>
            <ConfirmDialogBody
              body={`${federationTarget.providerName}ログインを解除しますか？`}
              executeLabel={'解除'}
              onClose={onConfirmClose}
              onExecute={() =>
                unLinkSocialAccount(
                  federationTarget.authenticationProviderId,
                  onConfirmClose,
                  showErrorDialog
                )
              }
            />
          </ConfirmDialog>
          <ErrorDialog show={showError} onClose={onErrorClose}>
            <ErrorDialogBody
              body={
                <ErrorMessage size={'md'}>
                  予期せぬエラーが発生したためアカウントを設定できませんでした。
                  <br />
                  <br />
                  改善されない場合は担当エージェントにご連絡ください。
                </ErrorMessage>
              }
              onClose={onErrorClose}
            />
          </ErrorDialog>
        </>
      )
    }

    return (
      <>
        <StatusArea onClick={() => onClickLink()}>
          <StatusLabel>設定する</StatusLabel>
        </StatusArea>
        <ConfirmDialog show={showConfirm} onClose={onConfirmClose}>
          <ConfirmDialogBody
            body={`${federationTarget.providerName}ログインを設定しますか？`}
            executeLabel={'設定'}
            onClose={onConfirmClose}
            onExecute={() =>
              linkSocialAccount(
                federationTarget.authenticationProviderId,
                onConfirmClose,
                showErrorDialog
              )
            }
          />
        </ConfirmDialog>
        <ErrorDialog show={showError} onClose={onErrorClose}>
          <ErrorDialogBody
            body={
              <ErrorMessage size={'md'}>
                このアカウントは他のアカウントに紐づけられています。
                <br />
                <br />
                以前の設定を解除してから実施してください。
              </ErrorMessage>
            }
            onClose={onErrorClose}
          />
        </ErrorDialog>
      </>
    )
  }

  const SettingRow = ({ item }: { item: FederationTarget }) => {
    return (
      <>
        <Row key={`row-${item.authenticationProviderId}`}>
          <LogoArea>{item.logo}</LogoArea>
          <SettingArea federationTarget={item} />
        </Row>
        {item.linkedAccountMail && (
          <LinkedAccountMail>
            設定したアカウント: {item.linkedAccountMail}
          </LinkedAccountMail>
        )}
      </>
    )
  }
  return (
    <Page
      title="ログイン設定"
      customHeader={<NoLogoHeader title={'ログイン設定'} />}
    >
      <Panel>
        <Block>
          {setting.map((item) => (
            <SettingRow
              key={`setting-row-${item.authenticationProviderId}`}
              item={item}
            />
          ))}
        </Block>
      </Panel>
    </Page>
  )
}

export default AuthenticationSetting
