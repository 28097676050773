import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { LineGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  GRAPH_PLUGINS,
  LINE_GRAPH_OPTIONS,
} from '../../../pages/contract/constants/graph'
import {
  createGraphLabel,
  createTransitionRateData,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'
import { formatedDate } from 'utils'
import HelpTips from '../../../components/help-tip'

const SuumoListToDetailGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const pcData = useMemo(() => {
    if (!contract?.suumo?.listPvForPC || !contract?.suumo?.detailPvForPC) {
      return []
    }

    return createTransitionRateData(
      contract?.suumo?.listPvForPC,
      contract?.suumo?.detailPvForPC,
      breakpoint
    )
  }, [breakpoint, contract?.suumo?.listPvForPC, contract?.suumo?.detailPvForPC])

  const smartPhoneData = useMemo(() => {
    if (
      !contract?.suumo?.listPvForSmartphone ||
      !contract?.suumo?.detailPvForSmartphone
    ) {
      return []
    }

    return createTransitionRateData(
      contract?.suumo?.listPvForSmartphone,
      contract?.suumo?.detailPvForSmartphone,
      breakpoint
    )
  }, [
    breakpoint,
    contract?.suumo?.listPvForSmartphone,
    contract?.suumo?.detailPvForSmartphone,
  ])

  const totalData = useMemo(() => {
    if (!contract?.suumo?.listPvForPC || !contract?.suumo?.detailPvForPC) {
      return []
    }

    if (
      !contract?.suumo?.listPvForSmartphone ||
      !contract?.suumo?.detailPvForSmartphone
    ) {
      return []
    }

    const listPv = contract?.suumo?.listPvForPC
      .map((data) => {
        return {
          ...data,
          dateName: formatedDate(data.date),
        }
      })
      .map((pcData) => {
        const detail = contract?.suumo?.listPvForSmartphone?.find((spData) => {
          return pcData.dateName === formatedDate(spData.date)
        })

        return {
          ...pcData,
          quantity: (pcData.quantity += detail?.quantity || 0),
        }
      })

    const detailPv = contract?.suumo?.detailPvForPC
      .map((data) => {
        return {
          ...data,
          dateName: formatedDate(data.date),
        }
      })
      .map((pcData) => {
        const detail = contract?.suumo?.detailPvForSmartphone?.find(
          (spData) => {
            return pcData.dateName === formatedDate(spData.date)
          }
        )

        return {
          ...pcData,
          quantity: (pcData.quantity += detail?.quantity || 0),
        }
      })

    return createTransitionRateData(listPv, detailPv, breakpoint)
  }, [
    contract?.suumo?.listPvForPC,
    contract?.suumo?.detailPvForPC,
    contract?.suumo?.listPvForSmartphone,
    contract?.suumo?.detailPvForSmartphone,
    breakpoint,
  ])

  const areaAverageData = useMemo(() => {
    if (
      !contract?.suumo?.areaAverageListPv ||
      !contract?.suumo?.areaAverageDetailPv
    ) {
      return []
    }

    return createTransitionRateData(
      contract?.suumo?.areaAverageListPv,
      contract?.suumo?.areaAverageDetailPv,
      breakpoint
    )
  }, [
    contract?.suumo?.areaAverageListPv,
    contract?.suumo?.areaAverageDetailPv,
    breakpoint,
  ])

  const detailPvForPCSum = useMemo(() => {
    return total30Days(contract?.suumo?.detailPvForPC || [])
  }, [contract?.suumo?.detailPvForPC])

  const listPvForPCSum = useMemo(() => {
    return total30Days(contract?.suumo?.listPvForPC || [])
  }, [contract?.suumo?.listPvForPC])

  const detailPvForSmartphoneSum = useMemo(() => {
    return total30Days(contract?.suumo?.detailPvForSmartphone || [])
  }, [contract?.suumo?.detailPvForSmartphone])

  const listPvForSmartphoneSum = useMemo(() => {
    return total30Days(contract?.suumo?.listPvForSmartphone || [])
  }, [contract?.suumo?.listPvForSmartphone])

  const areaAverageDetailPvSum = useMemo(() => {
    return total30Days(contract?.suumo?.areaAverageDetailPv || [])
  }, [contract?.suumo?.areaAverageDetailPv])

  const areaAverageListPvSum = useMemo(() => {
    return total30Days(contract?.suumo?.areaAverageListPv || [])
  }, [contract?.suumo?.areaAverageListPv])

  const totalRate = useMemo(() => {
    if (!listPvForPCSum && !listPvForSmartphoneSum) {
      return 0
    }

    return (
      ((detailPvForPCSum + detailPvForSmartphoneSum) /
        (listPvForPCSum + listPvForSmartphoneSum)) *
      100
    )
  }, [
    detailPvForPCSum,
    detailPvForSmartphoneSum,
    listPvForPCSum,
    listPvForSmartphoneSum,
  ])

  const pcRate = useMemo(() => {
    if (!listPvForPCSum) {
      return 0
    }

    return Math.round((detailPvForPCSum / listPvForPCSum) * 10000) / 100
  }, [detailPvForPCSum, listPvForPCSum])

  const smartphoneRate = useMemo(() => {
    if (!listPvForSmartphoneSum) {
      return 0
    }

    return (
      Math.round((detailPvForSmartphoneSum / listPvForSmartphoneSum) * 10000) /
      100
    )
  }, [detailPvForSmartphoneSum, listPvForSmartphoneSum])

  const areaAverageSum = useMemo(() => {
    if (!areaAverageListPvSum) {
      return 0
    }

    return (
      Math.round((areaAverageDetailPvSum / areaAverageListPvSum) * 10000) / 100
    )
  }, [areaAverageDetailPvSum, areaAverageListPvSum])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: `エリア平均`,
        data: areaAverageData,
        fill: false,
        backgroundColor: theme.BACKGROUND_SURFACE,
        borderColor: theme.GRAPH_COLOR_12,
        borderWidth: 1,
        pointStyle: 'rect',
      },
      {
        label: `PC`,
        data: pcData,
        fill: false,
        backgroundColor: theme.BACKGROUND_SURFACE,
        borderColor: theme.GRAPH_COLOR_03,
        borderWidth: 1,
        pointStyle: 'rect',
      },
      {
        label: `スマホ`,
        data: smartPhoneData,
        fill: false,
        backgroundColor: theme.BACKGROUND_SURFACE,
        borderColor: theme.GRAPH_COLOR_05,
        borderWidth: 1,
        pointStyle: 'rect',
      },
      {
        label: `全体`,
        data: totalData,
        fill: false,
        backgroundColor: theme.BACKGROUND_SURFACE,
        borderColor: theme.GRAPH_COLOR_11,
        borderWidth: 1,
        pointStyle: 'rect',
      },
    ],
  }

  return (
    <>
      <LineGraph
        data={data}
        options={{
          ...LINE_GRAPH_OPTIONS,
          scales: {
            ...LINE_GRAPH_OPTIONS.scales,
            y: {
              min: 0,
              suggestedMax: 20,
            },
          },
        }}
        plugins={GRAPH_PLUGINS('%')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日の遷移率',
            value: totalRate,
            unit: '%',
            detail: {
              list: [
                {
                  label: 'PC',
                  value: pcRate,
                  unit: '%',
                },
                {
                  label: 'スマホ',
                  value: smartphoneRate,
                  unit: '%',
                },
              ],
            },
          },
          {
            label: '直近30日のエリア平均遷移率',
            value: areaAverageSum,
            unit: '%',
            helpTip: <HelpTips text="同市区の掲載物件の平均です" />,
          },
        ]}
      />
    </>
  )
}

export default SuumoListToDetailGraph
