import styled from 'styled-components'
import {
  UseFormReturn,
  SubmitHandler,
  FieldValues,
  FormProvider,
} from 'react-hook-form'
import React from 'react'

const FormWrapper = styled.form.attrs({
  role: 'form',
})``

export interface FormProps<T extends FieldValues> {
  children?: React.ReactNode
  form: UseFormReturn<T>
  onSubmit?: SubmitHandler<T>
  'aria-label'?: string
}

const Form = <T extends FieldValues>({
  children,
  form,
  onSubmit,
  ...restProps
}: FormProps<T>) => {
  const { handleSubmit } = form

  return (
    <FormWrapper
      onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
      {...restProps}
    >
      <FormProvider {...form}>{children}</FormProvider>
    </FormWrapper>
  )
}

export default Form
