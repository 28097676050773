import styled from 'styled-components'
import { convertPrice } from '../../utils'

interface MapPopupProps {
  buildingName: string
  salesPrice: number
  // 専有面積
  unitSize: number
  // 間取り
  floorPlan: string
  // 階数
  story: number
  // 売出時期
  salesDateYM: string
  // 築年月
  builtDateYM: string
}

const Bold = styled.div`
  font-weight: 700;
`

const BuildingName = styled(Bold)`
  font-size: 12px;
  font-weight: 700;
`
const SalesPrice = styled(Bold)`
  font-size: 12px;
  font-weight: 700;
`
const BuildingDetailArea = styled.div`
  margin-top: 7px;
`
const BuildingDetail = styled.div``

export const MapMakerPopup = ({ item }: { item: MapPopupProps }) => {
  const formatYM = (ym: string) => {
    // yyyy-mm -> yyyy年/mm
    return `${ym.slice(0, 4)}/${ym.slice(5, 7)}`
  }
  return (
    <>
      <BuildingName>{item.buildingName}</BuildingName>
      <SalesPrice>{convertPrice(item.salesPrice)}</SalesPrice>
      <BuildingDetailArea>
        <BuildingDetail>
          {item.unitSize}m2、{item.floorPlan}、{item.story}階
        </BuildingDetail>
        <BuildingDetail>売出時期：{formatYM(item.salesDateYM)}</BuildingDetail>
        <BuildingDetail>築年月：{formatYM(item.builtDateYM)}</BuildingDetail>
      </BuildingDetailArea>
    </>
  )
}
