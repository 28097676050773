import React, { useEffect, useLayoutEffect } from 'react'

import ErrorArea from 'components/error-area'
import Loading from 'components/loading'
import { useAppSelector } from 'hooks'
import { ErrorMessage } from 'schema/error'
import { selectErrors } from 'stores/error'
import Layout from 'templates/layout'
import { ViewMode } from 'schema/common'

interface PageProps {
  loading?: boolean
  title?: string
  children?: React.ReactNode
  type?: ViewMode
  infiniteScroll?: boolean
  customHeader?: React.ReactNode
}

const Page = ({
  loading,
  title,
  children,
  type = 'user',
  infiniteScroll = false,
  customHeader,
}: PageProps) => {
  const apiErrors = useAppSelector(selectErrors)

  const errors = Object.keys(apiErrors).reduce<Array<ErrorMessage>>(
    (result, key) => {
      result.push({
        key,
        message:
          apiErrors[key]?.['message'] || '予期せぬエラーが発生しました。',
        messageNode: apiErrors[key]?.['messageNode'],
      })

      return result
    },
    []
  )

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    document.title = `${title} | ${process.env['REACT_APP_SERVICE_NAME']}`
  }, [title])

  return (
    <Layout
      type={type}
      infiniteScroll={infiniteScroll}
      customHeader={customHeader}
    >
      {errors.length > 0 ? <ErrorArea messages={errors} /> : null}
      {loading && <Loading />}
      {children}
    </Layout>
  )
}

export default Page
