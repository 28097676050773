import firebaseApp from 'commons/firebase-config'
import ROUTING_PATH from 'constants/route'
import { FirebaseError } from '@firebase/util'
import {
  checkActionCode,
  confirmPasswordReset,
  EmailAuthCredential,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  User,
  updateEmail,
  updatePassword,
  verifyPasswordResetCode,
  applyActionCode,
  FacebookAuthProvider,
  TwitterAuthProvider,
  linkWithPopup,
  unlink,
  AuthErrorCodes as FirebaseAuthErrorCodes,
} from 'firebase/auth'

import {
  AuthenticationFederationProviderId,
  AuthenticationProviderId,
  FirebaseUser,
  isAuthenticationFederationProviderId,
  isAuthenticationProviderId,
} from 'schema/user'
import AuthApi from 'apis/auth'

export const auth = getAuth(firebaseApp)
export const AuthErrorCodes = {
  ...({
    UNAUTHORIZED_USER: 'custom/unauthorized-user',
  } as const),
  ...FirebaseAuthErrorCodes,
}

const providerFactory = (providerId: AuthenticationFederationProviderId) => {
  if (providerId === 'google.com') {
    return new GoogleAuthProvider()
  } else if (providerId === 'facebook.com') {
    return new FacebookAuthProvider()
  } else {
    return new TwitterAuthProvider()
  }
}

// メールアドレス/パスワードによるログイン
export async function singinForOwnerWithEmailAndPassword(
  email: string,
  password: string
) {
  return signInWithEmailAndPassword(auth, email, password)
}

// Authentication federation でログイン
export async function singinForOwnerWithAuthenticationFederation(
  authenticationProviderId: AuthenticationFederationProviderId
) {
  const provider = providerFactory(authenticationProviderId)
  provider.setCustomParameters({ hd: process.env['REACT_APP_OWNER_HD'] || '' })
  const userCredential = await signInWithPopup(auth, provider)
  const authMeta = await userCredential.user.getIdTokenResult()
  const providerId = fetchAuthenticationProvider(userCredential.user)

  if (authMeta?.claims?.['role'] == null) {
    if (!providerId.includes('password')) {
      throw new FirebaseError(AuthErrorCodes.UNAUTHORIZED_USER, '')
    }
  }

  return userCredential
}

// Auth情報に含まれる認証プロバイダを取得する。
export function fetchAuthenticationProvider(
  user?: User
): AuthenticationProviderId[] {
  const currentUser = user == null ? auth.currentUser ?? null : user
  if (currentUser == null) {
    return []
  }

  const result: AuthenticationProviderId[] = []
  for (const data of currentUser.providerData) {
    if (isAuthenticationProviderId(data.providerId)) {
      result.push(data.providerId)
    }
  }

  return result
}

// 紐づいているSNS認証プロバイダを取得する
export interface AuthenticationFederationProvider {
  providerId: AuthenticationFederationProviderId
  linkedAccountMail: string | null
}
export function fetchAuthenticationFederationProvider(): AuthenticationFederationProvider[] {
  const currentUser = auth.currentUser ?? null
  if (currentUser == null) {
    return []
  }

  const result: AuthenticationFederationProvider[] = []
  for (const data of currentUser.providerData) {
    if (isAuthenticationFederationProviderId(data.providerId)) {
      result.push({
        providerId: data.providerId,
        linkedAccountMail: data.email,
      })
    }
  }

  return result
}

// 認証プロバイダをアカウントに紐づける
export async function linkAuthenticationFederationProvider(
  authenticationFederationProviderId: AuthenticationFederationProviderId
): Promise<AuthenticationFederationProvider> {
  const currentUser = auth.currentUser ?? null
  if (currentUser == null) {
    throw new Error('認証プロバイダの紐づけに失敗しました')
  }
  const provider = providerFactory(authenticationFederationProviderId)
  provider.setCustomParameters({ hd: process.env['REACT_APP_OWNER_HD'] || '' })
  const userCredential = await linkWithPopup(currentUser, provider)
  console.log({ data: userCredential.user.providerData })
  if (userCredential.user.uid === currentUser.uid) {
    return {
      providerId: authenticationFederationProviderId,
      linkedAccountMail:
        userCredential.user.providerData.find(
          (value: { providerId: string }) =>
            value.providerId === authenticationFederationProviderId
        )?.email ?? null,
    }
  }

  throw new Error('認証プロバイダの紐づけに失敗しました')
}

// 認証プロバイダの紐づけを解除する
export async function unlinkAuthenticationFederationProvider(
  authenticationFederationProviderId: AuthenticationFederationProviderId
) {
  const currentUser = auth.currentUser ?? null
  if (currentUser == null) {
    return false
  }
  const user = await unlink(currentUser, authenticationFederationProviderId)
  if (user.uid === currentUser.uid) {
    return true
  }
  return false
}

// Google認証によるログイン
export async function singinForAgent() {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({ hd: process.env['REACT_APP_AGENT_HD'] || '' })
  return signInWithPopup(auth, provider)
}

// ログアウト
export async function signout() {
  return signOut(auth)
}

// 認証中ユーザー取得
export function initFirebaseAuth(callback: (user: FirebaseUser) => void) {
  return onAuthStateChanged(auth, (user: FirebaseUser) => callback(user))
}

// authのメタ情報を取得
export async function getAuthMeta(user: FirebaseUser) {
  return user?.getIdTokenResult(true)
}

// パスワードリセットのためのメール送信
export async function sendEmailForResetPassword(email: string) {
  const actionCodeSettings = {
    url: `${window.location.origin}${ROUTING_PATH.B0101}`,
  }

  return sendPasswordResetEmail(auth, email, actionCodeSettings)
}

// パスワードリセットコードの正当性確認
export async function verificationPasswordResetVerifyCode(actionCode: string) {
  return verifyPasswordResetCode(auth, actionCode)
}

// パスワードリセット
export async function resetPassword(actionCode: string, password: string) {
  return confirmPasswordReset(auth, actionCode, password)
}

// 認証アカウントのcredenatial情報取得
export function getCredential(email: string, password: string) {
  return EmailAuthProvider.credential(email, password)
}

// credential情報を用いて再認証を行う
export async function reauth(
  currentUser: User,
  credential: EmailAuthCredential
) {
  return reauthenticateWithCredential(currentUser, credential)
}

// 登録メールアドレス変更
export async function updateAccountEmail(user: User, email: string) {
  return updateEmail(user, email)
}

// 登録パスワード変更
export async function updateAccountPassword(user: User, password: string) {
  return updatePassword(user, password)
}

// メールアドレス変更通知
export async function sendEmailForVerification(user: User) {
  const actionCodeSettings = {
    url: `${window.location.origin}${ROUTING_PATH.B0101}`,
  }

  return sendEmailVerification(user, actionCodeSettings)
}

// アクションコードの正当性確認
export async function verificationActionCode(actionCode: string) {
  return checkActionCode(auth, actionCode)
}

// メールアドレス変更
export async function executeApply(actionCode: string) {
  return applyActionCode(auth, actionCode)
}

// アカウントの削除（ソーシャルログインで新規作成されてしまうものだけが対象）
export async function deleteUnauthorizedUser() {
  return AuthApi.deleteUser()
}
