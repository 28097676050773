import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { ReactNode } from 'react'
import getLayer from '../styles/layer'

const IconLabel = styled.a`
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    line-height: 15px;
  }
`
const TooltipText = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`

const BaseTooltip = styled(ReactTooltip)`
  border-radius: 4px;
  opacity: 0.9;
  padding: 5px;
  max-width: 90%;
  z-index: ${getLayer('tooltip')};
`
const Tooltip = (props: {
  text: string | ReactNode | undefined
  icon?: JSX.Element | undefined
}) => {
  const id = `tooltip-component-${uuidv4()}`

  return (
    <>
      <IconLabel data-tooltip-id={id}>{props.icon}</IconLabel>
      <BaseTooltip id={id}>
        <TooltipText>{props.text}</TooltipText>
      </BaseTooltip>
    </>
  )
}

export default Tooltip
