import styled from 'styled-components'

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > a + a,
  > div + div {
    margin-left: 20px;
  }

  > a:nth-child(3n) + a,
  > div:nth-child(3n) + div {
    margin-left: 0px;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    flex-direction: column;
    flex-wrap: nowrap;

    > a + a,
    > div + div {
      margin-left: auto;
    }

    > a:nth-child(3n) + a,
    > div:nth-child(3n) + div {
      margin-left: auto;
    }
  }
`

export default ListWrapper
