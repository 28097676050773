import { ChatMessage, ChatRoom } from 'schema/chat'

export const getUnreadMsgCount = (
  chatRoom: ChatRoom | undefined,
  userId: string | undefined
) => {
  if (!(chatRoom && chatRoom.messages)) {
    return 0
  }
  const messageIds =
    chatRoom && chatRoom.messages ? Object.keys(chatRoom.messages) : []

  let unreadCnt = messageIds.length
  if (chatRoom && chatRoom.messageReadState && userId) {
    const lastMessageId = chatRoom.messageReadState[userId]
    if (lastMessageId && messageIds.indexOf(lastMessageId) !== -1) {
      unreadCnt = unreadCnt - messageIds.indexOf(lastMessageId) - 1
    }
  }
  return unreadCnt
}

export const isReadLastMessage = (
  userId: string | undefined,
  chatRoom: ChatRoom | undefined,
  message: ChatMessage
) => {
  const partnerId = getPartnerId(chatRoom, userId) // 相手のID
  if (chatRoom?.messageReadState && partnerId) {
    if (!chatRoom.messageReadState[partnerId]) {
      return false
    }

    const lastMessageId = chatRoom.messageReadState[partnerId] // 相手が読んだ最終メッセージID
    return message.id === lastMessageId
  } else {
    return false
  }
}

export const getPartnerId = (chatRoom?: ChatRoom, userId?: string) => {
  if (!chatRoom || !userId) {
    return
  }

  if (chatRoom.users.ownerId === userId) {
    return chatRoom.users.agentId
  }
  return chatRoom.users.ownerId
}
