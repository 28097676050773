import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Page from 'commons/page'
import Block from 'components/block'
import { SectionTitle, Text } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import {
  fetchContractById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
  selectFeatureByIdFailed,
} from 'stores/contract'
import { ListWrapper as BaseListWrapper } from '../../components/layout'
import { ComparableSalesListItem } from '../../features/contracts/list/comparable-sales-list-item'
import styled from 'styled-components'
import { UserRole } from '../../schema/user'
import ContractApi from '../../apis/contract'
import Map from '../../components/map/map'
import { MapMakerPopup } from '../../components/map/map-maker-popup'
import { UnitInformation } from '../../schema/contract'
import SubPageHeader from '../../components/sub-page-header'

const ListWrapper = styled(BaseListWrapper)`
  margin-bottom: 30px;
`
const SimilarPropertiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  @media only screen and (min-width: ${(p) => p.theme.vp}px) {
    flex-direction: row-reverse;
  }
`

const MapArea = styled.div`
  width: 100%;
  height: 600px;
  @media only screen and (min-width: ${(p) => p.theme.vp}px) {
    width: calc((100%) / 3 * 2);
    padding-left: 10px;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 100%;
  }
`
const SimilarPropertiesListArea = styled.div`
  width: calc((100% - 40px) / 3);
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 100%;
  }
`

const SimilarPropertiesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`
const ComparableSales = () => {
  const { claims } = useAuthProvider()
  const isOwner = useMemo(() => claims?.role === UserRole.OWNER, [claims?.role])
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const featureFailed = useAppSelector(selectFeatureByIdFailed)
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    dispatch(fetchContractById({ role: claims?.role, id }))
  }, [dispatch, claims?.role, id])
  const reFetch = () => {
    dispatch(fetchContractById({ role: claims!.role!, id: id! }))
  }
  const data = useMemo(() => {
    return contract
  }, [contract])

  const HasApiError = useMemo(() => {
    return contractFailed || featureFailed
  }, [contractFailed, featureFailed])

  const assignNumbersUnitInformation = (units: UnitInformation[]) => {
    let currentItemNumber = 1
    return units.map((unit) => {
      const isVisible = unit.isVisible !== undefined ? unit.isVisible : true
      const isLocated = unit.lat && unit.lon
      const itemNumberLabel =
        isVisible && isLocated ? String(currentItemNumber++) : '-'
      return {
        ...unit,
        itemNumberLabel,
      }
    })
  }
  const { sameProperties, similarProperties, position } = useMemo(() => {
    if (!data) {
      return {
        sameProperties: [],
        similarProperties: [],
        position: undefined,
      }
    }
    const numberedSimilarProperties = assignNumbersUnitInformation(
      data.similarProperties ?? []
    )

    const position =
      data.position && data.position.lat && data.position.lon
        ? { lat: Number(data.position.lat), lon: Number(data.position.lon) }
        : undefined

    return {
      sameProperties: data.sameProperties ?? [],
      similarProperties: numberedSimilarProperties,
      position: position,
    }
  }, [data])

  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title={`類似の成約事例`}
          loading={contractLoading}
          type="user"
          customHeader={<SubPageHeader />}
        >
          {sameProperties.length !== 0 && (
            <Block>
              <SectionTitle>棟内の成約事例</SectionTitle>
              {!isOwner && (
                <Text size={'sm'}>
                  売主様の画面に表示するかを事例毎に設定できます。
                </Text>
              )}
              <ListWrapper>
                {sameProperties.map((item, index) => (
                  <ComparableSalesListItem
                    key={`same-properties-${index}`}
                    unitInformation={item}
                    isVisibleToggle={!isOwner}
                    onChangeToggle={async (e) => {
                      await ContractApi.updateSameComparableSalesVisibility(
                        id!,
                        index,
                        e.target.checked
                      )
                      reFetch()
                    }}
                  />
                ))}
              </ListWrapper>
            </Block>
          )}
          {similarProperties.length !== 0 && (
            <Block>
              <SectionTitle>周辺の成約事例</SectionTitle>
              {!isOwner && (
                <Text size={'sm'}>
                  売主様の画面に表示するかを事例毎に設定できます。
                </Text>
              )}
              <SimilarPropertiesWrapper>
                <MapArea>
                  <Map
                    position={
                      position ? [position.lat, position.lon] : undefined
                    }
                    markers={similarProperties
                      // 座標がないものは非表示
                      .filter((item) => item.lat && item.lon)
                      // 表示制御がOFFは非表示
                      .filter((item) => item.isVisible)
                      .map((item) => {
                        return {
                          markerItemNumberLabel: item.itemNumberLabel,
                          lat: Number(item.lat),
                          lon: Number(item.lon),
                          popup: (
                            <MapMakerPopup
                              item={{
                                buildingName: item.name,
                                salesPrice: item.salesPrice,
                                unitSize: item.unitSize,
                                floorPlan: item.floorPlan,
                                story: item.story,
                                salesDateYM: item.salesYM,
                                builtDateYM: item.builtDate,
                              }}
                            />
                          ),
                        }
                      })}
                  />
                  <Text size={'sm'}>
                    ※地図上に表示される物件の位置は付近住所に所在することを表すものであり、実際の物件所在地とは異なる場合がございます。
                    <br />
                    ※地図の更新タイミングの関係で、物件情報が実際のものとは異なる場合や最新情報に更新されていない場合がございます。
                  </Text>
                </MapArea>
                <SimilarPropertiesListArea>
                  <SimilarPropertiesListWrapper>
                    {similarProperties.map((item, index) => (
                      <ComparableSalesListItem
                        key={`similar-properties-${index}`}
                        unitInformation={item}
                        isVisibleToggle={!isOwner}
                        onChangeToggle={async (e) => {
                          await ContractApi.updateSimilarComparableSalesVisibility(
                            id!,
                            index,
                            e.target.checked
                          )
                          reFetch()
                        }}
                        isVertical={true}
                      />
                    ))}
                  </SimilarPropertiesListWrapper>
                </SimilarPropertiesListArea>
              </SimilarPropertiesWrapper>
            </Block>
          )}
        </Page>
      )}
    </>
  )
}

export default ComparableSales
