import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'

const SuumoHpClickGraph = ({ contract }: { contract?: Contract | Feature }) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const graphData = useMemo(() => {
    if (!contract?.suumo?.linkClickCount) {
      return []
    }

    return createBarGraphData(contract?.suumo?.linkClickCount, breakpoint)
  }, [breakpoint, contract?.suumo?.linkClickCount])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: `PC`,
        data: graphData,
        backgroundColor: theme.GRAPH_COLOR_03,
        borderWidth: 0,
      },
    ],
  }

  const sum = useMemo(() => {
    return total30Days(contract?.suumo?.linkClickCount || [])
  }, [contract?.suumo?.linkClickCount])

  return (
    <>
      <StackedBarGraph
        data={data}
        options={BAR_GRAPH_OPTIONS}
        plugins={GRAPH_PLUGINS('回数')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日のクリック数',
            value: sum,
            unit: '回',
          },
        ]}
      />
    </>
  )
}

export default SuumoHpClickGraph
