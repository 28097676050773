import { PropertyExampleListItem } from './property-example-list-item'
import { SuccessfullyConcludedDealItem } from '../../../schema/contract'

const SuccessfullyConcludedDealList = (props: {
  list: SuccessfullyConcludedDealItem[]
  isVertical?: boolean
}) => {
  return (
    <>
      {props.list.map((item, index) => {
        return (
          <PropertyExampleListItem
            key={`contract-${index}`}
            item={item}
            isVertical={props.isVertical}
          />
        )
      })}
    </>
  )
}

export default SuccessfullyConcludedDealList
