import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Page from 'commons/page'
import Block from 'components/block'
import { SectionTitle, Text } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch, useAppSelector } from 'hooks'
import AccessDenied from 'pages/access-denied'
import {
  fetchContractById,
  selectContractById,
  selectContractByIdFailed,
  selectContractByIdLoading,
  selectFeatureByIdFailed,
} from 'stores/contract'

import styled from 'styled-components'
import { UserRole } from '../../schema/user'
import Map from '../../components/map/map'
import { ActiveInventoryItem } from '../../schema/contract'
import SubPageHeader from '../../components/sub-page-header'
import { convertPrice } from '../../utils'
import ListFiledBuildingName from '../../features/contracts/list/list-filed-building-name'
import ActiveInventoryList from '../../features/contracts/list/active-inventory-list'

const ActiveInventoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  @media only screen and (min-width: ${(p) => p.theme.vp}px) {
    flex-direction: row-reverse;
  }
`

const MapArea = styled.div`
  width: 100%;
  height: 600px;
  @media only screen and (min-width: ${(p) => p.theme.vp}px) {
    width: calc((100%) / 3 * 2);
    padding-left: 10px;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 100%;
  }
`
const ActiveInventoriesListArea = styled.div`
  width: calc((100% - 40px) / 3);
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 100%;
  }
`

const ActiveInventoriesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  @media only screen and (min-width: ${(p) => p.theme.vp}px) {
    overflow: scroll;
    height: 75vh;
  }
`

const Bold = styled.div`
  font-weight: 700;
`
const SalesPrice = styled(Bold)`
  font-size: 12px;
  font-weight: 700;
`
const BuildingDetailArea = styled.div`
  margin-top: 7px;
`
const BuildingDetail = styled.div``
const ActiveInventories = () => {
  const { claims } = useAuthProvider()
  const isOwner = useMemo(() => claims?.role === UserRole.OWNER, [claims?.role])
  const contract = useAppSelector(selectContractById)
  const contractLoading = useAppSelector(selectContractByIdLoading)
  const contractFailed = useAppSelector(selectContractByIdFailed)
  const featureFailed = useAppSelector(selectFeatureByIdFailed)
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (!claims?.role) {
      return
    }

    if (!id) {
      return
    }

    dispatch(fetchContractById({ role: claims?.role, id }))
  }, [dispatch, claims?.role, id])
  const reFetch = () => {
    dispatch(fetchContractById({ role: claims!.role!, id: id! }))
  }
  const data = useMemo(() => {
    return contract
  }, [contract])

  const HasApiError = useMemo(() => {
    return contractFailed || featureFailed
  }, [contractFailed, featureFailed])

  const assignNumbers = (items: ActiveInventoryItem[]) => {
    let currentItemNumber = 1
    return items.map((item) => {
      const isVisible = item.isVisible !== undefined ? item.isVisible : true
      const isLocated = item.lat && item.lon
      const itemNumberLabel =
        isVisible && isLocated ? String(currentItemNumber++) : '-'
      return {
        ...item,
        itemNumberLabel,
      }
    })
  }
  const { activeInventories, position } = useMemo(() => {
    if (!data) {
      return {
        position: null,
        activeInventories: [],
      }
    }
    const numberedActiveInventories = assignNumbers(
      data.activeInventory?.items ?? []
    )

    return {
      position: {
        lat: Number(data.activeInventory?.config.lat) ?? null,
        lon: Number(data.activeInventory?.config.lon) ?? null,
      },
      activeInventories: numberedActiveInventories,
    }
  }, [data])

  const formatYM = (ym: string) => {
    // yyyy-mm -> yyyy年/mm
    return `${ym.slice(0, 4)}/${ym.slice(5, 7)}`
  }
  return (
    <>
      {HasApiError && <AccessDenied />}
      {!HasApiError && (
        <Page
          title={`周辺の売出物件`}
          loading={contractLoading}
          type="user"
          customHeader={<SubPageHeader />}
        >
          {activeInventories.length !== 0 && (
            <Block>
              <SectionTitle>周辺の売出物件</SectionTitle>
              {!isOwner && (
                <Text size={'sm'}>
                  売主様の画面に表示するかを物件毎に設定できます。
                </Text>
              )}
              <ActiveInventoriesWrapper>
                <MapArea>
                  <Map
                    position={
                      position ? [position.lat, position.lon] : undefined
                    }
                    markers={activeInventories
                      // 座標がないものは非表示
                      .filter((item) => item.lat && item.lon)
                      // 表示制御がOFFは非表示
                      .filter((item) => item.isVisible)
                      .map((item) => {
                        return {
                          markerItemNumberLabel: item.itemNumberLabel,
                          lat: Number(item.lat),
                          lon: Number(item.lon),
                          popup: (
                            <>
                              <ListFiledBuildingName
                                name={item.name}
                                url={item.url}
                              />
                              <SalesPrice>
                                {convertPrice(item.salesPrice)}
                              </SalesPrice>
                              <BuildingDetailArea>
                                <BuildingDetail>
                                  {item.unitSize}m2、{item.floorPlan}、
                                  {item.story}階
                                </BuildingDetail>
                                <BuildingDetail>{item.type}</BuildingDetail>
                                <BuildingDetail>
                                  築年月：{formatYM(item.builtDate)}
                                </BuildingDetail>
                              </BuildingDetailArea>
                            </>
                          ),
                        }
                      })}
                  />
                  <Text size={'sm'}>
                    ※地図上に表示される物件の位置は付近住所に所在することを表すものであり、実際の物件所在地とは異なる場合がございます。
                    <br />
                    ※地図の更新タイミングの関係で、物件情報が実際のものとは異なる場合や最新情報に更新されていない場合がございます。
                  </Text>
                </MapArea>
                <ActiveInventoriesListArea>
                  <ActiveInventoriesListWrapper>
                    <ActiveInventoryList
                      list={activeInventories}
                      contractId={id!}
                      isOwner={isOwner}
                      reFetch={reFetch}
                      isVertical={true}
                    />
                  </ActiveInventoriesListWrapper>
                </ActiveInventoriesListArea>
              </ActiveInventoriesWrapper>
            </Block>
          )}
        </Page>
      )}
    </>
  )
}

export default ActiveInventories
