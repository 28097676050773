import log from 'loglevel'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import App from './App'
import { AuthProvider } from 'contexts/auth'
import { LOCAL_API_URL } from 'constants/common'
import ErrorBoundary from 'commons/error-boundary'
import reportWebVitals from 'reportWebVitals'
import GlobalStyle from 'styles/global-style'
import theme from 'styles/theme'
import { store } from 'stores'
import { parseErrorInfo } from 'utils/error'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://8ca7e3b958e44ea497a77c65c75cd9ba@o351104.ingest.sentry.io/4504915200049152',
  integrations: [new Sentry.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
  environment: process.env['NODE_ENV'],
})

const prepare = () => {
  if (process.env['NODE_ENV'] === 'development') {
    log.setLevel(log.levels.DEBUG)
  } else {
    log.setLevel(log.levels.ERROR)
  }

  if (
    process.env['NODE_ENV'] === 'development' &&
    process.env['REACT_APP_API_URL'] === LOCAL_API_URL
  ) {
    return import('./__mock__/browser').then(({ worker }) => {
      worker.start()
    })
  }

  return Promise.resolve()
}

const container = document.getElementById('root')
const root = createRoot(container!)
prepare().then(() => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ErrorBoundary
            onError={(e) => {
              log.error(parseErrorInfo(e))
              Sentry.captureException(e)
            }}
          >
            <AuthProvider>
              <GlobalStyle />
              <App />
            </AuthProvider>
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  )
})

reportWebVitals()
