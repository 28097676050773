import { configureStore } from '@reduxjs/toolkit'

import constractSlice, { CONTRACT_STORE_NAME } from 'stores/contract'
import errorSlice, { ERROR_STORE_NAME } from 'stores/error'
import userSlice, { USER_STORE_NAME } from 'stores/user'
import chatSlice, { CHAT_STORE_NAME } from 'stores/chat'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    [CONTRACT_STORE_NAME]: constractSlice,
    [ERROR_STORE_NAME]: errorSlice,
    [USER_STORE_NAME]: userSlice,
    [CHAT_STORE_NAME]: chatSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
