import { useCallback } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import Loading from 'components/loading'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import NotVerification from 'pages/not-verification'
import { UserRole } from 'schema/user'
import AccessDenied from 'pages/access-denied'

interface Props {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: Props) => {
  const { signInCheck, currentUser, claims } = useAuthProvider()
  const { pathname } = useLocation()

  const getRedirectLink = useCallback((pathname: string) => {
    if (pathname.includes(UserRole.OWNER)) {
      return ROUTING_PATH.B0101
    } else {
      return ROUTING_PATH.C0101
    }
  }, [])

  if (!signInCheck) {
    return <Loading />
  } else {
    if (!currentUser) {
      const redirectURL = getRedirectLink(pathname)
      return <Navigate to={redirectURL} replace />
    }
    const availableWeb = claims?.availableWeb
    if (!availableWeb || `${availableWeb}` === 'false') {
      return <AccessDenied />
    }
    const role = claims?.role
    if (role === UserRole.OWNER && !currentUser?.emailVerified) {
      return <NotVerification />
    }

    if (role && pathname.includes(role)) {
      return children
    } else {
      const redirectURL = getRedirectLink(pathname)
      return <Navigate to={redirectURL} replace />
    }
  }
}
export default ProtectedRoute
