import request from 'apis/request'
const setting = (
  contractId: string,
  address: string,
  size: number,
  buildDate: string
) => {
  return request({
    method: 'post',
    url: `/agents/successfully-concluded-deal/setting`,
    data: {
      contractId,
      address,
      size,
      buildDate,
    },
  })
}

const clear = (contractId: string) => {
  return request({
    method: 'post',
    url: `/agents/successfully-concluded-deal/clear`,
    data: {
      contractId,
    },
  })
}

const SuccessfullyConcludedDealApi = {
  setting,
  clear,
}

export default SuccessfullyConcludedDealApi
