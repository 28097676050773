import styled from 'styled-components'
import { Link } from 'react-router-dom'

const BuildingNameLink = styled(Link)`
  color: #008df3;
  font-size: 14px;
  font-weight: 700;
`
const ListFiledBuildingName = ({
  name,
  url,
}: {
  name: string | null
  url: string
}) => {
  const displayName = !!name ? name : '不明'

  if (!url) {
    return <>{displayName}</>
  }

  return (
    <BuildingNameLink to={url} target={'_blank'}>
      {displayName}
    </BuildingNameLink>
  )
}

export default ListFiledBuildingName
