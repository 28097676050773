import { AuthErrorCodes } from 'firebase/auth'
import log from 'loglevel'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { executeApply } from 'commons/firebase-auth'
import Page from 'commons/page'
import Button from 'components/button'
import Block from 'components/block'
import { ButtonWrapper } from 'components/layout'
import { PageTitle, Text } from 'components/typography'
import { ERRORS, UPDATE_MAIL } from 'constants/common'
import ROUTING_PATH from 'constants/route'
import { useAppDispatch, useAppSelector } from 'hooks'
import { selectVerifyEmailLoading, verifyEmail } from 'stores/user'
import { clearErrors, setErrors } from 'stores/error'

interface PageProp {
  actionCode: string
  redirectUrl: string | null
}

const VerificationEmail = ({ actionCode, redirectUrl }: PageProp) => {
  const loading = useAppSelector(selectVerifyEmailLoading)
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    try {
      dispatch(clearErrors())
      executeApply(actionCode)
        .then(() => {
          dispatch(verifyEmail())
          localStorage.removeItem(UPDATE_MAIL)
        })
        .catch((e) => {
          log.error(e)
          if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
            dispatch(
              setErrors({ action: { message: ERRORS['E0204']?.message } })
            )
          }
        })
    } catch (e) {
      log.error(e)
    }
  }, [location.search, dispatch, actionCode])

  return (
    <Page title="メールアドレス認証完了" loading={loading} type="guest">
      <PageTitle>メールアドレス認証完了</PageTitle>
      <Block>
        <Text>メールアドレスの認証が完了しました。</Text>
        <Text>引き続きサービスをお使いください。</Text>
      </Block>
      <ButtonWrapper>
        <Button
          onClick={() => {
            window.location.href = redirectUrl || ROUTING_PATH.B0101
          }}
        >
          ログイン画面へ
        </Button>
      </ButtonWrapper>
    </Page>
  )
}

export default VerificationEmail
