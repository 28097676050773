import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Logo = styled.img`
  height: 40px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 40px;
  }
`

const TopLogo = ({ to }: { to?: string }) => {
  if (!to) {
    return <Logo src="/image/terass-portal_logo.svg" alt="logo" />
  }

  return (
    <Link to={to}>
      <Logo src="/image/terass-portal_logo.svg" alt="logo" />
    </Link>
  )
}
export default TopLogo
