import { ApiError } from 'schema/api'

export const LOCAL_API_URL = 'http://localhost:3001'
export const JWT_TOKEN_KEY_NAME = `tera-six_${process.env['REACT_APP_MODE']}_jwtToken`
export const UPDATE_MAIL = `tera-six_${process.env['REACT_APP_MODE']}_updateEmail`
export const REQUEST_RETRY_CNT = 3

export const CONTACT_EMAIL = 'portal-support@terass.com'
export const PRIVACY_POLICY_URL = 'https://terass.com/page/privacy/'
export const TERMS_URL =
  'https://terassinc.notion.site/Terass-Portal-e433e637ca8d4f72b86020034fa33994'

interface ErrorProps {
  [key: string]: ApiError
}

export const ERRORS: ErrorProps = {
  // APIエラー E000X
  E0001: {
    httpStatusCode: 400,
    status: 400,
    code: 'E0001',
    message: '入力した値が間違っています（E0001）',
  },
  E0002: {
    httpStatusCode: 401,
    status: 401,
    code: 'E0002',
    message: '認証エラーです（E0002）',
  },
  E0003: {
    httpStatusCode: 403,
    status: 403,
    code: 'E0003',
    message: 'アクセス権限がありません（E0003)',
  },
  E0004: {
    httpStatusCode: 404,
    status: 404,
    code: 'E0004',
    message: 'データが見つかりません（E0004）',
  },
  E0005: {
    httpStatusCode: 500,
    status: 500,
    code: 'E0005',
    message: '予期せぬエラーが発生しました（E0005）',
  },
  E0006: {
    httpStatusCode: 503,
    status: 503,
    code: 'E0006',
    message: 'メンテナンス中です（E0006）',
  },
  E0007: {
    httpStatusCode: 504,
    status: 504,
    code: 'E0007',
    message: 'タイムアウトが発生しました（E0007）',
  },
  // Firebase Authentication E010X
  E0101: {
    httpStatusCode: 401,
    code: 'E0101',
    message: '認証されていません（E0101）',
  },
  E0102: {
    httpStatusCode: 401,
    code: 'E0102',
    message: '認証に失敗しました（E0102）',
  },
  // Front Error E020X
  E0201: {
    httpStatusCode: 403,
    code: 'E0201',
    message: 'あなたにはアクセス権がありません（E0201）',
  },
  E0202: {
    httpStatusCode: 404,
    code: 'E0202',
    message: 'ページが見つかりません（E0202）',
  },
  E0203: {
    httpStatusCode: 500,
    code: 'E0203',
    message: '予期せぬエラーが発生しました',
  },
  E0204: {
    httpStatusCode: 404,
    code: 'E0204',
    message: 'このURLは無効です（E0204）',
  },
  E0205: {
    httpStatusCode: 404,
    code: 'E0205',
    message: 'URLの有効期限が切れました。',
    messageNode: (
      <>
        URLの有効期限が切れました。
        <br />
        <a href="./forgot">こちら</a>からパスワードの設定をお願いします。
      </>
    ),
  },
}
