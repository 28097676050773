import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { StackedBarGraph } from 'components/graph'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'

const AtHomeFavoriteGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const graphData = useMemo(() => {
    if (!contract?.athome?.favoriteCount) {
      return []
    }

    return createBarGraphData(contract?.athome?.favoriteCount, breakpoint)
  }, [breakpoint, contract?.athome?.favoriteCount])

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: '',
        data: graphData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <StackedBarGraph
        data={data}
        options={{
          ...BAR_GRAPH_OPTIONS,
          plugins: {
            ...BAR_GRAPH_OPTIONS.plugins,
            legend: {
              ...BAR_GRAPH_OPTIONS.plugins?.legend,
              display: false,
            },
          },
        }}
        plugins={GRAPH_PLUGINS('回数')}
      />
    </>
  )
}

export default AtHomeFavoriteGraph
