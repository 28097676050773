import { PropertyExampleListItem } from './property-example-list-item'
import ActiveInventoryApi from '../../../apis/active-inventory'
import { ActiveInventoryItem } from '../../../schema/contract'

const ActiveInventoryList = (props: {
  contractId: string
  list: ActiveInventoryItem[]
  isOwner: boolean
  reFetch: () => void
  isVertical?: boolean
}) => {
  return (
    <>
      {props.list.map((item, index) => {
        return (
          <PropertyExampleListItem
            key={`contract-${index}`}
            item={item}
            isVisibleToggle={!props.isOwner}
            onChangeToggle={async (e) => {
              await ActiveInventoryApi.updateActiveInventoryVisibility(
                item.id,
                props.contractId,
                e.target.checked
              )
              props.reFetch()
            }}
            isVertical={props.isVertical}
          />
        )
      })}
    </>
  )
}

export default ActiveInventoryList
