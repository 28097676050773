import Tooltip from './tooltip'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import theme from '../styles/theme'
import { ReactNode } from 'react'

const HelpTips = ({ text }: { text: string | ReactNode }) => {
  return (
    <Tooltip
      text={text}
      icon={<BsFillQuestionCircleFill color={theme.MAIN} />}
    />
  )
}

export default HelpTips
