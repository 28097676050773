import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { ButtonWrapper } from 'components/layout'
import { PageTitle, Text } from 'components/typography'
import { CONTACT_EMAIL } from 'constants/common'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { UserRole } from 'schema/user'

const NotFound = () => {
  const { claims } = useAuthProvider()

  const topPage = useMemo(() => {
    return claims?.role === UserRole.AGENT
      ? ROUTING_PATH.C0101
      : ROUTING_PATH.B0101
  }, [claims?.role])

  return (
    <Page title="404 NOT FOUND" type="guest">
      <PageTitle>404 NOT FOUND</PageTitle>
      <Block>
        <Text>お探しのページは見つかりませんでした。</Text>
      </Block>
      <Block>
        <Text>問い合わせは、{CONTACT_EMAIL}までお願いいたします。</Text>
      </Block>
      <ButtonWrapper>
        <Button as={Link} to={topPage}>
          トップページ
        </Button>
      </ButtonWrapper>
    </Page>
  )
}

export default NotFound
