import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios'

import { ERRORS, JWT_TOKEN_KEY_NAME, REQUEST_RETRY_CNT } from 'constants/common'
import { ApiError, RequestParams } from 'schema/api'
import { dateParseChallenge } from 'utils'

const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env

const instance = axios.create({
  baseURL: REACT_APP_API_URL || window.location.origin,
  timeout: 5000,
})

const request = async <R = unknown, T = unknown, P = unknown>({
  method = 'GET',
  data,
  params,
  url,
  retryCnt = REQUEST_RETRY_CNT,
  requireAuth = true,
  timeout,
}: RequestParams<T, P>): Promise<AxiosResponse<R>> => {
  const headers: AxiosRequestHeaders = {}
  if (REACT_APP_API_KEY) {
    headers['x-api-key'] = REACT_APP_API_KEY
  }

  if (requireAuth) {
    const jwtToken = localStorage.getItem(JWT_TOKEN_KEY_NAME)
    if (jwtToken) {
      headers['Authorization'] = `Bearer ${jwtToken}`
    } else {
      const error = ERRORS['E0101']
      throw error
    }
  }

  return instance
    .request({
      url,
      method,
      headers,
      data,
      params,
      timeout,
      transformResponse: (resData) => {
        if (resData && typeof resData === 'string') {
          return JSON.parse(resData, dateParseChallenge)
        }

        return resData
      },
    })
    .catch((axiosError: AxiosError<ApiError>) => {
      let error
      if (!axiosError.response) {
        // リトライ処理(頻度はリトライ回数 * 1秒)
        if (retryCnt > 0) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              request<R, T, P>({ url, method, data, retryCnt: retryCnt - 1 })
                .then((v) => resolve(v))
                .catch((e) => reject(e))
            }, 1000 * (REQUEST_RETRY_CNT - retryCnt + 1))
          })
        }

        error = ERRORS['E0005']
      } else {
        const code = axiosError.response?.data?.code
        error = code ? ERRORS[code] : ERRORS['E0005']
      }

      throw new Error(error?.message)
    })
}

export default request
