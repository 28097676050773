import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { useBreakpoints } from 'styles'

import { MultitypeGraph } from 'components/graph'
import MonthTally from '../month-tally'
import {
  BAR_GRAPH_OPTIONS,
  GRAPH_PLUGINS,
} from '../../../pages/contract/constants/graph'
import {
  createBarGraphData,
  createGraphLabel,
  total30Days,
} from '../../../pages/contract/logic/graph'
import { Contract, Feature } from 'schema/contract'
import theme from 'styles/theme'
import HelpTips from '../../../components/help-tip'

const SuumoDetailPVGraph = ({
  contract,
}: {
  contract?: Contract | Feature
}) => {
  const breakpoint = useBreakpoints()

  const labels = useMemo(() => {
    return createGraphLabel(breakpoint)
  }, [breakpoint])

  const pcData = useMemo(() => {
    if (!contract?.suumo?.detailPvForPC) {
      return []
    }

    return createBarGraphData(contract?.suumo?.detailPvForPC, breakpoint)
  }, [breakpoint, contract?.suumo?.detailPvForPC])

  const smartPhoneData = useMemo(() => {
    if (!contract?.suumo?.detailPvForSmartphone) {
      return []
    }

    return createBarGraphData(
      contract?.suumo?.detailPvForSmartphone,
      breakpoint
    )
  }, [breakpoint, contract?.suumo?.detailPvForSmartphone])

  const areaAverageData = useMemo(() => {
    if (!contract?.suumo?.areaAverageDetailPv) {
      return []
    }

    return createBarGraphData(contract?.suumo?.areaAverageDetailPv, breakpoint)
  }, [breakpoint, contract?.suumo?.areaAverageDetailPv])

  const pcSum = useMemo(() => {
    return total30Days(contract?.suumo?.detailPvForPC || [])
  }, [contract?.suumo?.detailPvForPC])

  const smartphoneSum = useMemo(() => {
    return total30Days(contract?.suumo?.detailPvForSmartphone || [])
  }, [contract?.suumo?.detailPvForSmartphone])

  const areaAverageSum = useMemo(() => {
    return total30Days(contract?.suumo?.areaAverageDetailPv || [])
  }, [contract?.suumo?.areaAverageDetailPv])

  const data: ChartData = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: `エリア平均`,
        data: areaAverageData,
        fill: false,
        backgroundColor: theme.BACKGROUND_SURFACE,
        borderColor: theme.GRAPH_COLOR_12,
        borderWidth: 1,
        pointStyle: 'rect',
      },
      {
        type: 'bar' as const,
        label: `PC`,
        data: pcData,
        backgroundColor: theme.GRAPH_COLOR_03,
        borderWidth: 0,
      },
      {
        type: 'bar' as const,
        label: `スマホ`,
        data: smartPhoneData,
        backgroundColor: theme.GRAPH_COLOR_05,
        borderWidth: 0,
      },
    ],
  }

  return (
    <>
      <MultitypeGraph
        data={data}
        options={BAR_GRAPH_OPTIONS}
        plugins={GRAPH_PLUGINS('PV')}
      />
      <MonthTally
        list={[
          {
            label: '直近30日の詳細PV数',
            value: smartphoneSum + pcSum,
            unit: '回',
            detail: {
              list: [
                {
                  label: 'PC',
                  value: pcSum,
                  unit: '回',
                },
                {
                  label: 'スマホ',
                  value: smartphoneSum,
                  unit: '回',
                },
              ],
            },
          },
          {
            label: '直近30日のエリア平均詳細PV数',
            value: areaAverageSum,
            unit: '回',
            helpTip: <HelpTips text="同市区の掲載物件の平均です" />,
          },
        ]}
      />
    </>
  )
}

export default SuumoDetailPVGraph
