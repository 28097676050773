import styled from 'styled-components'

interface BlockProps {
  level?: 'subsection' | 'section'
}

const Block = styled.div<BlockProps>`
  width: 100%;

  + div {
    margin-top: ${(p) => (p.level === 'subsection' ? '20px' : '30px')};
  }
`

export default Block
