import styled, { css } from 'styled-components'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Transition, TransitionStatus } from 'react-transition-group'
import getLayer from 'styles/layer'
import Block from './block'

interface TransitionProps {
  state: TransitionStatus
  position?: 'top' | 'center' | 'bottom'
}

const dialogWrapperAnimate = (props: TransitionProps) => {
  const { state } = props
  if (state === 'entered') {
    return css`
      opacity: 1;
      pointer-events: visible;
    `
  }
  return css`
    opacity: 0;
  `
}

const DialogWrapper = styled.div<TransitionProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;

  background-color: ${(p) => p.theme.DIALOG_OVERLAY};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${getLayer('modal')};

  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  ${dialogWrapperAnimate}
`

const dialogContentAnimate = (props: TransitionProps) => {
  const { state } = props
  if (state === 'entered') {
    return css`
      transform: translateY(0);
    `
  }
  return css`
    transform: translateY(-200px);
  `
}

export const DialogContent = styled.div<TransitionProps>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${(props) => props.position === 'center' && 'justify-content: center;'}
  ${(props) => props.position === 'top' && 'justify-content: flex-start;'}
  ${(props) => props.position === 'bottom' && 'justify-content: flex-end;'}
  align-items: center;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  ${dialogContentAnimate}
`

const CloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    img {
      width: 18px;
      height: 18px;
    }
  }
`
interface DialogBodyProps {
  height?: number
}

const DialogBody = styled.div<DialogBodyProps>`
  width: 70%;

  padding: 40px;
  margin: 100px 25px 100px;

  background: #ffffff;
  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    max-width: 100%;
  }
`

export interface ModalProps {
  show?: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  styleOptions?: {
    height?: number
    position?: 'top' | 'center' | 'bottom'
  }
}

const Dialog: React.FC<ModalProps> = ({
  show,
  onClose,
  children,
  className,
  styleOptions,
}) => {
  // モーダル表示時、一時的にbodyのスクロールを無効にする。
  useEffect(() => {
    if (show) {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.left = '0px'
      document.body.style.right = '0px'
      document.body.style.position = 'fixed'
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.left = ''
      document.body.style.right = ''
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
    }
  }, [show])

  const nodeRef = React.useRef(null)
  const component = (
    <Transition
      in={show}
      nodeRef={nodeRef}
      unmountOnExit
      timeout={{ enter: 0, exit: 350 }}
    >
      {(state: TransitionStatus) => (
        <DialogWrapper
          onMouseDown={onClose}
          state={state}
          className={className}
          role="dialog"
          aria-label="backdrop"
        >
          <DialogContent
            onMouseDown={(e) => e.stopPropagation()}
            state={state}
            position={styleOptions?.position}
          >
            <DialogBody height={styleOptions?.height}>
              <Block>
                <CloseIconWrapper onClick={onClose}>
                  <img src="/image/close.svg" alt="close" />
                </CloseIconWrapper>
              </Block>
              {children}
            </DialogBody>
          </DialogContent>
        </DialogWrapper>
      )}
    </Transition>
  )

  return ReactDOM.createPortal(
    component,
    document.querySelector('#root') || document.body
  )
}

export default Dialog
