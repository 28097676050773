import styled from 'styled-components'

const SectionTitle = styled.h3`
  margin: 0 0 10px;
  color: ${(p) => p.theme.FONT_COLOR};
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    font-size: 16px;
    line-height: 16px;
  }
`

export default SectionTitle
