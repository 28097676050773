import log from 'loglevel'
import { AuthErrorCodes } from 'firebase/auth'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { sendEmailForResetPassword } from 'commons/firebase-auth'
import Page from 'commons/page'
import Block from 'components/block'
import Button from 'components/button'
import { Form, FormControl } from 'components/form'
import { Input } from 'components/input-control'
import { ButtonWrapper } from 'components/layout'
import { Text } from 'components/typography'
import { ErrorMsg } from 'constants/message'
import ROUTING_PATH from 'constants/route'
import styled from 'styled-components'
import BasePanel from '../components/panel'
import NoLogoHeader from '../components/no-logo-header'

const Panel = styled(BasePanel)`
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

interface ForgotPasswordProp {
  email: string
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [address, setAddress] = useState<string>('')
  const [status, setStatus] = useState<'edit' | 'done'>('edit')

  const schema = yup.object({
    email: yup
      .string()
      .required(ErrorMsg.REQUIRED)
      .email(ErrorMsg.INVALID_EMAIL),
  })

  const form = useForm<ForgotPasswordProp>({
    resolver: yupResolver(schema),
  })

  const sendEmail = useCallback(async (email: string) => {
    return await sendEmailForResetPassword(email)
  }, [])

  const handleSubmit = useCallback(
    async (data: ForgotPasswordProp) => {
      setAddress(data.email)
      setLoading(true)
      try {
        await sendEmail(data.email)
        setStatus('done')
      } catch (e: any) {
        log.error(e)
        if (e.code === AuthErrorCodes.USER_DELETED) {
          form.setError('email', {
            type: 'custom',
            message: ErrorMsg.NOT_FOUND_EMAIL,
          })
        }
      }

      setLoading(false)
    },
    [form, sendEmail]
  )

  return (
    <Page
      title="パスワード再設定"
      loading={loading}
      type="guest"
      customHeader={
        <NoLogoHeader
          title={'パスワード再設定'}
          visibleGlobalMenu={false}
          visibleReturn={status === 'edit'}
        />
      }
    >
      {status === 'edit' && (
        <Panel>
          <Block>
            <Text>登録しているメールアドレスを入力してください。</Text>
          </Block>
          <Block>
            <Form form={form} onSubmit={handleSubmit}>
              <FormControl label="Eメールアドレス" name="email">
                <Input
                  type="email"
                  placeholder="abc@example.com"
                  maxLength={200}
                  aria-label="Eメールアドレス"
                />
              </FormControl>
              <Block>
                <Button>送信</Button>
              </Block>
            </Form>
          </Block>
        </Panel>
      )}
      {status === 'done' && (
        <Panel>
          <Block>
            <Text>メール送信が完了しました。</Text>
            <Text>
              メールに記載されているURLからパスワードを再設定してください。
            </Text>
          </Block>
          <Block>
            <Text>送信したEメールアドレス</Text>
            <Text>{address}</Text>
          </Block>
          <ButtonWrapper>
            <Button onClick={() => setStatus('edit')}>
              メールを再送信する
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                window.location.href = ROUTING_PATH.B0101
              }}
            >
              ログインページへ戻る
            </Button>
          </ButtonWrapper>
        </Panel>
      )}
    </Page>
  )
}

export default ForgotPassword
