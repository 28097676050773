import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

export const getTypePrefix = (actionType: string) =>
  actionType.substring(0, actionType.lastIndexOf('/'))

// UTC日付をDate型に変換
const utcDateRegex =
  /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/
export const dateParseChallenge = (_: string, value: string) => {
  if (utcDateRegex.test(value)) {
    return new Date(value)
  }
  return value
}

export const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const PRICE_UNIT = ['万', '億', '兆']
export const convertPrice = (price?: number) => {
  if (!price) {
    return '0'
  }

  const validValue = Math.floor(price / 10000)
  const arr: Array<string> = []
  PRICE_UNIT.reduce((value, unit) => {
    const v = value % 10000
    arr.push(v ? v + unit : '')
    return Math.floor(value / 10000)
  }, validValue)

  return arr.reverse().join('')
}

export const getParameterByName = (url: string, name: string) => {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const formatedDate = (
  date?: Timestamp | { _seconds: number; _nanoseconds: number },
  fmt = 'yyyy/MM/dd'
) => {
  if (!date) {
    return ''
  }

  if (date instanceof Timestamp) {
    return format(new Date(date.toDate()), fmt)
  }

  if (date._seconds) {
    return format(new Date(date._seconds * 1000), fmt)
  } else {
    return ''
  }
}
