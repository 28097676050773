import request from 'apis/request'
import ROUTING_PATH from 'constants/route'

const deleteUser = () =>
  request({
    method: 'DELETE',
    url: `/auth/user/delete`,
    data: {},
  })

const sendSignInLink = (email: string) =>
  request({
    method: 'post',
    url: `/auth/send-signinlink`,
    data: {
      email,
      redirectUrl: `${window.location.origin}${ROUTING_PATH.B0201}`,
    },
    requireAuth: false,
  })

const AuthApi = {
  deleteUser,
  sendSignInLink,
}

export default AuthApi
